/* eslint-disable react-hooks/exhaustive-deps */
import Spinner from "components/ui/Spinner";
import Tabs from "components/ui/Tabs";
import Title from "components/ui/Title";
import { PRODUCT } from "constants/commonConstants";
import { productTabs } from "constants/productConstants";
import { useApp } from "context/appContext";
import usePermissions from "hooks/usePermissions";
import useProducts from "hooks/useProducts";
import { useSearchParams } from "react-router-dom";
import AddProducts from "./AddProducts";
import EditProducts from "./EditProducts";

export default function Products() {
  const { selectedTab, setSelectedTab } = useProducts();
  const { canCreate, canUpdate, canRead } = usePermissions();
  const { tenantLoader } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const fromPage = searchParams.get("from");
  const tabs = fromPage
    ? productTabs.filter(({ value }) => value !== "add")
    : canCreate(PRODUCT) && canUpdate(PRODUCT)
    ? productTabs
    : canCreate(PRODUCT) && !canRead(PRODUCT) && !canUpdate(PRODUCT)
    ? productTabs.filter(({ value }) => value !== "edit")
    : (canRead(PRODUCT) || canUpdate(PRODUCT)) && !canCreate(PRODUCT)
    ? productTabs.filter(({ value }) => value !== "add")
    : [];

  return (
    <div className="p-20">
      <Title title="Product" />
      <div className="flex flex-col gap-y-20 p-20">
        <Tabs
          list={tabs}
          selectedTab={selectedTab}
          onClick={(tab) => {
            setSelectedTab(tab);
            if (tab !== selectedTab) {
              const _searchParams = searchParams;
              if (_searchParams.get("id")) _searchParams.delete("id");
              if (_searchParams.get("catId")) _searchParams.delete("catId");
              if (_searchParams.get("from")) _searchParams.delete("from");
              setSearchParams(_searchParams);
            }
          }}
        />
        {tenantLoader ? (
          <Spinner />
        ) : (
          <>
            {selectedTab === "add" && <AddProducts />}
            {selectedTab === "edit" && <EditProducts />}
          </>
        )}
      </div>
    </div>
  );
}
