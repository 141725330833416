import { BrandApiConfig } from "api/configs/brandApiConfig";
import { useApiMutate } from "hooks/useApi";
import { useState } from "react";
import { handleError } from "utils/helper";
import Input from "../../ui/Input";
import Modal from "../../ui/Modal";

export default function NewBrand({ isOpen, close }) {
  const [newBrand, setNewBrand] = useState("");
  const resetData = () => {
    close();
    setNewBrand("");
  };
  const { mutate: createBrand } = useApiMutate(BrandApiConfig.CREATE_BRAND, {
    successCb: resetData,
    errorCb: handleError,
  });

  return (
    <Modal
      title="New Brand"
      isOpen={isOpen}
      closeModal={resetData}
      onSave={() => {
        createBrand({ name: newBrand?.trim() });
      }}
      saveBtnTxt="Save"
      titleClass="bg-btn"
    >
      <div className="flex justify-center py-10">
        <Input
          inputType="text"
          value={newBrand}
          name="brand"
          onChange={({ target: { value } }) => setNewBrand(value)}
          label="Name:"
        />
      </div>
    </Modal>
  );
}
