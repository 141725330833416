import { AuthApiConfig } from "api/configs/authApiConfig";
import { useAuth } from "context/authContext";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getStorageData, handleError, setStorageData } from "utils/helper";
import { UserApiConfig } from "api/configs/userApiConfig";

export default function useLogin() {
  const { signIn, signOut, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleGoogleLoginSuccess = (res) => {
    const { email }: any = jwt_decode(res?.credential);
    setStorageData("email", email);
    login({ token: res.credential });
  };

  const handleLoginSuccess = (res) => {
    signIn(res);
    fetchUser();
  };

  const { refetch: fetchUser } = useApiQuery(UserApiConfig.GET_USER_BY_MAIL, {
    params: { email: getStorageData("email") },
    successCb: (data) => {
      setStorageData("userDetails", data);
      setIsAuthenticated(true);
      navigate("/products");
    },
    errorCb: (msg) => {
      handleError(msg);
      signOut();
    },
  });

  const { mutate: login } = useApiMutate(AuthApiConfig.SIGNIN, {
    successCb: handleLoginSuccess,
  });

  return {
    handleGoogleLoginSuccess,
  };
}
