import { CategoryApiConfig } from "api/configs/categoryConfig";
import { CategoriesContainer } from "components/features/categorySequencing/CategoriesContainer";
import Button from "components/ui/Button";
import Spinner from "components/ui/Spinner";
import Title from "components/ui/Title";
import { useApp } from "context/appContext";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { useState } from "react";
import { handleError } from "utils/helper";

export default function CategorySequencing() {
  const [categoryCards, setCategoryCards] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const { tenant } = useApp();
  const {
    data: categoriesHierarchy = [],
    isFetching,
    refetch,
  } = useApiQuery(CategoryApiConfig.GET_CATEGORIES_HIERARCHY, {
    pathParams: { tenant },
    successCb: updateCategoryCards,
    errorCb: handleError,
  });
  const { mutate: updateCategoriesSequence, isLoading } = useApiMutate(
    CategoryApiConfig.UPDATE_CATEGORY_SEQUENCE,
    {
      pathParams: { tenant },
      successCb: refetch,
      errorCb: handleError,
    }
  );

  function updateCategoryCards(categories) {
    setCategoryCards(
      categories
        ?.sort((a, b) =>
          a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
        )
        ?.map((category, i) => ({
          id: i + 1,
          text: category.name,
          categoryId: category._id,
          childCategories: category.childCategories,
        }))
    );
  }

  function handleEditChilds(childCategories) {
    updateCategoryCards(childCategories);
  }

  return (
    <div className="p-20">
      <Title title="Category Sequencing" />
      {isFetching ? (
        <Spinner />
      ) : (
        <div className="p-20 flex">
          <div className={`${!canEdit && "cursor-not-allowed"}`}>
            <div className={`${!canEdit && "pointer-events-none"}`}>
              <CategoriesContainer
                cards={categoryCards}
                setCards={setCategoryCards}
                handleEditChilds={handleEditChilds}
              />
            </div>
          </div>
          <div className="px-60">
            <Button
              text={!canEdit ? "Edit" : "Cancel"}
              onButtonClick={() => {
                setCanEdit((prev) => !prev);
                canEdit && updateCategoryCards(categoriesHierarchy);
              }}
              btnClass="bg-btn mr-20"
            />
            <Button
              text={"Save"}
              onButtonClick={() =>
                updateCategoriesSequence(
                  categoryCards.map(({ categoryId }, i) => ({
                    _id: categoryId,
                    sequence: i + 1,
                  }))
                )
              }
              btnClass="bg-btn"
              disabled={isLoading}
              loadingText="Saving..."
              loading={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
