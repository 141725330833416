import ProductBrochures from "components/features/products/ProductBrochures";
import ProductCategory from "components/features/products/ProductCategory";
import ProductInfo from "components/features/products/ProductInfo";
import ProductPicture from "components/features/products/ProductPicture";
import ProductSpecifications from "components/features/products/ProductSpecifications";
import ProductVideos from "components/features/products/ProductVideos";
import Accordion from "components/ui/Accordion";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import Spinner from "components/ui/Spinner";
import { useApp } from "context/appContext";
import useProducts from "hooks/useProducts";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function AddProducts() {
  const {
    create,
    categories,
    setCategories,
    info,
    setInfo,
    attributesMetaData,
    attributesData,
    setAttributesData,
    images,
    getCat,
    saveProductLoader,
    generateDisplay,
    displayName,
    fetchingCategory,
    handleImages,
    category,
    noOfImages,
    addImage,
    removeImage,
    handleImageSequence,
    categoryBrandsList,
    displayNameLoader,
    imageUploadFailed,
    productRes,
    showProductDialog,
    setProductDialog,
    setDisplayName,
    categoryTenantsList,
    handleBrochures,
    noOfBrochures,
    addBrochure,
    removeBrochure,
    brochureImages,
    removeImageVariant,
    removeBrochureVariant,
    asn,
    video,
    setVideo,
    uploadProgress,
    handleVideoRemove,
    uploadVideo,
  } = useProducts();
  const [copiedModel, setCopiedModel] = useState(false);
  const [copiedAsn, setCopiedAsn] = useState(false);
  const { isProductApprovalEnabled } = useApp();

  return (
    <>
      <ProductCategory
        categories={categories}
        updateCategories={setCategories}
        fetchCat={getCat}
        fetchingCategory={fetchingCategory}
      />
      {fetchingCategory ? (
        <Spinner />
      ) : (
        <>
          {category?._id ? (
            <>
              <Accordion
                title="Info"
                titleClass="text-17"
                labels={["ASN: "]}
                labelValues={[asn]}
              >
                <ProductInfo
                  info={info}
                  updateInfo={setInfo}
                  categoryBrandsList={categoryBrandsList}
                  setDisplayName={setDisplayName}
                  categoryTenantsList={categoryTenantsList}
                />
              </Accordion>
              <Accordion title="Upload Product Images" titleClass="text-17">
                <ProductPicture
                  noOfImages={noOfImages}
                  images={images}
                  handleImages={handleImages}
                  addImage={addImage}
                  removeImage={removeImage}
                  handleImageSequence={handleImageSequence}
                  info={info}
                  imageUploadFailed={imageUploadFailed}
                  removeImageVariant={removeImageVariant}
                  uploadSuccess={showProductDialog}
                />
              </Accordion>
              <Accordion title="Upload Brochure Images" titleClass="text-17">
                <ProductBrochures
                  noOfImages={noOfBrochures}
                  images={brochureImages}
                  addImage={addBrochure}
                  removeImage={removeBrochure}
                  info={info}
                  imageUploadFailed={imageUploadFailed}
                  handleBrochures={handleBrochures}
                  removeBrochureVariant={removeBrochureVariant}
                  uploadSuccess={showProductDialog}
                />
              </Accordion>
              <Accordion title="Upload Video" titleClass="text-17">
                <ProductVideos
                  video={video}
                  setVideo={setVideo}
                  info={info}
                  uploadProgress={uploadProgress}
                  handleVideoRemove={handleVideoRemove}
                  uploadVideo={uploadVideo}
                  url={info.video}
                />
              </Accordion>
              {attributesMetaData?.length ? (
                <Accordion title={"Specifications"} titleClass="text-17">
                  <ProductSpecifications
                    metaData={attributesMetaData}
                    data={attributesData}
                    setData={setAttributesData}
                    setDisplayName={setDisplayName}
                  />
                </Accordion>
              ) : null}
              <div className="flex items-center gap-x-20 py-10">
                <Button
                  text={"Create Display Name"}
                  onButtonClick={generateDisplay}
                  btnClass={"bg-btn py-[10px]"}
                  loading={displayNameLoader}
                  loadingText="Generating..."
                  disabled={displayNameLoader}
                />
                <p className="mt-14 font-bold text-18">
                  Display Name:{" "}
                  <span className="text-primary">{displayName}</span>
                </p>
              </div>
              <div className="flex justify-center gap-x-20 py-40">
                <Button
                  text={
                    isProductApprovalEnabled ? "Request for approval" : "Save"
                  }
                  onButtonClick={create}
                  btnClass={"bg-btn px-[30px] py-[10px]"}
                  loading={saveProductLoader}
                  loadingText="Processing..."
                />
              </div>
              <Modal
                isOpen={showProductDialog}
                closeModal={() => setProductDialog(false)}
                title={""}
                onSave={() => setProductDialog(false)}
                saveBtnTxt={"OK"}
              >
                <div className="text-16">
                  <p className="text-18 font-bold text-green-500">
                    Product created successfully!
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="font-medium">
                      Model Name:{" "}
                      <span className="font-bold">{productRes?.modelName}</span>
                    </p>
                    <CopyToClipboard
                      text={productRes?.modelName}
                      onCopy={() => {
                        setCopiedModel(true);
                        setTimeout(() => {
                          setCopiedModel(false);
                        }, 1000);
                      }}
                      className="text-blue-400"
                    >
                      <button>
                        {copiedModel ? (
                          <span className="text-green-500 no-underline">
                            Copied!
                          </span>
                        ) : (
                          "Copy"
                        )}
                      </button>
                    </CopyToClipboard>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="font-medium">
                      ASN: <span className="font-bold">{productRes?.asn}</span>
                    </p>
                    <CopyToClipboard
                      text={productRes?.asn}
                      onCopy={() => {
                        setCopiedAsn(true);
                        setTimeout(() => {
                          setCopiedAsn(false);
                        }, 1000);
                      }}
                      className="text-blue-400"
                    >
                      <button>
                        {copiedAsn ? (
                          <span className="text-green-500 no-underline">
                            Copied!
                          </span>
                        ) : (
                          "Copy"
                        )}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </Modal>
            </>
          ) : null}
        </>
      )}
    </>
  );
}
