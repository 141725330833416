export const ProductApiConfig: any = {
  CREATE_PRODUCT: {
    url: "/product",
    method: "post",
  },
  GET_PRODUCT_BY_ID: {
    enabled: false,
    url: "/tenant/:tenant/product/:productId",
  },
  UPDATE_PRODUCT_BY_ID: {
    url: "/tenant/:tenant/product/:productId",
    method: "put",
    successToast: "Product updated successfully!",
  },
  DELETE_PRODUCT_BY_ID: {
    url: "/product/:productId/delete",
    method: "put",
  },
  GET_IMAGE_UPLOAD_URL: {
    url: "/product/imageUploadUrl",
    enabled: false,
    method: "post",
  },
  SEARCH_PRODUCTS: {
    url: "/tenant/:tenant/product/search",
    enabled: false,
  },
  GENERATE_DISPLAY_NAME: {
    url: "/product/generateDisplayName",
    enabled: false,
    method: "post",
  },
  UPDATE_PRODUCT_IMAGES: {
    url: "/product/productImage",
    method: "post",
    successToast: "Product updated successfully!",
  },
  MOQ_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdateMOQ",
    enabled: false,
    method: "post",
  },
  MAX_ORDER_QTY_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdateMaxQty",
    enabled: false,
    method: "post",
  },
  PACK_QTY_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdatePackQty",
    enabled: false,
    method: "post",
  },
  STATUS_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdateActiveStatus",
    enabled: false,
    method: "post",
  },
  POD_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdatePayOnDelivery",
    enabled: false,
    method: "post",
  },
  GET_CREATOR_PRODUCTS_BY_STATUS: {
    url: "/products/creator/status/:status",
  },
  GET_APPROVER_PRODUCTS_BY_STATUS: {
    url: "/products/approver/status/:status",
  },
  DOWNLOAD_PRODUCTS_BY_STATUS: {
    url: "/products/download/status/:status/from/:fromDate/to/:toDate",
    enabled: false,
    //response type "arraybuffer" to download file
    responseType: "arraybuffer",
  },
  APPROVE_PRODUCT: {
    url: "/product/:productId/approve",
    method: "put",
    successToast: "Product approved successfully!",
  },
  DECLINE_PRODUCT: {
    url: "/product/:productId/decline",
    method: "put",
    successToast: "Product declined successfully!",
  },
  SUBMIT_PRODUCT: {
    url: "/product/:productId/submit",
    method: "put",
    successToast: "Product submitted successfully!",
  },
  IS_PRODUCT_APPROVAL_ENABLED: {
    url: "/product/approvalEnabled",
  },
  PRODUCT_BULK_UPLOAD_FILE: {
    url: "/category/bulkUploadTemplate/:categoryId",
    enabled: false,
    responseType: "arraybuffer",
  },
  PRODUCT_BULK_UPLOAD: {
    url: "/product/bulkUpload",
    enabled: false,
    method: "post",
    responseType: "arraybuffer",
  },
  IMAGES_FILE_UPLOAD: {
    url: "/bulkUpload/images",
    enabled: false,
    method: "post",
  },
  MRP_BULK_UPLOAD: {
    url: "/tenant/:tenant/product/bulkUpdateMrp",
    enabled: false,
    method: "post",
  },
  MIN_PAYMENT_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateMinPayment",
    enabled: false,
    method: "post",
  },
  GENERATE_ASN: {
    url: "/generateAsn",
    enabled: false,
  },
  DOWNLOAD_IMAGES: {
    url: "/tenant/:tenant/product/:productId/images",
    enabled: false,
    responseType: "arraybuffer",
  },
  PRIORITY_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdatePriority",
    enabled: false,
    method: "post",
  },
  IMAGES_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdate/images",
    enabled: false,
    method: "post",
    responseType: "arraybuffer",
  },
  GET_VIDEO_UPLOAD_URL: {
    url: "/product/generateVideoUploadUrl",
    enabled: false,
    method: "post",
  },
  HIGHLIGHTS_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateHighlights",
    enabled: false,
    method: "post",
  },
  PRODUCT_TAG_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateProductTag",
    enabled: false,
    method: "post",
  },
  BULK_UPDATE_PRODUCT_TAG_TEMPLATE: {
    url: "/product/bulkUpdateProductTagTemplate",
    enabled: false,
    responseType: "arraybuffer",
  },
  GENERIC_NAME_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateGenericName",
    enabled: false,
    method: "post",
  },
  COUNTRY_OF_ORIGIN_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateCountryOfOrigin",
    enabled: false,
    method: "post",
  },
  MANUFACTURER_DETAILS_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateManufacturerDetails",
    enabled: false,
    method: "post",
  },
  IMPORTER_DETAILS_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateImporterDetails",
    enabled: false,
    method: "post",
  },
  PACKER_DETAILS_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdatePackerDetails",
    enabled: false,
    method: "post",
  },
  DISPLAY_SIZE_BULK_UPDATE: {
    url: "/tenant/:tenant/product/bulkUpdateDisplaySize",
    enabled: false,
    method: "post",
  },
};
