import { ProductApiConfig } from "api/configs/productApiConfig";
import Table from "components/ui/Table";
import Tabs from "components/ui/Tabs";
import Title from "components/ui/Title";
import { creatorTabs } from "constants/productConstants";
import {
  CreatorDraftProductColumns,
  CreatorPendingProductColumns,
} from "constants/TableColumns/productColumns";
import { useApp } from "context/appContext";
import { useApiQuery } from "hooks/useApi";
import { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import Button from "components/ui/Button";
import { DATE_FORMAT } from "constants/commonConstants";
import { disabledDate, downloadFile, handleError } from "utils/helper";

export default function ProductCreator() {
  const { RangePicker } = DatePicker;
  const [selectedTab, setSelectedTab] = useState("DRAFT");
  const columnsByStatus = {
    DRAFT: CreatorDraftProductColumns,
    PENDING: CreatorPendingProductColumns,
  };
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const { tenant } = useApp();
  const { data: products = [] } = useApiQuery(
    ProductApiConfig.GET_CREATOR_PRODUCTS_BY_STATUS,
    {
      pathParams: { status: selectedTab, tenant },
    }
  );
  const { refetch: download, isLoading } = useApiQuery(
    ProductApiConfig.DOWNLOAD_PRODUCTS_BY_STATUS,
    {
      pathParams: {
        status: selectedTab,
        fromDate: moment(fromDate).format(DATE_FORMAT),
        toDate: moment(toDate).format(DATE_FORMAT),
      },
      successCb: (data) =>
        downloadFile(
          data,
          `${selectedTab}-PRODUCTS_FROM_${moment(fromDate).format(
            DATE_FORMAT
          )}_TO_${moment(toDate).format(DATE_FORMAT)}.xlsx`
        ),
      errorCb: handleError,
    }
  );
  return (
    <div className="p-20">
      <Title title="Product Creator's Page" />
      <div className="p-20">
        <Tabs
          list={creatorTabs}
          selectedTab={selectedTab}
          onClick={setSelectedTab}
        />
        <div className="flex gap-x-20 py-20">
          <RangePicker
            onChange={([from, to]) => {
              setFromDate(from);
              setToDate(to);
            }}
            disabledDate={disabledDate}
            value={[fromDate, toDate]}
          />
          <Button
            text={"Download"}
            onButtonClick={() => download()}
            btnClass={"bg-btn"}
            loading={isLoading}
            loadingText="Downloading..."
            disabled={isLoading || !fromDate || !toDate}
          />
        </div>
        <div className="py-20">
          {products?.length ? (
            <Table
              columns={columnsByStatus[selectedTab]}
              data={products}
              loading={false}
              hasPagination={false}
              manualPagination
            />
          ) : (
            <p className="text-20 font-bold text-center py-20">No data found</p>
          )}
        </div>
      </div>
    </div>
  );
}
