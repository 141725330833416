import classNames from "classnames";

type TabProps = {
  list: any;
  selectedTab: any;
  labelClassname?: string;
  onClick?: any;
};

const Tabs = (props: TabProps) => {
  const handleClick = (item) => {
    if (item.children || !props.onClick) return;
    props.onClick(item.value);
  };
  return (
    <div className="flex h-[42px] border-b border-stroke mb-15">
      {props.list.map((item, i) => {
        const isActive = item.children
          ? item.children.find((item) => item.value === props.selectedTab)
          : props.selectedTab === item.value;
        return (
          <div
            key={`_tab_${item.label}`}
            onClick={() => handleClick(item)}
            className={classNames(
              "relative flex flex-col items-center h-full mr-[48px] justify-center text-14 cursor-pointer",
              props.labelClassname
            )}
          >
            <span
              className={classNames(
                "font-bold text-15",
                !isActive ? "opacity-50" : undefined
              )}
            >
              {item.label}
            </span>
            {isActive && (
              <div className="absolute bottom-0 w-full h-4 bg-primary" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
