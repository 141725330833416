type TextAreaProps = {
  name: string;
  value: string;
  onChange: any;
  label?: string;
  mandatory?: boolean;
  disabled?: boolean;
};

export default function TextArea({
  name,
  value,
  onChange,
  label,
  mandatory = false,
  disabled = false,
}: TextAreaProps) {
  return (
    <div className="flex w-[90%] justify-start items-center">
      {label && (
        <label className="pr-20 w-[20%] text-right text-textColor font-bold">
          {mandatory ? <span className="text-red pr-5">*</span> : ""}
          {label}
        </label>
      )}
      <textarea
        className="rounded border-[1.5px] outline-none p-2 w-[70%]"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
