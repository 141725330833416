import { BrandApiConfig } from "api/configs/brandApiConfig";
import Button from "components/ui/Button";
import NewBrand from "components/features/brands/NewBrand";
import Table from "components/ui/Table";
import Title from "components/ui/Title";
import { BrandColumns } from "constants/TableColumns/brandColumns";
import { useApiQuery } from "hooks/useApi";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import Spinner from "components/ui/Spinner";
import { BRAND } from "constants/commonConstants";
import usePermissions from "hooks/usePermissions";

function Brands() {
  const [showNewBrand, setShowNewBrand] = useState(false);
  const [searchedBrand, setSearchedBrand] = useState("");
  const { canCreate } = usePermissions();
  const {
    data: brands = [],
    refetch,
    isFetching,
  } = useApiQuery(BrandApiConfig.GET_BRAND_BY_NAME, {
    params: { name: searchedBrand },
  });

  useEffect(() => {
    if (searchedBrand) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedBrand]);

  return (
    <div className="p-20">
      <Title title="Brand" />
      <div className="flex justify-start gap-x-20 p-20">
        <DebounceInput
          minLength={2}
          debounceTimeout={200}
          className="border p-4 rounded outline-none"
          placeholder="Search brands..."
          onChange={(e) => {
            setSearchedBrand(e.target.value);
          }}
          value={searchedBrand}
        />
        <Button
          text={"+ New Brand"}
          onButtonClick={() => setShowNewBrand(true)}
          btnClass="bg-btn"
          disabled={!canCreate(BRAND)}
        />
      </div>
      {isFetching ? (
        <Spinner />
      ) : brands.length ? (
        <div className="p-20">
          <div className="flex pb-20 text-20 text-[grey] opacity-50">
            Brands matching "{searchedBrand}"
          </div>
          <Table
            columns={BrandColumns}
            data={brands}
            loading={false}
            hasPagination={false}
          />
        </div>
      ) : (
        <div className="flex p-20 text-20 text-[grey] opacity-50">
          Search some brands to see the data
        </div>
      )}
      <NewBrand isOpen={showNewBrand} close={() => setShowNewBrand(false)} />
    </div>
  );
}
export default Brands;
