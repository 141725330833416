export const VariantApiConfig: any = {
  CREATE_VARIANTS: {
    url: "/variant",
    enabled: false,
    method: "post",
    successToast: "Variant created successfully!",
  },
  UPDATE_VARIANT: {
    url: "/variant",
    enabled: false,
    method: "put",
    successToast: "Variant updated successfully!",
  },
  GET_VARIANT: {
    url: "/variant/:id",
    enabled: false,
  },
  SEARCH_VARIANT: {
    url: "/variant/search",
    enabled: false,
  },
  DELETE_VARIANT_PRODUCT: {
    url: "/variant/:variantId/product/:productId",
    enabled: false,
    method: "delete",
    successToast: "Deleted Product Successfully!",
  },
  DELETE_VARIANT: {
    url: "/variant/:variantId",
    enabled: false,
    method: "delete",
    successToast: "Deleted variant Successfully!",
  },
};
