import Input from "components/ui/Input";
import Modal from "components/ui/Modal";

export default function UpdateASN({
  show,
  oldASN,
  close,
  update,
  newASN,
  setNewASN,
  updating,
}) {
  return (
    <Modal
      isOpen={show}
      closeModal={() => {
        close();
        setNewASN("");
      }}
      title={"Update ASN"}
      onSave={update}
      saveBtnTxt={"Update"}
      disableSave={!newASN?.trim() || updating}
    >
      <>
        <div className="grid grid-cols-2 pb-10 text-16 font-medium">
          <p>Old ASN:</p>
          <p className="font-bold">{oldASN}</p>
        </div>
        <div className="grid grid-cols-2 pb-10 text-16 font-medium">
          <p>New ASN:</p>
          <div>
            <Input
              inputType={"alphanumeric"}
              value={newASN}
              name={"newASN"}
              onChange={({ target: { value } }) => setNewASN(value)}
              maxLength={10}
            />
          </div>
        </div>
      </>
    </Modal>
  );
}
