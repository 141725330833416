import useLogin from "hooks/useLogin";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

function Login() {
  const { handleGoogleLoginSuccess } = useLogin();

  return (
    <div className="h-screen flex justify-center items-center bg-cover bg-center">
      <div className="bg-stroke rounded-lg text-center relative bottom-[5%] px-[120px] py-[50px] border border-stroke opacity-80">
        <h1 className="text-24 mb-20 pb-10 border-b border-black">Login</h1>
        <GoogleOAuthProvider clientId="708736541531-3om04bpdomuenfe4abtc3f3inpel6eth.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => {
              alert("Login Failed");
            }}
            useOneTap
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default Login;
