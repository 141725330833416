export const CategoryApiConfig: any = {
  GET_CATEGORY_BY_NAME: { url: "/category/search", enabled: false },
  CREATE_CATEGORY: {
    url: "/category",
    method: "post",
    successToast: "Created category successfully!",
  },
  UPDATE_CATEGORY: {
    url: "/category/:id",
    method: "put",
    successToast: "Updated category successfully!",
  },
  GET_CATEGORY_BY_ID: { url: "/tenant/:tenant/category/:id", enabled: false },
  GET_ALL_CATEGORIES: { url: "/category" },
  DELETE_CATEGORY: { url: "/category/delete/:id", method: "put" },
  ACTIVATE_CATEGORY: { url: "/category/activate/:id", method: "put" },
  GET_ACTIVE_PARENT_CATEGORIES: { url: "/category/parents", method: "get" },
  GET_ACTIVE_CHILD_CATEGORIES: {
    url: "/category/childCategories/:parentId",
    enabled: false,
  },
  EDIT_SUB_CATEGORY: {
    url: "/product/:productId/categoryChange?shouldDeleteAttributes=:shouldDeleteAttributes",
    method: "put",
  },
  GET_CATEGORIES_HIERARCHY: {
    url: "/tenant/:tenant/category/hierarchy",
  },
  UPDATE_CATEGORY_SEQUENCE: {
    url: "/tenant/:tenant/category/sequence",
    method: "put",
    successToast: "Updated category sequence successfully!",
  },
  IS_SEQUENCING_ENABLED: {
    url: "/tenant/:tenant/category/sequencingEnabled",
  },
};
