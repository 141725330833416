/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { MASTER_TENANT, PRODUCT } from "constants/commonConstants";
import { brochureVariantUrls, infoFields } from "constants/productConstants";
import { useApp } from "context/appContext";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  buildValidArray,
  createSelectList,
  handleError,
  replaceImageBaseUrl,
} from "utils/helper";
import { useApiQuery, useApiMutate } from "./useApi";
import usePermissions from "./usePermissions";
import axios from "axios";
import { message } from "antd";

export default function useProducts() {
  const { canCreate, canRead } = usePermissions();
  const { tenant, setTenant } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("from") ? "edit" : ""
  );
  const [categories, setCategories]: any = useState({
    childCatL2: {},
    childCatL1: {},
    parentCat: {},
  });
  const [info, setInfo]: any = useState({
    dashboardActive: true,
    active: false,
    freeShip: false,
    hasCapacity: false,
    payOnDelivery: false,
    highlights: "NA",
  });
  const [attributesMetaData, setAttributesMetaData] = useState([]);
  const [attributesData, setAttributesData] = useState([]);
  const [images, setImages] = useState({});
  const [noOfImages, setNoOfImages]: any = useState({ 0: [""] });
  const [noOfBrochures, setNoOfBrochures]: any = useState({ 0: [""] });
  const [categoryBrandsList, setCategoryBrands] = useState([]);
  const [categoryTenantsList, setCategoryTenants] = useState([]);
  const parseCategoryData = (data) => {
    const mandatoryAttributes = [];
    const restAttributes = [];
    data?.attributes?.forEach((attribute) => {
      if (attribute?.isMandatory) mandatoryAttributes.push(attribute);
      else restAttributes.push(attribute);
    });
    data.attributes = [...mandatoryAttributes, ...restAttributes];
    return data;
  };
  const [asn, setAsn] = useState("");
  const { refetch: generateAsn } = useApiQuery(ProductApiConfig.GENERATE_ASN, {
    params: { categoryId: categories.parentCat?.value },
    successCb: setAsn,
    errorCb: handleError,
  });
  const {
    data: category,
    refetch: getCat,
    isFetching: fetchingCategory,
  } = useApiQuery(CategoryApiConfig.GET_CATEGORY_BY_ID, {
    pathParams: {
      id: searchParams.get("catId")?.length
        ? searchParams.get("catId")
        : categories.childCatL2?.value
        ? categories.childCatL2?.value
        : categories.childCatL1?.value
        ? categories.childCatL1?.value
        : categories.parentCat?.value,
      tenant,
    },
    dataParser: parseCategoryData,
    successCb: (data) => {
      if (categories.parentCat?.value) generateAsn();
      setAttributesMetaData(data?.attributes);
      const tempAttributesData = [];
      data?.attributes?.forEach(({ valueType, options, _id }) => {
        if (
          ["integer", "string"].includes(valueType) &&
          options?.length === 1
        ) {
          tempAttributesData.push({ u: options?.[0], k: _id });
        }
      });
      setAttributesData([...tempAttributesData]);
      setCategoryBrands(createSelectList(data?.brands, "name", "_id"));
      setCategoryTenants(createSelectList(data?.tenants));
    },
  });
  const [imageExtensions, setImageExtensions]: any = useState({});
  const [imageUploadFailed, setImageUploadFailed]: any = useState();
  const uploadImage = async (data) => {
    setImages({
      ...images,
      [currentImage.index]: {
        ...images[currentImage.index],
        [currentImage.variant]: {
          ...images[currentImage.index][currentImage.variant],
          url: data?.[0]?.substring(
            0,
            data?.[0]?.indexOf("jpg") > -1
              ? data?.[0]?.indexOf("jpg") + 3
              : data?.[0]?.indexOf("jpeg") > -1
              ? data?.[0]?.indexOf("jpeg") + 4
              : data?.[0]?.indexOf("png") > -1
              ? data?.[0]?.indexOf("png") + 3
              : null
          ),
        },
      },
    });
    await fetch(data?.[0], {
      method: "PUT",
      body: images?.[currentImage?.index]?.[currentImage.variant],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  const [video, setVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadVideo = async () => {
    const { status, data } = await axios.put(videoUploadUrl, video, {
      onUploadProgress: (progressEvent) => {
        setUploadProgress(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      },
    });
    setUploadProgress(0);
    if (status === 200) {
      setVideo(null);
      message.success("Uploaded video successfully!");
      setInfo({
        ...info,
        video: videoUploadUrl?.substring(
          0,
          videoUploadUrl?.indexOf(".mp4") + 4
        ),
      });
    } else message.error(data ?? "Error while uploading video");
  };

  const uploadBrochure = async (data) => {
    setBrochureImages({
      ...brochureImages,
      [currentImage.index]: {
        ...brochureImages[currentImage.index],
        [currentImage.variant]: data?.[0]?.substring(
          0,
          data?.[0]?.indexOf("jpg") > -1
            ? data?.[0]?.indexOf("jpg") + 3
            : data?.[0]?.indexOf("jpeg") + 4
        ),
      },
    });
    await fetch(data?.[0], {
      method: "PUT",
      body: brochureImages?.[currentImage?.index]?.[currentImage.variant],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  const { mutate: getImageUploadUrl } = useApiMutate(
    ProductApiConfig.GET_IMAGE_UPLOAD_URL,
    {
      successCb: (data) => {
        setImageUploadFailed(null);
        setImageExtensions({});
        if (brochureVariantUrls.includes(currentImage.variant))
          uploadBrochure(data);
        else uploadImage(data);
      },
      errorCb: (msg) => {
        setImageUploadFailed({ ...currentImage });
        setImageExtensions({});
        handleError(msg);
      },
    }
  );
  const [videoUploadUrl, setVideoUploadUrl] = useState("");
  const { mutate: getVideoUploadUrl } = useApiMutate(
    ProductApiConfig.GET_VIDEO_UPLOAD_URL,
    {
      successCb: setVideoUploadUrl,
      errorCb: handleError,
    }
  );

  useEffect(() => {
    if (video) getVideoUploadUrl({ asn, sequence: 1 });
  }, [video]);

  const [currentImage, setCurrentImage]: any = useState({});
  const [searchedBrand, setSearchedBrand] = useState("");
  const resetStates = () => {
    setInfo({
      dashboardActive: true,
      freeShip: false,
      hasCapacity: false,
      active: false,
      payOnDelivery: false,
      highlights: "NA",
    });
    setAttributesData([]);
    setImages({});
    setBrochureImages({});
    setNoOfImages({ 0: [""] });
    setNoOfBrochures({ 0: [""] });
    setDisplayName("");
    setCurrentImage({});
    setSearchedBrand("");
    setVideo(null);
    setVideoUploadUrl("");
    generateAsn();
  };
  const [showProductDialog, setProductDialog] = useState(false);
  const {
    mutate: createProduct,
    isLoading: saveProductLoader,
    data: productRes,
  } = useApiMutate(ProductApiConfig.CREATE_PRODUCT, {
    successCb: () => {
      setProductDialog(true);
      if (searchParams.get("id")) {
        const temp = searchParams;
        temp.delete("id");
        setSearchParams(temp);
      }
      if (searchParams.get("catId")) {
        const temp = searchParams;
        temp.delete("catId");
        setSearchParams(temp);
      }
      resetStates();
    },
    errorCb: handleError,
  });
  const [displayName, setDisplayName] = useState("");
  const [brochureImages, setBrochureImages]: any = useState({});
  const { mutate: generateDisplayName, isLoading: displayNameLoader } =
    useApiMutate(ProductApiConfig.GENERATE_DISPLAY_NAME, {
      successCb: (data) => setDisplayName(data),
      errorCb: handleError,
    });
  useEffect(() => {
    if (imageExtensions.ext)
      getImageUploadUrl({
        modelName: info.modelName?.trim(),
        imageExtension: [imageExtensions.ext],
        type: imageExtensions.type ?? "NEWPRODUCT",
        position: imageExtensions.position,
        imageVariant: imageExtensions.imageVariant,
      });
  }, [imageExtensions]);
  useEffect(() => {
    if (tenant)
      setSelectedTab(
        searchParams.get("from") && canRead(PRODUCT)
          ? "edit"
          : canCreate(PRODUCT)
          ? "add"
          : canRead(PRODUCT) && "edit"
      );
  }, [tenant]);

  const { refetch: getProduct } = useApiQuery(
    ProductApiConfig.GET_PRODUCT_BY_ID,
    {
      pathParams: { productId: searchParams.get("id"), tenant },
      successCb: (data) => {
        setCategories({
          childCatL2: {
            value: data.categories[data.categories.length - 3]?.id ?? "",
          },
          childCatL1: {
            value: data.categories[data.categories.length - 2]?.id ?? "",
          },
          parentCat: { value: data.categories[data.categories.length - 1]?.id },
        });
        setTimeout(generateAsn, 100);
        setDisplayName(data.displayName);
        setInfo({
          ...data,
          brand: data.brand.id,
          highlights: data.highlights ? data.highlights : "NA",
          _id: null,
          asn: null,
          video: null,
        });
        setAttributesData(data.attributes);
        const productBrochures = {};
        const noOfBrochures = {};
        if (data?.brochureImages?.length) {
          data?.brochureImages?.forEach((brochure, i) => {
            noOfBrochures[i] = [""];
            productBrochures[i + 1] = brochure;
          });
          setNoOfBrochures(noOfBrochures);
          setBrochureImages(productBrochures);
        }
      },
    }
  );

  useEffect(() => {
    if (searchParams.get("id")?.length) {
      if (tenant !== MASTER_TENANT) setTenant(MASTER_TENANT);
      getProduct();
      getCat();
    }
  }, []);

  const canGenerateDisplayName = () =>
    info.modelName?.trim() && info.brand && hasMandatoryAttributes();

  const hasMandatoryAttributes = () => {
    for (const attributeMeta of attributesMetaData) {
      const attribute = attributesData.find(
        (attribute) => attribute?.k === attributeMeta?._id
      );
      if (
        attributeMeta?.isMandatory &&
        (!attribute ||
          !attribute["v"] ||
          (attributeMeta?.options.length
            ? ["integer", "string"].includes(attributeMeta?.valueType) &&
              !attribute["u"]
            : false) ||
          (attributeMeta?.filterOptions.length
            ? !attribute?.filterOption
            : false))
      ) {
        return false;
      }
    }
    return true;
  };

  const hasUnitValues = () => {
    for (const attributeMeta of attributesMetaData) {
      const attribute = attributesData.find(
        (attribute) => attribute?.k === attributeMeta?._id
      );
      if (
        attribute &&
        attribute["v"] &&
        attributeMeta?.options.length &&
        ["integer", "string"].includes(attributeMeta?.valueType) &&
        !attribute["u"]
      ) {
        return false;
      }
    }
    return true;
  };

  const hasFilterOptions = () => {
    for (const attributeMeta of attributesMetaData) {
      const attribute = attributesData.find(
        (attribute) => attribute?.k === attributeMeta?._id
      );
      if (
        attribute &&
        attribute["v"] &&
        attributeMeta?.filterOptions.length &&
        !attribute["filterOption"]
      ) {
        return false;
      }
    }
    return true;
  };

  const buildPayload = () => {
    const imagesArr = [];
    let minOrder = 1000;
    let primaryImage = "";
    Object.values(images).forEach((img: any) => {
      if (Number(img.order) < minOrder) {
        minOrder = Number(img.order);
        primaryImage = img?.["mv"]?.url;
      }
      let smallUrl = img?.["sv"] && img?.["sv"]?.url ? img["sv"].url : null;
      let mediumUrl = img?.["mv"] && img?.["mv"]?.url ? img["mv"]?.url : null;
      let largeUrl = img?.["lv"] && img?.["lv"]?.url ? img["lv"].url : null;
      if (process.env.REACT_APP_ENV === "production") {
        primaryImage = replaceImageBaseUrl(primaryImage);
        smallUrl = replaceImageBaseUrl(smallUrl);
        mediumUrl = replaceImageBaseUrl(mediumUrl);
        largeUrl = replaceImageBaseUrl(largeUrl);
      }
      const url = {
        smallUrl,
        mediumUrl,
        largeUrl,
      };
      imagesArr.push({
        order: img.order,
        url,
        tenant: buildValidArray(info.tenants),
      });
    });
    const t = { ...info };
    t.modelName = t?.modelName?.trim();
    t.description = t?.description?.trim();
    t.highlights = t?.highlights?.trim();
    t.brand = { id: t.brand };
    t.tenants = buildValidArray(t.tenants);
    t.productTag = t.productTag ? t.productTag.trim() : null;
    t.asn = asn;
    const pl = {
      ...t,
      displayName,
      attributes: getAttributesPayload(),
      categories: getCreateProdCategoriesPayload(),
      images: {
        imagesData: imagesArr,
        primaryImage,
      },
      brochureImages: Object.values(brochureImages),
    };
    return pl;
  };
  const getCreateProdCategoriesPayload = () => {
    const cats = [];
    Object.values(categories).forEach((cat: any) => {
      if (cat?.value) cats.push({ id: cat.value });
    });
    return cats;
  };
  const isSequenceUnique = () => {
    const sequenceMap = {};
    const imgs: any = Object.values(images);
    for (const { order } of imgs) {
      if (sequenceMap[order]) {
        return true;
      } else {
        sequenceMap[order] = true;
      }
    }
    return false;
  };
  const hasMandatoryInfo = () => {
    for (const field of infoFields) {
      if (
        field?.isMandatory &&
        (field.name === "tenants"
          ? !info[field?.name]?.length
          : [null, ""].includes(info[field?.name]))
      ) {
        return false;
      }
    }
    return true;
  };

  function productImagesValidation() {
    const imgs = Object.values(images);
    if (!imgs.length) {
      alert("Please upload the images");
      return true;
    }
    for (const img of imgs) {
      if (!img["mv"] || !img["order"]) {
        alert("Medium image & sequence are mandatory");
        return true;
      }
    }
    if (imgs.length && (!imgs?.[0]?.["mv"] || !imgs?.[0]?.["mv"]?.["url"])) {
      alert("Please upload the images again");
      return true;
    }
    return false;
  }

  const create = async () => {
    if (!hasMandatoryInfo()) return alert("Please fill mandatory product info");
    if (
      (info?.["moq"] && info?.["moq"] % info?.["packQuantity"] !== 0) ||
      (info?.["maxQuantity"] &&
        info?.["maxQuantity"] % info?.["packQuantity"] !== 0)
    ) {
      return alert("Min & max qty should be in multiples of pack qty");
    }
    if (productImagesValidation()) return;
    if (!hasMandatoryAttributes())
      return alert("Please fill mandatory product attributes");
    if (!hasUnitValues())
      return alert("Please provide units for attributes that has values");
    if (!hasFilterOptions())
      return alert(
        "Please provide filter option for attributes that has values"
      );
    if (isSequenceUnique()) return alert("Duplicate image sequence");
    if (!displayName) return alert("Please generate display name");
    if (uploadProgress > 0) return alert("Video upload in progress");

    const payload = buildPayload();
    createProduct(payload);
  };
  const getCatsPayload = () => {
    const cats = [];
    Object.values(categories).forEach((cat: any) => {
      if (cat?.value) cats.push(cat.value);
    });
    return cats;
  };
  const getAttributesPayload = () => {
    const attributes = [];
    attributesData.forEach((attribute) => {
      if (attribute?.v?.trim()) {
        attributes.push({
          ...attribute,
          v: attribute?.v?.trim(),
          filterOption: attribute?.filterOption ?? null,
        });
      }
    });
    return attributes;
  };
  const generateDisplay = () => {
    if (!canGenerateDisplayName()) return alert("Please fill mandatory fields");
    if (!hasUnitValues)
      return alert("Please provide units for attributes that has values");

    generateDisplayName({
      modelName: info.modelName?.trim(),
      attributes: getAttributesPayload(),
      categories: getCatsPayload(),
      brand: info.brand,
    });
  };

  async function handleBrochures({ name, variant }, position, file) {
    setImageUploadFailed(null);
    const failedImages = await brochureValidation(file, variant);
    if (failedImages) {
      setImageUploadFailed({ variant: name, index: position });
      return alert(failedImages);
    }
    setCurrentImage({ variant: name, index: position });
    setBrochureImages((curr) => {
      if (curr[position]) {
        curr[position][name] = file;
      } else {
        curr[position] = { [name]: file };
      }
      return curr;
    });
    let ext = file?.type?.slice(file?.type?.indexOf("/") + 1);
    if (ext === "jpeg") ext = "jpg";
    const imageVariant = variant;
    setImageExtensions({ ext, position, imageVariant, type: "BROCHURE" });
  }

  const handleImages = async (idx, name, file, position) => {
    setImageUploadFailed(null);
    const correctImages = await imageValidation(file);
    if (correctImages) {
      setImageUploadFailed({ variant: name, index: idx });
      return alert(correctImages);
    }
    setCurrentImage({ variant: name, index: idx });
    setImages({
      ...images,
      [idx]: { ...images[idx], [name]: file },
    });
    let ext = file?.type?.slice(file?.type?.indexOf("/") + 1);
    if (ext === "jpeg") ext = "jpg";
    const imageVariant = name === "sv" ? "S" : name === "mv" ? "M" : "L";
    setImageExtensions({ ext, position, imageVariant });
  };
  const handleImageSequence = (idx, order) => {
    const t = { ...images };
    t[idx] = { ...t[idx], order };
    setImages(t);
  };
  const getWidthHeight = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.width, img.height]);
      img.onerror = reject;
      img.src = src;
    });
  };
  const imageValidation = async (file) => {
    let failedSize = "",
      failedDimensions = "";
    if (file?.size > 80000) {
      failedSize += file?.name + ", ";
    }
    let [width, height]: any = await getWidthHeight(
      window.URL.createObjectURL(file)
    );
    if (width > 400 || height > 400) {
      failedDimensions += file.name + ", ";
    }

    if (failedSize && failedDimensions)
      return `Image size should not exceed 80kb\nImage dimensions should not exceed 400px\n`;
    else if (failedSize) return `Image size should not exceed 80kb\n`;
    else if (failedDimensions)
      return `Image dimensions should not exceed 400px\n`;
    return false;
  };

  const brochureValidation = async (file, variant) => {
    let failedSize = "",
      failedDimensions = "";
    if (file?.size > 5000000) {
      failedSize += file?.name + ", ";
    }
    let [width, height]: any = await getWidthHeight(
      window.URL.createObjectURL(file)
    );
    const brochureAspectRatio = 1.61;
    if (Math.floor((width / height) * 100) / 100 !== brochureAspectRatio) {
      failedDimensions += file.name + ", ";
    }
    if (failedSize && failedDimensions)
      return `Brochure size must not exceed 5mb\n\n${`${variant} brochure aspect ratio should be 1.61\n`}`;
    else if (failedSize) return `Brochure size must not exceed 5mb\n`;
    else if (failedDimensions)
      return `${variant} brochure aspect ratio should be 1.61 \n`;
    return false;
  };

  const addImage = (i) => {
    setNoOfImages((prev) => ({ ...prev, [i]: [""] }));
  };

  const addBrochure = (i) => {
    setNoOfBrochures((prev) => ({ ...prev, [i]: [""] }));
  };

  const removeBrochure = (i) => {
    setBrochureImages((prev) => {
      const imgs = { ...prev };
      if (imgs[i] && Object.keys(imgs[i])?.length) delete imgs[i];
      return { ...imgs };
    });
    setNoOfBrochures((prev) => {
      const noOfImgs = { ...prev };
      delete noOfImgs[i];
      return { ...noOfImgs };
    });
  };

  const removeImage = (i) => {
    setImages((prev) => {
      const imgs = { ...prev };
      if (imgs[i] && Object.keys(imgs[i])?.length) delete imgs[i];
      return { ...imgs };
    });
    setNoOfImages((prev) => {
      const noOfImgs = { ...prev };
      delete noOfImgs[i];
      return { ...noOfImgs };
    });
  };

  function removeImageVariant(index, variant) {
    setImages((curr) => {
      const t = { ...curr };
      t[index][variant] = null;
      return t;
    });
  }

  function removeBrochureVariant(index, variant) {
    setBrochureImages((curr) => {
      const t = { ...curr };
      t[index][variant] = null;
      return t;
    });
  }

  const handleVideoRemove = () => {
    setVideo(null);
    setInfo({ ...info, video: null });
  };

  return {
    selectedTab,
    setSelectedTab,
    create,
    categories,
    setCategories,
    info,
    setInfo,
    attributesMetaData,
    setAttributesMetaData,
    attributesData,
    setAttributesData,
    images,
    setImages,
    getCat,
    saveProductLoader,
    displayName,
    generateDisplay,
    canGenerateDisplayName,
    fetchingCategory,
    handleImages,
    category,
    noOfImages,
    addImage,
    removeImage,
    handleImageSequence,
    categoryBrandsList,
    resetStates,
    displayNameLoader,
    searchedBrand,
    setSearchedBrand,
    imageUploadFailed,
    productRes,
    showProductDialog,
    setProductDialog,
    setDisplayName,
    categoryTenantsList,
    handleBrochures,
    noOfBrochures,
    addBrochure,
    removeBrochure,
    brochureImages,
    removeImageVariant,
    removeBrochureVariant,
    setVideo,
    asn,
    video,
    uploadVideo,
    uploadProgress,
    handleVideoRemove,
  };
}
