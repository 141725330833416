import classnames from "classnames";
import Loader from "./Loader";

type ButtonProps = {
  text: string;
  onButtonClick(): void;
  btnClass: string;
  loading?: boolean;
  disabled?: boolean;
  loadingText?: string;
};

export default function Button({
  text,
  onButtonClick,
  btnClass,
  loading = false,
  disabled = false,
  loadingText = "",
}: ButtonProps) {
  return (
    <button
      type="button"
      onClick={onButtonClick}
      className={classnames(
        "rounded-md px-30 py-8 font-bold text-white text-15 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
        btnClass,
        loading || disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
      )}
      disabled={disabled}
    >
      {loading ? (
        <div className="flex justify-between text-15 items-center">
          <Loader size="w-15 h-15" color="fill-white" />
          {loadingText}
        </div>
      ) : (
        text
      )}
    </button>
  );
}
