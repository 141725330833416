export const detailsPayload = {
  name: "",
  parent: null,
  hsnCode: "",
  gstPercentage: "",
  tenants: [],
  qcCapacity: null,
  qcColor: null,
  level: "",
  weightType: "",
  ancestors: [],
  active: true,
  displayNameLogic: [],
};
export const attributesPayload = [
  {
    _id: null,
    valueType: "",
    options: [""],
    isMandatory: false,
    attributePriority: "",
    isSearchable: false,
    tenants: [],
    filterPriority: "",
    type: "",
    name: "",
    filterOptions: [{ option: "" }],
    overridable: false,
  },
];
export const editCategoryBrandsPayload = [
  {
    name: "",
    tenants: [],
    _id: null,
  },
];
export const createCategoryBrandsPayload = [
  {
    _id: null,
    tenants: [],
  },
];
export const valueTypes = [
  { label: "Number", value: "number" },
  { label: "String", value: "string" },
  { label: "Dropdown", value: "dropdown" },
];
export const attributeTypes = [
  { label: "Specification", value: "specification" },
  { label: "Warranty", value: "warranty" },
];
export const categoryTabs = [
  { label: "Details", value: "details" },
  { label: "Attributes", value: "attributes" },
  { label: "Quality Check", value: "qc" },
  { label: "Brands", value: "brands" },
  { label: "Display Name Logic", value: "displayName" },
];
export const displayNameLogic = {
  brand: { isBrand: true },
  modelName: { isModelName: true },
  category: { isCategory: true },
  parentCategory: { isParentCategory: true },
  "(": { isSpecialChar: true, specialChar: "(" },
  ",": { isSpecialChar: true, specialChar: "," },
  ")": { isSpecialChar: true, specialChar: ")" },
};
export const displayNameOptions = [
  { label: "Brand", value: "brand" },
  { label: "Model Name", value: "modelName" },
  { label: "Category", value: "category" },
  { label: "Parent Category", value: "parentCategory" },
  { label: "(", value: "(" },
  { label: ",", value: "," },
  { label: ")", value: ")" },
];
export const weightTypes = [
  { label: "Heavy Weight", value: "HW" },
  { label: "Light Weight", value: "LW" },
];
export const levels = {
  0: "L0",
  1: "L1",
  2: "L2",
};
export const tenantsList = [
  {
    label: "ARZOOO",
    value: "ARZOOO",
  },
  {
    label: "GOSTOR",
    value: "GOSTOR",
  },
];
