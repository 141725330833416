/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, message, Spin } from "antd";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { VariantApiConfig } from "api/configs/variantApiConfig";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
  createSelectList,
  handleError,
  makeList,
  makeProductsSelectionList,
} from "utils/helper";
import { DeleteOutlined } from "@ant-design/icons";
import { useApp } from "context/appContext";
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { VariantAttributes } from "./VariantAttributes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axiosInstance from "api/apiClient";
import { toast } from "react-toastify";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function EditVariants() {
  const [selectedVariant, setSelectedVariant] = useState("");
  const [searchedVariant, setSearchedVariant] = useState("");
  const [matchedVariants, setMatchedVariants] = useState([]);
  const [variantData, setVariantData]: any = useState({});
  const [searchedAsn, setSearchedAsn] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [attributesMetaData, setAttributesMetaData] = useState([]);
  const [searchedAttribute, setSearchedAttribute] = useState("");
  const [variantCategory, setVariantCategory] = useState("");
  const [isAttributesModalOpen, setIsAttributesModalOpen] = useState(false);
  const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState("");
  const [hasCopied, setHasCopied] = useState(false);

  const { tenant } = useApp();

  useEffect(() => {
    if (variantCategory) {
      setTimeout(() => getCat(), 100);
    }
  }, [variantCategory]);

  useEffect(() => {
    if (searchedVariant.length > 2) {
      setTimeout(() => searchVariant(), 100);
    }
  }, [searchedVariant]);

  useEffect(() => {
    if (searchedAsn.length > 2) {
      setTimeout(() => getSearchedProducts(), 100);
    }
  }, [searchedAsn]);

  useEffect(() => {
    if (selectedProductId) setTimeout(() => getProduct(), 100);
  }, [selectedProductId]);

  useEffect(() => {
    if (productIdToBeDeleted) {
      setTimeout(() => {
        deleteVariantProduct(null);
      }, 100);
    }
  }, [productIdToBeDeleted]);

  const { refetch: getProduct } = useApiQuery(
    ProductApiConfig.GET_PRODUCT_BY_ID,
    {
      pathParams: { productId: selectedProductId, tenant },
      successCb: handleProduct,
    }
  );

  function isProductEligible(productAttributes) {
    return variantData.attributes?.every(({ id }) =>
      productAttributes?.find(
        ({ k, v }) => k === id && ![null, undefined, ""].includes(v)
      )
    );
  }

  function handleProduct(product) {
    const { _id: id, displayName, attributes, variant, asn } = product;
    if (variant) {
      message.error("Product already has variants");
      return;
    }
    if (!isProductEligible(attributes)) {
      message.error("Product doesn't have the selected attribute values");
      return;
    }
    if (!variantData.variants?.find((product) => product.id === id)) {
      setVariantData((prev) => ({
        ...prev,
        variants: [...(prev.variants ?? []), { id, displayName, asn }],
      }));
      message.success(`Added product: ${displayName} successfully`);
    }
  }

  const { refetch: searchVariant } = useApiQuery(
    VariantApiConfig.SEARCH_VARIANT,
    {
      params: {
        searchString: searchedVariant,
        size: 2,
      },
      dataParser: makeList,
      successCb: (data) => {
        setMatchedVariants(data);
      },
      errorCb: handleError,
    }
  );

  const { refetch: getSearchedProducts } = useApiQuery(
    ProductApiConfig.SEARCH_PRODUCTS,
    {
      pathParams: { tenant },
      params: { text: searchedAsn },
      dataParser: makeProductsSelectionList,
      successCb: setSearchedProducts,
      errorCb: () => setSearchedProducts([]),
    }
  );

  const { refetch: getCat } = useApiQuery(
    CategoryApiConfig.GET_CATEGORY_BY_ID,
    {
      pathParams: {
        id: variantCategory,
        tenant,
      },
      successCb: (data) => {
        const { attributes } = data;
        setAttributesMetaData(
          createSelectList(
            attributes?.filter(({ isMandatory }) => isMandatory),
            "name",
            "_id"
          )
        );
      },
    }
  );
  const { refetch: getVariant, isFetching: getVariantLoader } = useApiQuery(
    VariantApiConfig.GET_VARIANT,
    {
      pathParams: { id: selectedVariant },
      successCb: (data) => {
        setVariantData({
          ...data,
          variants: data.variants.map((x) => ({ ...x, isExisting: true })),
        });
        setVariantCategory(data?.category?.id);
      },
      errorCb: handleError,
    }
  );

  const { mutate: updateVariant, isLoading: updating } = useApiMutate(
    VariantApiConfig.UPDATE_VARIANT,
    {
      errorCb: handleError,
    }
  );

  const { mutate: deleteVariantProduct, isLoading: deleting } = useApiMutate(
    VariantApiConfig.DELETE_VARIANT_PRODUCT,
    {
      pathParams: {
        variantId: variantData.id,
        productId: productIdToBeDeleted,
      },
      successCb: () => {
        setProductIdToBeDeleted("");
        setIsProductsModalOpen(false);
        getVariant();
      },
      errorCb: handleError,
    }
  );

  const { mutate: deleteVariant, isLoading: deletingVariant } = useApiMutate(
    VariantApiConfig.DELETE_VARIANT,
    {
      pathParams: {
        variantId: variantData.id,
      },
      successCb: () => {
        setVariantData({});
      },
      errorCb: handleError,
    }
  );

  function deleteProduct(productId) {
    if (window.confirm("Are you sure to delete the product?"))
      setProductIdToBeDeleted(productId);
  }

  function removeAttribute(attributeId) {
    setVariantData((prev) => {
      const attributes = prev.attributes.filter(({ id }) => id !== attributeId);
      return { ...prev, attributes };
    });
  }

  function removeProduct(productId) {
    setVariantData((prev) => {
      const variants = prev.variants.filter(({ id }) => id !== productId);
      return { ...prev, variants };
    });
  }

  function update() {
    if (!variantData?.brand) {
      message.error("Please select brand");
      return;
    }
    if (!variantData?.name?.trim()) {
      message.error("Please provide a name");
      return;
    }
    if (!variantData?.attributes?.length) {
      message.error("Please select attributes");
      return;
    }
    if (!variantData?.variants?.length) {
      message.error("Please select products");
      return;
    }
    updateVariant(variantData);
  }

  async function deleteVariantProducts() {
    for (const { id } of variantData?.variants) {
      const res = await deleteVariantProductById(id);
      if (!res?.data) {
        toast.error("Unable to delete product");
        return;
      }
    }
  }

  async function deleteVariantProductById(productId) {
    return await axiosInstance({
      ...VariantApiConfig.DELETE_VARIANT_PRODUCT,
      url: `/variant/${variantData?.id}/product/${productId}`,
    });
  }

  return (
    <>
      <div className="flex justify-start gap-x-20">
        <div className="w-[30%]">
          <Autocomplete
            selected={selectedVariant}
            onSearch={debounce((value) => {
              setSearchedVariant(value);
            }, 300)}
            onChange={({ value }) => {
              setSelectedVariant(value);
            }}
            placeholder="Search variant to edit..."
            list={matchedVariants ?? []}
            apiSearch
            searchedValue={searchedVariant}
          />
        </div>
        <Button
          text={"Submit"}
          onButtonClick={getVariant}
          btnClass="bg-btn"
          loadingText="Fetching..."
          disabled={getVariantLoader}
          loading={getVariantLoader}
        />
      </div>
      {variantData?.variants && (
        <>
          <div className="flex flex-col gap-y-40 pt-40">
            <div className="flex items-center gap-x-60">
              <label className="w-[6%] font-bold text-textColor">
                <span className="text-red pr-5">*</span>
                Brand
              </label>
              <div className="w-[30%] font-bold">
                {variantData?.brand?.name}
              </div>
            </div>
            <div className="flex items-center gap-x-60">
              <label className="w-[6%] font-bold text-textColor">
                <span className="text-red pr-5">*</span>
                Name
              </label>
              <div className="w-[37%]">
                <Input
                  inputType={"text"}
                  value={variantData.name}
                  name={"name"}
                  onChange={({ target: { value } }) => {
                    if (value.indexOf(variantData?.brand?.name) !== 0)
                      value = `${variantData?.brand?.name}`;
                    setVariantData((prev) => ({ ...prev, name: value }));
                  }}
                  mandatory
                />
              </div>
            </div>
            <div className="flex items-center gap-x-60">
              <label className="w-[6%] font-bold text-textColor">
                <span className="text-red pr-5">*</span>
                Attributes
              </label>
              <div className="w-[30%]">
                <Autocomplete
                  selected={
                    variantData.attributes?.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    })) ?? []
                  }
                  onChange={(x) => {
                    if (variantData.attributes?.length === 3) {
                      message.info("Only 3 attributes can be added");
                      return;
                    }
                    if (variantData?.variants?.length) {
                      const flag = window.confirm(
                        "Click OK only if you've copied the products"
                      );
                      if (!flag) return;
                    }
                    deleteVariantProducts();
                    const { value: id, label: name } = x[x?.length - 1];
                    if (
                      !variantData?.attributes?.find(
                        ({ id: attrId }) => attrId === id
                      )
                    ) {
                      const attributes = [
                        ...(variantData?.attributes ?? []),
                        { id, name },
                      ];
                      setVariantData((prev) => ({
                        ...prev,
                        attributes,
                        variants: [],
                      }));
                      message.success(`Added attribute: ${name} successfully`);
                    }
                  }}
                  placeholder="Search attribute"
                  list={attributesMetaData}
                  searchedValue={searchedAttribute}
                  onSearch={(v) => setSearchedAttribute(v)}
                  multiselect={true}
                />
              </div>
              <div>
                {variantData?.attributes?.length > 0 && (
                  <Button
                    text={"View attributes"}
                    onButtonClick={() => setIsAttributesModalOpen(true)}
                    btnClass={"bg-btn"}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center gap-x-60">
              <label className="w-[6%] font-bold text-textColor">
                Pack variants
              </label>
              <Checkbox
                checked={variantData?.pack}
                onChange={({ target: { checked } }) => {
                  setVariantData({ ...variantData, pack: checked });
                }}
                autoFocus
              >
                {variantData?.pack ? "Enabled" : "Disabled"}
              </Checkbox>
            </div>
            <div className="flex items-center gap-x-60">
              <label className="w-[6%] font-bold text-textColor">
                <span className="text-red pr-5">*</span>
                Products
              </label>
              <div className="w-[30%]">
                <Autocomplete
                  selected={
                    variantData.variants?.map(({ id, asn }) => ({
                      label: asn,
                      value: id,
                    })) ?? []
                  }
                  onSearch={debounce((value) => {
                    setSearchedAsn(value);
                  }, 300)}
                  onChange={(x) => {
                    if (!variantData.attributes.length) {
                      message.info("Please select attributes first");
                      return;
                    }
                    const { value: productId } = x[x.length - 1];
                    setSelectedProductId(productId);
                  }}
                  placeholder="Search ASN or Model name"
                  list={searchedProducts}
                  apiSearch
                  searchedValue={searchedAsn}
                  multiselect={true}
                />
              </div>
              <div>
                {variantData?.variants?.length > 0 && (
                  <Button
                    text={"View products"}
                    onButtonClick={() => setIsProductsModalOpen(true)}
                    btnClass={"bg-btn"}
                  />
                )}
              </div>
            </div>
            <div className="flex">
              <Button
                text={"Update"}
                onButtonClick={update}
                btnClass={"bg-btn"}
                disabled={updating}
                loading={updating}
                loadingText="Updating..."
              />
              <Button
                text={"Delete"}
                onButtonClick={() => {
                  if (window.confirm("Are you sure to delete the variant?"))
                    deleteVariant(null);
                }}
                btnClass={"bg-red ml-30"}
                disabled={deletingVariant}
                loading={deletingVariant}
                loadingText="Deleting..."
              />
            </div>
          </div>
          <Modal
            title="Attributes"
            open={isAttributesModalOpen}
            onOk={() => setIsAttributesModalOpen(false)}
            onCancel={() => setIsAttributesModalOpen(false)}
            footer={[
              <CopyToClipboard
                text={variantData?.attributes
                  ?.map(({ name }) => name)
                  ?.join(", ")}
                onCopy={() => {
                  setHasCopied(true);
                  setTimeout(() => {
                    setHasCopied(false);
                  }, 1000);
                }}
                className="text-blue-400 mb-12"
              >
                <button>
                  {hasCopied ? (
                    <span className="text-green-500 no-underline text-16">
                      Copied!
                    </span>
                  ) : (
                    <span className="text-16">Copy</span>
                  )}
                </button>
              </CopyToClipboard>,
            ]}
          >
            <VariantAttributes
              cards={variantData?.attributes}
              setCards={(attributes) =>
                setVariantData({ ...variantData, attributes })
              }
              removeAttribute={removeAttribute}
            />
          </Modal>
          <Modal
            title="Products"
            open={isProductsModalOpen}
            onOk={() => setIsProductsModalOpen(false)}
            onCancel={() => setIsProductsModalOpen(false)}
            footer={[
              <CopyToClipboard
                text={variantData?.variants
                  ?.map(({ displayName }) => displayName)
                  ?.join(", ")}
                onCopy={() => {
                  setHasCopied(true);
                  setTimeout(() => {
                    setHasCopied(false);
                  }, 1000);
                }}
                className="text-blue-400 mb-12"
              >
                <button>
                  {hasCopied ? (
                    <span className="text-green-500 no-underline text-16">
                      Copied!
                    </span>
                  ) : (
                    <span className="text-16">Copy</span>
                  )}
                </button>
              </CopyToClipboard>,
            ]}
          >
            <Spin spinning={deleting}>
              {variantData?.variants?.map((x) => (
                <div
                  className="font-bold bg-slate-300 rounded-md flex justify-between items-center p-3 mb-15"
                  key={x.id}
                >
                  <span className="p-5">{x.displayName}</span>
                  <DeleteOutlined
                    className="p-3 cursor-pointer"
                    onClick={() =>
                      x.isExisting ? deleteProduct(x.id) : removeProduct(x.id)
                    }
                  />
                </div>
              ))}
            </Spin>
          </Modal>
        </>
      )}
    </>
  );
}
