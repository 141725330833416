import UploadVideo from "components/ui/UploadVideo";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import usePermissions from "hooks/usePermissions";
import { useSearchParams } from "react-router-dom";

export default function ProductVideos({
  info,
  isEdit = false,
  setVideo,
  video,
  uploadProgress = null,
  handleVideoRemove = null,
  uploadVideo = null,
  url = "",
}) {
  const { canRead, canUpdate, canAccess } = usePermissions();
  const { tenant, isProductApprovalEnabled } = useApp();
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from");
  const cannotEdit =
    isEdit &&
    (isProductApprovalEnabled && info.status && info.status !== APPROVED_STATUS
      ? fromPage !== "approver" &&
        (info.status === PENDING_STATUS ||
          (info.status === DRAFT_STATUS && !canAccess(PRODUCT_CREATOR_PAGE)))
      : (canRead(PRODUCT) && !canUpdate(PRODUCT)) || tenant === MASTER_TENANT);

  return (
    <div className="flex flex-col p-30 bg-white">
      <div className="grid grid-cols-5 items-center">
        <UploadVideo
          setVideo={setVideo}
          video={video}
          uploadProgress={uploadProgress}
          handleVideoRemove={handleVideoRemove}
          uploadVideo={uploadVideo}
          url={url}
          disabled={cannotEdit}
        />
      </div>
    </div>
  );
}
