export const UserApiConfig: any = {
  GET_USER_BY_ID: {
    url: "/user/:id",
    service: "AUTH_CATALOGUE",
  },
  GET_USER_BY_MAIL: {
    url: "/userByEmail",
    service: "AUTH_CATALOGUE",
    enabled: false,
  },
  GET_PERMISSIONS: {
    url: "/user/:userId/tenant/:tenantId/permission",
    service: "AUTH_CATALOGUE",
  },
  GET_ROLES_BY_OFFERING: {
    url: "/user/:id",
    service: "AUTH_CATALOGUE",
  },
  CREATE_USER: {
    url: "/user",
    method: "post",
    service: "AUTH_CATALOGUE",
  },
};
