import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, ReactNode } from "react";
import classNames from "classnames";

type SelectListProps = {
  list: any;
  onChange: any;
  selected: any;
  multiselect?: boolean;
  placeholder?: string;
  labelType?: "select" | "dropdown";
  labelClassName?: string;
  label?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  name?: string;
  mandatory?: boolean;
  width?: string;
  disabled?: boolean;
  hasDefaultSelect?: boolean;
};

const SelectList = ({
  list,
  onChange,
  selected,
  multiselect = false,
  placeholder,
  labelType = "select",
  labelClassName = "text-14",
  fullWidth = false,
  label,
  children,
  name,
  mandatory = false,
  width = "w-[185px]",
  disabled = false,
  hasDefaultSelect = true,
}: SelectListProps) => {
  const getDisplayLabel = () => {
    // if (label) return label;
    if (multiselect) {
      if (!selected?.length) return;
      const selectedItems = list
        .filter((item) => selected.includes(item.value))
        .map((item) => item.label);
      if (!selectedItems.length) return;
      return selectedItems.join(", ");
    } else {
      if (!selected) return;
      const selectedItem = list.find((item) => selected === item.value);
      if (!selectedItem) return;
      return selectedItem.label;
    }
  };
  const displaylabel = getDisplayLabel();

  return (
    <div>
      <Listbox
        value={selected}
        onChange={onChange}
        multiple={multiselect}
        name={name}
        disabled={disabled}
      >
        {children ? (
          <Listbox.Button className="relative">{children}</Listbox.Button>
        ) : labelType === "dropdown" ? (
          <Listbox.Button
            className={classNames(
              "relative inline-flex items-center justify-between h-40 bg-[#ECF0F4] font-bold text-16 px-10 rounded",
              labelClassName
            )}
          >
            <span className={classNames("mr-6", !displaylabel && "opacity-50")}>
              {displaylabel || placeholder}
            </span>
            <ChevronDownIcon
              className="w-20 h-20"
              fillOpacity={0.8}
              aria-hidden="true"
            />
          </Listbox.Button>
        ) : (
          <div className="w-[90%]">
            {label && (
              <label className="pr-20 text-right w-[20%] font-bold">
                {mandatory ? <span className="text-red pr-5">*</span> : ""}
                {label}
              </label>
            )}
            <Listbox.Button
              className={classNames(
                "relative rounded-md border bg-white py-6 pl-3 pr-10 text-left text-13 h-40",
                fullWidth ? "w-full" : width
              )}
            >
              <span
                className={classNames(
                  "block truncate",
                  !displaylabel && "opacity-50"
                )}
              >
                {displaylabel || placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-16 w-16 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
          </div>
        )}

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={classNames(
              "absolute mt-1 max-h-[300px] overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-12 z-[1]",
              fullWidth
                ? "w-full"
                : labelType === "dropdown"
                ? "bg-white"
                : width
            )}
          >
            {hasDefaultSelect ? (
              <Listbox.Option
                key={"select"}
                value={""}
                className={
                  "relative cursor-default select-none py-6 pl-10 pr-4 text-left"
                }
              >
                --Select--
              </Listbox.Option>
            ) : null}
            {list?.map((item) => (
              <Listbox.Option
                key={item.value}
                value={item.value}
                className={({ active }) =>
                  `relative cursor-default select-none py-6 pl-10 pr-4 text-left ${
                    active
                      ? labelType === "dropdown"
                        ? "bg-[#ECF0F4]"
                        : "bg-primary text-white"
                      : "text-gray-900"
                  }`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate text-15 ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {item.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default SelectList;
