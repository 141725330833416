import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon,
  FolderIcon,
  // Cog8ToothIcon,
  // ComputerDesktopIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import { NavLink, useSearchParams } from "react-router-dom";
import ArzoooLogo from "./ArzoooLogo";
import classNames from "classnames";
import { getPageLabel, getTenants } from "utils/helper";
import usePermissions from "hooks/usePermissions";
import SelectList from "./SelectList";
import { useApp } from "context/appContext";
import { MASTER_TENANT } from "constants/commonConstants";

function SideMenu({ isOpen, close }) {
  const sideNavItems = [
    {
      label: "Merchandise",
      icon: <Squares2X2Icon className="h-20 w-20" />,
      children: [
        { label: "Attributes", value: "attributes" },
        { label: "Categories", value: "categories" },
        { label: "Brands", value: "brands" },
        { label: "Products", value: "products" },
        { label: "Variants", value: "variants" },
        { label: "Bulk Update", value: "bulk-update" },
        { label: "Product Creator Page", value: "product-creator" },
        { label: "Product Approver Page", value: "product-approver" },
        { label: "Product Bulk Upload", value: "product-bulk-upload" },
      ],
    },
    // {
    //   label: "Settings",
    //   icon: <Cog8ToothIcon className="h-20 w-20" />,
    //   children: [],
    // },
    // {
    //   label: "Administration",
    //   icon: <ComputerDesktopIcon className="h-20 w-20" />,
    //   children: [
    //     { label: "Users", value: "users" },
    //     { label: "Roles", value: "roles" },
    //     { label: "Permissions", value: "permissions" },
    //   ],
    // },
  ];
  const { canAccess } = usePermissions();
  const onNavClick = (e, page) => {
    const label = getPageLabel(page);
    return !canAccess(label) ? e.preventDefault() : close();
  };

  const { tenant, setTenant, setTenantLoader } = useApp();

  const [searchParams, setSearchParams] = useSearchParams();

  function deleteSearchParams() {
    const searchParamsCopy = searchParams;
    if (searchParamsCopy.get("id")) {
      searchParamsCopy.delete("id");
    }
    if (searchParamsCopy.get("from")) {
      searchParamsCopy.delete("from");
    }
    if (searchParamsCopy.get("catId")) {
      searchParamsCopy.delete("catId");
    }
    setSearchParams(searchParamsCopy);
  }

  return (
    <div
      className={classNames(
        "h-screen bg-sidemenu shadow-3xl top-0 left-0 overflow-x-hidden transition-[width] duration-100 ease-in fixed z-[1]",
        isOpen ? "w-[18%]" : "w-0"
      )}
    >
      <div className="w-full px-4 pt-16">
        <div className="pl-30">
          <ArzoooLogo />
          <p className="text-[40px] font-bold text-primary">Catalogue</p>
        </div>
        <div className="flex justify-center pb-30">
          <SelectList
            list={getTenants()}
            onChange={(v) => {
              setTenantLoader(true);
              setTenant(v);
              setTimeout(() => {
                setTenantLoader(false);
              }, 1000);
              tenant === MASTER_TENANT && deleteSearchParams();
            }}
            selected={tenant}
            placeholder="--Select--"
            width={"w-[15vw]"}
            hasDefaultSelect={false}
          />
        </div>
        <div className="w-full max-w-md p-2 flex flex-col">
          {sideNavItems.map(({ label, icon, children }, i) => {
            return (
              <Disclosure key={i}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        "flex justify-between items-center px-4 py-10 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                      )}
                    >
                      <div className="flex justify-between">
                        {icon}
                        <span className="text-15 pl-15">{label}</span>
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-15 w-15`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="flex flex-col justify-between px-4 pt-4 pb-2 text-15 text-white bg-[#3E4449]">
                      {children.map(({ label, value }, i) => {
                        return (
                          <NavLink
                            to={value}
                            className={({ isActive }) =>
                              isActive ? "tab-active" : "opacity-50"
                            }
                            onClick={(e) => onNavClick(e, value)}
                            key={i}
                          >
                            <div className="flex py-10">
                              <FolderIcon className="h-20 w-20 text-white" />
                              <span className="pl-15 text-white">{label}</span>
                            </div>
                          </NavLink>
                        );
                      })}
                    </Disclosure.Panel>
                    {open && (
                      <div className="absolute left-0 w-6 h-40 bg-primary" />
                    )}
                  </>
                )}
              </Disclosure>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
