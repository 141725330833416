/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { AttributeApiConfig } from "api/configs/attributeApiConfig";
import { BrandApiConfig } from "api/configs/brandApiConfig";
import { CategoryApiConfig } from "api/configs/categoryConfig";
import AddValueOptions from "components/features/categories/AddValueOptions";
import AttributesTable from "components/features/categories/AttributesTable";
import CategoryDetails from "components/features/categories/CategoryDetails";
import DisplayNameLogic from "components/features/categories/DisplayNameLogic";
import FilterOptions from "components/features/categories/FilterOptions";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import SelectList from "components/ui/SelectList";
import Table, { TableColumns } from "components/ui/Table";
import Tabs from "components/ui/Tabs";
import Title from "components/ui/Title";
import {
  attributesPayload,
  editCategoryBrandsPayload,
  categoryTabs,
  detailsPayload,
  displayNameLogic,
} from "constants/categoryConstants";
import { CATEGORY } from "constants/commonConstants";
import { useApp } from "context/appContext";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import useLists from "hooks/useLists";
import usePermissions from "hooks/usePermissions";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IsCategorySequencingAccessable,
  buildValidArray,
  handleError,
  makeList,
} from "utils/helper";

export function Categories() {
  const [searchedCategory, setSearchedCategory] = useState("");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("details");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    ...detailsPayload,
    _id: "",
    attributes: [...attributesPayload],
    brands: [...editCategoryBrandsPayload],
  });
  const [displayNameValues, setDisplayNameValues] = useState([{ d: "" }]);
  const [currAttr, setCurrAttr] = useState(0);
  const { tenants } = useLists();
  const [showOptions, setShowOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchedAttribute, setSearchedAttribute] = useState({
    index: -1,
    text: "",
  });
  const [categories, setCategories]: any = useState();
  const [categoriesList, setCategoriesList]: any = useState([]);
  const [replacements, setReplacements] = useState([]);
  const parseCategories = (data) => {
    return data.map((cat) => ({ label: cat.name, value: cat._id }));
  };
  const { refetch: fetchCategories } = useApiQuery(
    CategoryApiConfig.GET_CATEGORY_BY_NAME,
    {
      params: { name: searchedCategory },
      successCb: (data) => {
        setCategoriesList(parseCategories(data));
        setCategories(data);
      },
    }
  );
  const [attributesList, setAttributesList] = useState([]);
  const { refetch: getAttrByName } = useApiQuery(
    AttributeApiConfig.GET_ATTRIBUTE_BY_NAME,
    {
      params: { name: searchedAttribute.text },
      dataParser: makeList,
      successCb: (data) => setAttributesList(data),
    }
  );
  const { canUpdate, canCreate } = usePermissions();
  useEffect(() => {
    if (searchedAttribute?.text?.length > 2) getAttrByName();
  }, [searchedAttribute]);
  const { tenant } = useApp();
  const { refetch: getCategory } = useApiQuery(
    CategoryApiConfig.GET_CATEGORY_BY_ID,
    {
      pathParams: { tenant, id: selectedCategory._id },
      successCb: setCategoryData,
    }
  );
  const { mutate: updateCategory, isLoading } = useApiMutate(
    CategoryApiConfig.UPDATE_CATEGORY,
    {
      pathParams: { id: selectedCategory._id },
      successCb: getCategory,
      errorCb: handleError,
    }
  );
  const fetchAttrs = (v, i) => {
    setSearchedAttribute({ text: v, index: i });
  };
  useEffect(() => {
    if (searchedCategory) fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCategory]);
  useEffect(() => {
    const logic = [];
    if (selectedCategory?.displayNameLogic?.length) {
      selectedCategory?.displayNameLogic.forEach((logicType) => {
        if (logicType.isBrand) logic.push({ d: "brand" });
        else if (logicType.isCategory) logic.push({ d: "category" });
        else if (logicType.isParentCategory)
          logic.push({ d: "parentCategory" });
        else if (logicType.isModelName) logic.push({ d: "modelName" });
        else if (logicType.isSpecialChar && logicType.specialChar === "(")
          logic.push({ d: "(" });
        else if (logicType.isSpecialChar && logicType.specialChar === ")")
          logic.push({ d: ")" });
        else if (logicType.isSpecialChar && logicType.specialChar === ",")
          logic.push({ d: "," });
        else if (logicType?.attributeId?.length) {
          logic.push({
            d: logicType?.attributeId,
          });
        }
      });
      setDisplayNameValues(logic);
    } else setDisplayNameValues([{ d: "" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);
  const addAttr = () => {
    setSelectedCategory((prev) => {
      const attributes = [...prev.attributes, attributesPayload[0]];
      return { ...prev, attributes };
    });
  };

  const handleAttributeData = (i, { name, value }) => {
    setAttributesList([]);
    setSearchedAttribute({ index: -1, text: "" });
    setSelectedCategory((current) => {
      const attributes = current.attributes.map((obj, idx) => {
        if (i === idx) {
          if (name === "_id") {
            return { ...obj, _id: value?.value, name: value?.label };
          }
          return { ...obj, [name]: value };
        }
        return obj;
      });
      return { ...current, attributes };
    });
  };
  const getDisplayNameLogic = () => {
    const logic = [];
    displayNameValues.forEach(({ d }) => {
      if (d) {
        if (displayNameLogic.hasOwnProperty(d)) logic.push(displayNameLogic[d]);
        else {
          const attributeLogic = replacements.find(
            ({ attributeId }) => attributeId === d
          );
          if (attributeLogic) logic.push(attributeLogic);
          else logic.push({ attributeId: d });
        }
      }
    });
    return logic.length ? logic : null;
  };

  const [searchedBrand, setSearchedBrand] = useState({ index: -1, text: "" });
  const [brandsList, setBrandsList] = useState([]);
  const { refetch } = useApiQuery(BrandApiConfig.GET_BRAND_BY_NAME, {
    params: { name: searchedBrand.text },
    dataParser: makeList,
    successCb: (data) => setBrandsList(data),
  });
  const add = () => {
    setSelectedCategory((prev) => {
      const brands = [...prev.brands, ...editCategoryBrandsPayload];
      return { ...prev, brands };
    });
  };

  useEffect(() => {
    if (searchedBrand.text.length > 1) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedBrand]);

  const fetchBrands = (v, i) => {
    setSearchedBrand({ text: v, index: i });
  };

  const CategoryBrandsColumns: TableColumns[] = [
    {
      Header: "Brand",
      accessor: "_id",
      Cell: ({
        value,
        row: {
          index,
          original: { name, _id },
        },
      }) => (
        <Autocomplete
          selected={value}
          onSearch={debounce((value) => {
            fetchBrands(value, index);
          }, 300)}
          onChange={({ value: _id, label: name }) => {
            setBrandsList([]);
            setSearchedBrand({ index: -1, text: "" });
            setSelectedCategory((prev) => {
              const brands = prev.brands.map((obj, i) => {
                if (i === index) return { ...obj, _id, name };
                return obj;
              });
              return { ...prev, brands };
            });
          }}
          placeholder="Search brands..."
          list={
            brandsList.length && searchedBrand.index === index
              ? brandsList
              : [{ label: name, value: _id }]
          }
          apiSearch
          searchedValue={
            searchedBrand.index === index ? searchedBrand.text : ""
          }
          disabled={!canUpdate(CATEGORY)}
        />
      ),
    },
    {
      Header: "Tags",
      accessor: "tenants",
      Cell: ({ value, row: { index } }) => (
        <SelectList
          placeholder="Select tags"
          list={tenants}
          onChange={(tenants) => {
            setSelectedCategory((prev) => {
              const brands = prev.brands.map((obj, i) => {
                if (i === index) return { ...obj, tenants };
                return obj;
              });
              return { ...prev, brands };
            });
          }}
          selected={value}
          multiselect
          disabled={!canUpdate(CATEGORY)}
        />
      ),
    },
    {
      Header: "Add",
      Cell: ({ row: { index } }) => (
        <div className="flex justify-center gap-x-10">
          {index === selectedCategory?.brands.length - 1 && (
            <PlusCircleIcon
              className="w-20 h-20 cursor-pointer"
              fill="green"
              onClick={() => canUpdate(CATEGORY) && add()}
            />
          )}
        </div>
      ),
    },
  ];

  function getBrandsPayload() {
    const categoryBrands = selectedCategory?.brands
      .filter(({ _id }) => _id)
      .map(({ _id, tenants }) => ({
        _id,
        tenants: buildValidArray(tenants),
      }));
    return categoryBrands?.length ? categoryBrands : null;
  }

  function getAttributesPayload() {
    const categoryAttributes = selectedCategory?.attributes
      .filter(({ _id }) => _id)
      .map(
        ({
          valueType = null,
          options = null,
          filterOptions = null,
          filterPriority = null,
          tenants = null,
          ...rest
        }) => ({
          ...rest,
          valueType: valueType === "number" ? "integer" : valueType,
          options: options?.filter((option) => option),
          filterOptions: filterOptions
            ?.filter(({ option }) => option)
            ?.map(({ option }) => option),
          filterPriority: filterPriority === "" ? null : filterPriority,
          tenants: buildValidArray(tenants),
        })
      );
    return categoryAttributes?.length ? categoryAttributes : null;
  }

  function buildPayload() {
    const payload = {
      ...selectedCategory,
      displayNameLogic: getDisplayNameLogic(),
      brands: getBrandsPayload(),
      attributes: getAttributesPayload(),
      tenants: buildValidArray(selectedCategory.tenants),
    };
    return payload;
  }

  function handleUpdateCategory() {
    if (!selectedCategory.name) return alert("Provide category name");
    if (!selectedCategory.hsnCode) return alert("Provide HSN code");
    if (!selectedCategory.gstPercentage) return alert("Provide GST Percentage");
    if (!selectedCategory.tenants) return alert("Provide category tags");

    for (const att of selectedCategory.attributes) {
      if (!att?._id) return alert("Select attribute");
      else if (!att?.type) return alert("Attribute type is mandatory");
      else if ([null, ""].includes(att.attributePriority))
        return alert("Attribute priority is mandatory");
      else if (!att?.valueType)
        return alert("Attribute value type is mandatory");
      else if (
        att?.filterPriority !== null &&
        att?.filterPriority !== "" &&
        att?.filterOptions?.filter(({ option }) => option?.length)?.length === 0
      ) {
        return alert(
          "Filter options is mandatory if filter priority is assigned"
        );
      } else if (!att?.tenants?.length)
        return alert("Attribute tags are mandatory");
    }

    const payload = buildPayload();
    updateCategory(payload);
  }

  function handleCategoryDetails(value) {
    setSelectedCategory((prev) => ({ ...prev, ...value }));
  }

  function setCategoryData(category) {
    setSelectedCategory({
      ...category,
      attributes: category.attributes?.length
        ? category.attributes.map((attr) => {
            if (!attr?.options?.length) attr = { ...attr, options: [""] };
            if (!attr?.filterOptions?.length)
              attr = { ...attr, filterOptions: [{ option: "" }] };
            else {
              attr.filterOptions = attr.filterOptions.map((fo) => ({
                option: fo,
                type: "existing",
              }));
            }
            return attr;
          })
        : [...attributesPayload],
      brands: category.brands?.length
        ? [...category.brands]
        : [...editCategoryBrandsPayload],
    });
    setReplacements(
      category.displayNameLogic.filter((logic) => {
        const { attributeId, prefix, suffix, DefaultIfBlank, label } = logic;
        return attributeId && (prefix || suffix || DefaultIfBlank || label);
      })
    );
  }

  return (
    <div className="p-20">
      <Title title="Category" />
      <div className="flex justify-start gap-x-20 p-20">
        <Autocomplete
          selected={selectedCategoryId}
          onSearch={debounce((value) => {
            setSearchedCategory(value);
          }, 300)}
          onChange={({ value }) => {
            setSelectedCategoryId(value);
            const category = categories.find(({ _id }) => value === _id);
            setCategoryData(category);
          }}
          placeholder="Search categories..."
          list={categoriesList}
          apiSearch
          searchedValue={searchedCategory}
        />
        <Button
          text={"+ New Category"}
          onButtonClick={() => navigate("/categories/new-category")}
          btnClass="bg-btn"
          disabled={!canCreate(CATEGORY)}
        />
        <Button
          text={"Change Product Category"}
          onButtonClick={() => navigate("/categories/change-category")}
          btnClass="bg-btn"
          disabled={!canUpdate(CATEGORY)}
        />
        {IsCategorySequencingAccessable() && (
          <Button
            text={"Category Sequencing"}
            onButtonClick={() => navigate("/categories/category-sequencing")}
            btnClass="bg-btn"
          />
        )}
      </div>
      {selectedCategory._id && (
        <div className="p-20">
          <div>
            <Tabs
              list={categoryTabs}
              selectedTab={selectedTab}
              onClick={setSelectedTab}
            />
          </div>
          {selectedTab === "details" && (
            <CategoryDetails
              details={selectedCategory}
              updateDetails={handleCategoryDetails}
              isEdit
            />
          )}
          {selectedTab === "attributes" && (
            <AttributesTable
              attributes={selectedCategory.attributes}
              handleAttributeData={handleAttributeData}
              fetchAttrs={fetchAttrs}
              attributesList={attributesList}
              searchedAttribute={searchedAttribute}
              setCurrAttr={setCurrAttr}
              setShowOptions={setShowOptions}
              setShowFilterOptions={setShowFilterOptions}
              addAttr={addAttr}
              isEditCategory
            />
          )}
          {selectedTab === "qc" && (
            <div className="flex justify-start gap-x-40 py-40">
              <SelectList
                list={selectedCategory?.attributes?.map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))}
                onChange={(qcCapacity) => {
                  setSelectedCategory({ ...selectedCategory, qcCapacity });
                }}
                selected={selectedCategory?.qcCapacity}
                placeholder="Select QC capacity"
                disabled={!canUpdate(CATEGORY)}
              />
              <SelectList
                list={selectedCategory?.attributes?.map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))}
                onChange={(qcColor) => {
                  setSelectedCategory({ ...selectedCategory, qcColor });
                }}
                selected={selectedCategory?.qcColor}
                placeholder="Select QC color"
                disabled={!canUpdate(CATEGORY)}
              />
            </div>
          )}
          {selectedTab === "brands" && (
            <div className="py-20">
              <Table
                columns={CategoryBrandsColumns}
                data={selectedCategory?.brands}
                loading={false}
                hasPagination={false}
              />
            </div>
          )}
          {selectedTab === "displayName" && (
            <DisplayNameLogic
              mandatoryAttributes={
                selectedCategory?.attributes[0]?._id
                  ? selectedCategory?.attributes.map(({ _id, name }) => ({
                      label: name,
                      value: _id,
                    }))
                  : []
              }
              displayNameValues={displayNameValues}
              setDisplayNameValues={setDisplayNameValues}
              attributes={selectedCategory.attributes}
              replacements={replacements}
              setReplacements={setReplacements}
              canUpdate={canUpdate(CATEGORY)}
            />
          )}
          {selectedCategory?.name && (
            <div className="flex pt-40">
              <Button
                text={"Update"}
                onButtonClick={handleUpdateCategory}
                btnClass={"bg-btn"}
                loadingText="Updating..."
                disabled={isLoading || !canUpdate(CATEGORY)}
              />
            </div>
          )}
          <AddValueOptions
            showOptions={showOptions}
            close={() => setShowOptions(false)}
            data={selectedCategory?.attributes[currAttr]?.options}
            currAttr={currAttr}
            updateAttributes={setSelectedCategory}
            isEdit
          />
          <FilterOptions
            showOptions={showFilterOptions}
            close={() => setShowFilterOptions(false)}
            data={selectedCategory?.attributes[currAttr]?.filterOptions}
            currAttr={currAttr}
            updateAttributes={setSelectedCategory}
            isEdit
          />
        </div>
      )}
    </div>
  );
}
