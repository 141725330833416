import Modal from "components/ui/Modal";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function ASNpopup({ show, asn, close }) {
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <Modal
      isOpen={show}
      closeModal={close}
      title={""}
      onSave={close}
      saveBtnTxt={"OK"}
    >
      <div className="flex justify-between items-center">
        <p className="text-16 font-bold">Generated ASN : {asn}</p>
        <CopyToClipboard
          text={asn}
          onCopy={() => {
            setHasCopied(true);
            setTimeout(() => {
              setHasCopied(false);
            }, 1000);
          }}
          className="text-blue-400 mb-12"
        >
          <button>
            {hasCopied ? (
              <span className="text-green-500 no-underline text-16">
                Copied!
              </span>
            ) : (
              <span className="text-16">Copy</span>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </Modal>
  );
}
