/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import { brochureVariantUrls } from "constants/productConstants";

const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadImage = (props: any) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList]: any = useState([]);

  useEffect(() => {
    if (
      (props?.imageUploadFailed?.variant === props.variant &&
        props?.imageUploadFailed?.index === props.row) ||
      props?.uploadSuccess
    ) {
      setFileList([]);
    }
  }, [props?.imageUploadFailed, props?.uploadSuccess]);

  useEffect(() => {
    if (props.images?.[props.row]?.[props.variant]?.url && !fileList.length) {
      setFileList([{ url: props.images?.[props.row]?.[props.variant]?.url }]);
    }
  }, [props.images?.[props.row]?.[props.variant]?.url]);

  useEffect(() => {
    if (
      props.images?.[props.row]?.[props.variant] &&
      brochureVariantUrls.includes(props.variant) &&
      !fileList.length
    ) {
      setFileList([{ url: props.images?.[props.row]?.[props.variant] }]);
    }
  }, [props.images?.[props.row]?.[props.variant]]);

  const handleChange = (file: any) => {
    if (!props?.info?.modelName) {
      return alert("Please enter model name first");
    }
    const { type: extension } = file.file;
    if (!props?.accept?.includes(extension)) {
      return alert(`Allowed extensions - ${props.accept}`);
    }
    setFileList(file.fileList);
    props?.uploadImage(file.file);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <div className="m-20 p-20 rounded">
      <p>{props?.title}</p>
      <Upload
        beforeUpload={() => false}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        accept={props.accept}
        disabled={props.disabled}
        onRemove={() => {
          setFileList([]);
          props.removeImage();
        }}
      >
        {fileList.length >= 1 ? null : (
          <>
            <PlusOutlined />
            <p> Upload</p>
          </>
        )}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default UploadImage;
