import React, { createContext, useState, useContext, ReactNode } from "react";
import { getStorageData, setStorageData } from "utils/helper";

type AuthContextData = {
  isAuthenticated: boolean;
  signIn(token: any): any;
  signOut(): void;
  setIsAuthenticated: any;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const authData = getStorageData("token");
  const [isAuthenticated, setIsAuthenticated] = useState(!!authData);

  const signIn = (token) => {
    setStorageData("token", token);
  };

  const signOut = async () => {
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("userDetails");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
