import { CategoryApiConfig } from "api/configs/categoryConfig";
import Autocomplete from "components/ui/Autocomplete";
import Modal from "components/ui/Modal";
import { useApiQuery } from "hooks/useApi";
import useGenerateASN from "hooks/useGenerateASN";
import { makeList } from "utils/helper";
import ASNpopup from "./ASNpopup";

export default function GenerateASN({ show, close }) {
  const { data: parentCategories = [] } = useApiQuery(
    CategoryApiConfig.GET_ACTIVE_PARENT_CATEGORIES,
    {
      dataParser: makeList,
    }
  );
  const {
    setCategoryId,
    categoryId,
    generatedASN,
    generateAsn,
    searchedCatgory,
    setSearchedCategory,
    showASNPopup,
    setShowASNPopup,
    setGeneratedASN,
  } = useGenerateASN();
  return (
    <>
      <Modal
        isOpen={show}
        closeModal={() => {
          close();
          setSearchedCategory("");
          setCategoryId("");
        }}
        title={"Generate ASN"}
        onSave={generateAsn}
        saveBtnTxt={"Submit"}
        disableSave={!categoryId}
      >
        <div className="min-h-[150px]">
          <p className="text-15 font-medium">Select category:</p>
          <Autocomplete
            selected={categoryId}
            onChange={({ value }) => {
              setCategoryId(value);
              setSearchedCategory("");
            }}
            list={parentCategories}
            placeholder={"Search category"}
            searchedValue={searchedCatgory}
            onSearch={setSearchedCategory}
          />
        </div>
      </Modal>
      <ASNpopup
        show={showASNPopup}
        asn={generatedASN}
        close={() => {
          setShowASNPopup(false);
          setGeneratedASN("");
        }}
      />
    </>
  );
}
