export const AttributeApiConfig: any = {
  GET_ALL_ATTRIBUTES: {
    url: "/attribute",
    enabled: false,
  },
  GET_ATTRIBUTE_BY_ID: {
    url: "/attribute",
    enabled: false,
  },
  GET_ATTRIBUTE_BY_NAME: {
    url: "/attribute/search",
    enabled: false,
  },
  CREATE_ATTRIBUTE: {
    url: "/attribute",
    method: "post",
    successToast: "Added new attribute successfully!",
  },
  GET_ATTRIBUTES_BY_CATEGORY: {
    url: "/attribute/list",
    method: "post",
  },
};
