import { Routes, Route, Navigate } from "react-router-dom";
import Brands from "pages/Brands";
import { Attributes } from "pages/Attributes";
import { Categories } from "pages/Categories";
import NewCategory from "pages/NewCategory";
import Layout from "components/ui/Layout";
import Products from "pages/Products";
import PageNotFound from "components/ui/PageNotFound";
import usePermissions from "hooks/usePermissions";
import {
  ATTRIBUTE,
  BRAND,
  BULK_UPLOAD,
  CATEGORY,
  PRODUCT,
  PRODUCT_APPROVER_PAGE,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import BulkUpload from "pages/BulkUpload";
import EditSubCategory from "pages/EditSubCategory";
import { useApp } from "context/appContext";
import Spinner from "components/ui/Spinner";
import ProductCreator from "pages/ProductCreator";
import ProductApprover from "pages/ProductApprover";
import ProductBulkUpload from "pages/ProductBulkUpload";
import CategorySequencing from "pages/CategorySequencing";
import { IsCategorySequencingAccessable } from "utils/helper";
import ProductVariants from "pages/ProductVariants";

function AppRoutes() {
  const { canAccess, canCreate, canUpdate } = usePermissions();
  const { tenantLoader, permissionsLoader } = useApp();

  return (
    <Layout>
      <Routes>
        <Route index element={<Navigate to="/attributes" replace />} />
        <Route
          path="/attributes"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(ATTRIBUTE) ? (
              <Attributes />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="brands"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(BRAND) ? (
              <Brands />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="categories"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(CATEGORY) ? (
              <Categories />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="categories/new-category"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canCreate(CATEGORY) ? (
              <NewCategory />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="products"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(PRODUCT) ? (
              <Products />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="bulk-update"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(BULK_UPLOAD) ? (
              <BulkUpload />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="categories/change-category"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canUpdate(CATEGORY) ? (
              <EditSubCategory />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="categories/category-sequencing"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : IsCategorySequencingAccessable() ? (
              <CategorySequencing />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/product-creator"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(PRODUCT_CREATOR_PAGE) ? (
              <ProductCreator />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="product-bulk-upload"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canCreate(PRODUCT) || canUpdate(PRODUCT) ? (
              <ProductBulkUpload />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/product-approver"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canAccess(PRODUCT_APPROVER_PAGE) ? (
              <ProductApprover />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route
          path="/variants"
          element={
            tenantLoader || permissionsLoader ? (
              <Spinner />
            ) : canCreate(PRODUCT) ? (
              <ProductVariants />
            ) : (
              <PageNotFound />
            )
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  );
}

export default AppRoutes;
