/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { MASTER_TENANT } from "constants/commonConstants";
import {
  APPROVED_STATUS,
  brochureVariantUrls,
  infoFields,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  buildValidArray,
  createSelectList,
  downloadFile,
  handleError,
  makeProductsSelectionList,
  replaceImageBaseUrl,
} from "utils/helper";
import { useApiQuery, useApiMutate } from "./useApi";
import axios from "axios";
import { message } from "antd";

export default function useEditProducts() {
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("specifications");
  const [searchedAsn, setSearchedAsn] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(
    searchParams.get("id") || ""
  );
  const [showUpdateASN, setShowUpdateASN] = useState(false);
  const [newASN, setNewASN] = useState("");
  const [productData, setProductData]: any = useState({
    categories: [],
    modelName: "",
    images: { imagesData: [], primaryImage: "" },
    tenants: [],
    brand: "",
    video: null,
  });
  const [productSpecs, setProductSpecs] = useState([]);
  const [images, setImages]: any = useState({});
  const [imagesToDelete, setImagesToDelete]: any = useState([]);
  const [brochuresToDelete, setBrochuresToDelete]: any = useState([]);
  const [currentImage, setCurrentImage] = useState({ variant: "", index: -1 });
  const [brochureImages, setBrochureImages]: any = useState({});
  const [noOfImages, setNoOfImages] = useState({ 0: [""] });
  const [noOfBrochures, setNoOfBrochures] = useState({ 0: [""] });
  const [currentModelNameNdTenants, setCurrentModelNameNdTenants] = useState({
    modelName: "",
    tenants: [],
  });
  const { tenant = MASTER_TENANT, isProductApprovalEnabled } = useApp();
  const [searchedProducts, setSearchedProducts] = useState([]);
  const { refetch: getSearchedProducts } = useApiQuery(
    ProductApiConfig.SEARCH_PRODUCTS,
    {
      pathParams: { tenant },
      params: { text: searchedAsn },
      dataParser: (data) => makeProductsSelectionList(data),
      successCb: setSearchedProducts,
      errorCb: () => setSearchedProducts([]),
    }
  );
  const {
    data: product,
    refetch: getProduct,
    isFetching: getProductLoader,
  } = useApiQuery(ProductApiConfig.GET_PRODUCT_BY_ID, {
    pathParams: { productId: selectedProductId, tenant },
    successCb: (data) => {
      setCurrentModelNameNdTenants({
        modelName: data.modelName,
        tenants: data.tenants,
      });
      setProductData({
        ...data,
        highlights: data?.highlights ? data.highlights : "NA",
        active:
          isProductApprovalEnabled &&
          searchParams.get("from") === "approver" &&
          data.status &&
          data.status !== APPROVED_STATUS
            ? true
            : data.active,
      });
      setProductCategory(data?.categories);
      setProductSpecs([{ status: data.status }, ...data.attributes]);
      setDisplayName(data.displayName);
    },
  });
  const { mutate: updateProduct, isLoading: updateLoader } = useApiMutate(
    ProductApiConfig.UPDATE_PRODUCT_BY_ID,
    {
      pathParams: { productId: selectedProductId, tenant },
      successCb: (data) => {
        setImagesToDelete([]);
        setBrochuresToDelete([]);
        setShowUpdateASN(false);
        setNewASN("");
        setUpdateProductSuccess(true);
        getProduct();
        setDisplayName(data.displayName);
        setImages({});
        setNoOfImages({ 0: [""] });
        setBrochureImages({});
        setNoOfBrochures({ 0: [""] });
        setCurrentImage({ variant: "", index: -1 });
        setVideo(null);
        setVideoUploadUrl("");
      },
      errorCb: handleError,
    }
  );
  const [attributesMetaData, setAttributesMetaData] = useState([]);
  const [imageExtensions, setImageExtensions]: any = useState({});
  const [categoryBrandsList, setCategoryBrands] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [productCategory, setProductCategory] = useState([]);
  const [categoryTenantsList, setCategoryTenants] = useState([]);
  const [imageUploadFailed, setImageUploadFailed]: any = useState();
  const [updateProductSuccess, setUpdateProductSuccess] = useState(false);

  const navigate = useNavigate();

  const parseCategoryData = (data) => {
    const mandatoryAttributes = [];
    const restAttributes = [];
    data?.attributes?.forEach((attribute) => {
      if (attribute?.isMandatory) mandatoryAttributes.push(attribute);
      else restAttributes.push(attribute);
    });
    data.attributes = [...mandatoryAttributes, ...restAttributes];
    return data;
  };
  const { refetch: getCat } = useApiQuery(
    CategoryApiConfig.GET_CATEGORY_BY_ID,
    {
      pathParams: {
        id: productCategory?.[0]?.id,
        tenant,
      },
      dataParser: parseCategoryData,
      successCb: (data) => {
        setAttributesMetaData(data?.attributes);
        setCategoryBrands(createSelectList(data?.brands, "name", "_id"));
        setCategoryTenants(createSelectList(data?.tenants));
      },
    }
  );

  const { mutate: approve, isLoading: approving } = useApiMutate(
    ProductApiConfig.APPROVE_PRODUCT,
    {
      pathParams: { productId: productData._id },
      successCb: () => {
        navigate("/product-approver");
      },
      errorCb: handleError,
    }
  );

  const { mutate: decline, isLoading: declining } = useApiMutate(
    ProductApiConfig.DECLINE_PRODUCT,
    {
      pathParams: { productId: productData._id },
      successCb: () => {
        navigate("/product-approver");
      },
      errorCb: handleError,
    }
  );
  const { mutate: submitForApproval, isLoading: submitting } = useApiMutate(
    ProductApiConfig.SUBMIT_PRODUCT,
    {
      pathParams: { productId: productData._id },
      successCb: () => {
        navigate("/product-creator");
      },
      errorCb: handleError,
    }
  );

  const { refetch: downloadImages, isFetching: downloadImagesLoader } =
    useApiQuery(ProductApiConfig.DOWNLOAD_IMAGES, {
      pathParams: { tenant, productId: productData._id },
      successCb: (data) => {
        downloadFile(data, `${productData.asn}_Images.zip`);
      },
      errorCb: handleError,
    });

  useEffect(() => {
    if (selectedProductId) getProduct();
  }, []);
  useEffect(() => {
    if (searchedAsn.length > 2) getSearchedProducts();
  }, [searchedAsn]);

  useEffect(() => {
    if (productCategory?.length) getCat();
  }, [productCategory]);

  useEffect(() => {
    if (imageExtensions.ext) {
      setTimeout(() => {
        getImageUploadUrl({
          modelName: productData.modelName?.trim(),
          imageExtension: [imageExtensions.ext],
          type: imageExtensions.type ?? "OLDPRODUCT",
          position: imageExtensions.position,
          imageVariant: imageExtensions.imageVariant,
        });
      }, 1000);
    }
  }, [imageExtensions]);

  const uploadImage = async (data) => {
    setImages({
      ...images,
      [currentImage.index]: {
        ...images[currentImage.index],
        [currentImage.variant]: {
          url: data?.[0]?.substring(
            0,
            data?.[0]?.indexOf("jpg") > -1
              ? data?.[0]?.indexOf("jpg") + 3
              : data?.[0]?.indexOf("jpeg") + 4
          ),
          ...images[currentImage.index][currentImage.variant],
        },
      },
    });
    await fetch(data?.[0], {
      method: "PUT",
      body: images?.[currentImage?.index]?.[currentImage.variant],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const [video, setVideo] = useState(null);
  const [videoUploadUrl, setVideoUploadUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const { mutate: getVideoUploadUrl } = useApiMutate(
    ProductApiConfig.GET_VIDEO_UPLOAD_URL,
    {
      successCb: setVideoUploadUrl,
      errorCb: handleError,
    }
  );

  useEffect(() => {
    if (video) getVideoUploadUrl({ asn: productData?.asn, sequence: 1 });
  }, [video]);

  const uploadVideo = async () => {
    const { status, data } = await axios.put(videoUploadUrl, video, {
      onUploadProgress: (progressEvent) => {
        setUploadProgress(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      },
    });
    setUploadProgress(0);
    if (status === 200) {
      setVideo(null);
      message.success("Uploaded video successfully!");
      const video = videoUploadUrl?.substring(
        0,
        videoUploadUrl?.indexOf(".mp4") + 4
      );
      setProductData((prev) => ({ ...prev, video }));
    } else {
      message.error(data ?? "Error while uploading video!");
    }
  };

  const uploadBrochure = async (data) => {
    setBrochureImages({
      ...brochureImages,
      [currentImage.index]: {
        ...brochureImages[currentImage.index],
        [currentImage.variant]: data?.[0]?.substring(
          0,
          data?.[0]?.indexOf("jpg") > -1
            ? data?.[0]?.indexOf("jpg") + 3
            : data?.[0]?.indexOf("jpeg") + 4
        ),
      },
    });
    await fetch(data?.[0], {
      method: "PUT",
      body: brochureImages?.[currentImage?.index]?.[currentImage.variant],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const { mutate: getImageUploadUrl } = useApiMutate(
    ProductApiConfig.GET_IMAGE_UPLOAD_URL,
    {
      successCb: (data) => {
        setImageUploadFailed(null);
        setImageExtensions({});
        if (brochureVariantUrls.includes(currentImage.variant))
          uploadBrochure(data);
        else uploadImage(data);
      },
      errorCb: (msg) => {
        setImageUploadFailed({ ...currentImage });
        setImageExtensions({});
        handleError(msg);
      },
    }
  );

  const { mutate: generateDisplayName, isLoading: displayNameLoader } =
    useApiMutate(ProductApiConfig.GENERATE_DISPLAY_NAME, {
      successCb: (data) => setDisplayName(data),
      errorCb: handleError,
    });

  const brochureValidation = async (file, variant) => {
    let failedSize = "",
      failedDimensions = "";
    if (file?.size > 5000000) {
      failedSize += file?.name + ", ";
    }
    let [width, height]: any = await getWidthHeight(
      window.URL.createObjectURL(file)
    );
    const brochureAspectRatio = 1.61;
    if (Math.floor((width / height) * 100) / 100 !== brochureAspectRatio) {
      failedDimensions += file.name + ", ";
    }
    if (failedSize && failedDimensions)
      return `Brochure size must not exceed 5mb\n\n${`${variant} brochure aspect ratio should be 1.61\n`}`;
    else if (failedSize) return `Brochure size must not exceed 5mb\n`;
    else if (failedDimensions)
      return `${variant} brochure aspect ratio should be 1.61 \n`;
    return false;
  };

  async function handleBrochures({ name, variant }, position, file) {
    setImageUploadFailed(null);
    setUpdateProductSuccess(false);
    const failedImages = await brochureValidation(file, variant);
    if (failedImages) {
      setImageUploadFailed({ variant: name, index: position });
      return alert(failedImages);
    }
    setCurrentImage({ variant: name, index: position });
    setBrochureImages((curr) => {
      if (curr[position]) {
        curr[position][name] = file;
      } else {
        curr[position] = { [name]: file };
      }
      return curr;
    });
    let ext = file?.type?.slice(file?.type?.indexOf("/") + 1);
    if (ext === "jpeg") ext = "jpg";
    const imageVariant = variant;
    setImageExtensions({ ext, position, imageVariant, type: "BROCHURE" });
  }

  const handleImages = async (idx, name, file, position) => {
    setImageUploadFailed(null);
    setUpdateProductSuccess(false);
    if (!productData?.modelName) {
      return alert("Please enter model name first");
    }
    const correctImages = await imageValidation(file);
    if (correctImages) {
      setImageUploadFailed({ variant: name, index: idx });
      return alert(correctImages);
    }
    setCurrentImage({ variant: name, index: idx });
    setImages({
      ...images,
      [idx]: { [name]: file, ...images[idx] },
    });
    let ext = file?.type?.slice(file?.type?.indexOf("/") + 1);
    if (ext === "jpeg") ext = "jpg";
    const imageVariant = name === "sv" ? "S" : name === "mv" ? "M" : "L";
    position += productData?.images?.imagesData?.length ?? 0;
    setImageExtensions({ ext, position, imageVariant });
  };
  const getWidthHeight = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.width, img.height]);
      img.onerror = reject;
      img.src = src;
    });
  };
  const imageValidation = async (file) => {
    if (file) {
      let failedSize = "",
        failedDimensions = "";
      if (file?.size > 80000) {
        failedSize += file?.name + ", ";
      }
      let [width, height]: any = await getWidthHeight(
        window.URL.createObjectURL(file)
      );
      if (width > 400 || height > 400) {
        failedDimensions += file.name + ", ";
      }

      if (failedSize && failedDimensions)
        return `Image size should not exceed 80kb\n\nImage dimensions should not exceed 400 X 400`;
      else if (failedSize) return `Image size should not exceed 80kb\n`;
      else if (failedDimensions)
        return `Image dimensions should not exceed 400 X 400\n`;
      return false;
    }
    return true;
  };

  const buildPayload = () => {
    let imagesArr = [];
    let minOrder = 1000;
    let primaryImage = "";
    const imagesData = productData?.images?.imagesData?.filter(
      (_, idx) => !imagesToDelete.includes(idx)
    );
    imagesData?.forEach((img) => {
      imagesArr.push(img);
      if (img.order < minOrder) {
        minOrder = img.order;
        primaryImage = img.url.mediumUrl;
      }
    });

    Object.values(images).forEach((img: any) => {
      if (Number(img.order) < minOrder) {
        minOrder = Number(img.order);
        primaryImage = img?.["mv"]?.url;
      }
      let smallUrl = img?.["sv"] && img?.["sv"]?.url ? img["sv"].url : null;
      let mediumUrl = img?.["mv"] && img?.["mv"]?.url ? img["mv"]?.url : null;
      let largeUrl = img?.["lv"] && img?.["lv"]?.url ? img["lv"].url : null;
      if (process.env.REACT_APP_ENV === "production") {
        primaryImage = replaceImageBaseUrl(primaryImage);
        smallUrl = replaceImageBaseUrl(smallUrl);
        mediumUrl = replaceImageBaseUrl(mediumUrl);
        largeUrl = replaceImageBaseUrl(largeUrl);
      }
      const url = {
        smallUrl,
        mediumUrl,
        largeUrl,
      };
      imagesArr.push({
        order: img.order,
        url,
        tenant: buildValidArray(productData.tenants),
      });
    });
    const brochureImagesAfterDeleting = productData?.brochureImages?.filter(
      (_, idx) => !brochuresToDelete.includes(idx)
    );

    return {
      ...productData,
      tenants: buildValidArray(productData.tenants),
      displayName,
      attributes: getAttributesPayload(),
      images: { imagesData: imagesArr, primaryImage },
      brochureImages: [
        ...brochureImagesAfterDeleting,
        ...Object.values(brochureImages),
      ],
      productTag: productData.productTag ? productData.productTag.trim() : null,
    };
  };
  const hasMandatoryAttributes = () => {
    for (const attributeMeta of attributesMetaData) {
      const attribute = productSpecs.find(
        (attribute) => attribute?.k === attributeMeta?._id
      );
      if (
        attributeMeta?.isMandatory &&
        (!attribute ||
          !attribute["v"] ||
          (attributeMeta?.options.length
            ? ["integer", "string"].includes(attributeMeta?.valueType) &&
              !attribute["u"]
            : false))
      ) {
        return false;
      }
    }
    return true;
  };

  const hasUnitValues = () => {
    for (const attributeMeta of attributesMetaData) {
      const attribute = productSpecs.find(
        (attribute) => attribute?.k === attributeMeta?._id
      );
      if (
        attribute &&
        attribute["v"] &&
        attributeMeta?.options.length &&
        ["integer", "string"].includes(attributeMeta?.valueType) &&
        !attribute["u"]
      ) {
        return false;
      }
    }
    return true;
  };
  const hasMandatoryInfo = () => {
    for (const field of infoFields) {
      if (field?.name === "tenants") continue;
      if (
        field?.isMandatory &&
        (typeof productData?.[field?.name] === "number"
          ? productData?.[field?.name] === null ||
            productData?.[field?.name] < 0
          : field.name === "tenants"
          ? !productData?.[field?.name]?.length
          : !productData?.[field?.name])
      ) {
        return false;
      }
    }
    return true;
  };
  const getAttributesPayload = () => {
    const attributes = [];
    productSpecs.forEach((attribute) => {
      if (
        attributesMetaData.find(({ _id }) => _id === attribute.k) &&
        attribute?.v?.trim()
      ) {
        attributes.push({
          ...attribute,
          v: attribute?.v?.trim(),
          filterOption: attribute?.filterOption ?? null,
        });
      }
    });
    return attributes;
  };
  const canGenerateDisplayName = () =>
    productData.modelName?.trim() &&
    productData.brand &&
    hasMandatoryAttributes();

  const generateDisplay = () => {
    if (!canGenerateDisplayName()) return alert("Please fill mandatory fields");
    if (!hasUnitValues)
      return alert("Please provide units for attributes that has values");

    generateDisplayName({
      modelName: productData.modelName?.trim(),
      attributes: getAttributesPayload(),
      categories: productData.categories.map(({ id }) => id),
      brand: productData.brand.id,
    });
  };

  function productImagesValidation() {
    const imgs = Object.values(images);
    if (!productData.images && !imgs.length) {
      alert("Please upload the images");
      return true;
    }
    for (const img of imgs) {
      if (
        !productData.images || !productData.images?.imagesData?.length
          ? !img["mv"] || !img["order"]
          : img["mv"] && (!img["mv"]?.url || !img["order"])
      ) {
        alert("Medium image & sequence are mandatory");
        return true;
      }
    }
    return false;
  }

  function updateProductDataValidation() {
    if (!hasMandatoryInfo()) {
      alert("Please fill mandatory product info");
      return true;
    }
    if (
      (productData?.["moq"] &&
        productData?.["moq"] % productData?.["packQuantity"] !== 0) ||
      (productData?.["maxQuantity"] &&
        productData?.["maxQuantity"] % productData?.["packQuantity"] !== 0)
    ) {
      alert("Min & max qty should be in multiples of pack qty");
      return true;
    }
    if (!hasMandatoryAttributes()) {
      alert("Please fill mandatory product attributes");
      return true;
    }
    if (!hasUnitValues()) {
      alert("Please provide units for attributes that has values");
      return true;
    }
    if (tenant !== MASTER_TENANT && productImagesValidation()) return true;

    if (isSequenceUnique()) {
      alert("Duplicate image sequence");
      return true;
    }
    if (tenant === MASTER_TENANT && !displayName) {
      alert("Please generate display name");
      return true;
    }
    if (uploadProgress > 0) {
      alert("Video upload in progress");
      return true;
    }
  }

  function isModelNameEdited() {
    return productData.modelName !== currentModelNameNdTenants.modelName;
  }

  function isGostorToArzooo() {
    return (
      currentModelNameNdTenants.tenants?.length === 1 &&
      currentModelNameNdTenants.tenants?.[0] === "GOSTOR" &&
      productData.tenants.includes("ARZOOO")
    );
  }

  const updateProductDetails = () => {
    const dataValidationFailed = updateProductDataValidation();
    if (dataValidationFailed) return;
    let payload = buildPayload();
    if (isModelNameEdited() || isGostorToArzooo()) {
      if (
        window.confirm("Click OK only if you've downloaded the product images!")
      ) {
        payload = { ...payload, images: null };
      } else return;
    }
    updateProduct(payload);
  };

  function approveProduct() {
    const dataValidationFailed = updateProductDataValidation();
    if (dataValidationFailed) return;
    let payload = buildPayload();
    if (isModelNameEdited()) {
      if (
        window.confirm("Click OK only if you've downloaded the product images!")
      ) {
        payload = { ...payload, images: null };
      } else return;
    }
    approve(payload);
  }

  function declineProduct() {
    const dataValidationFailed = updateProductDataValidation();
    if (dataValidationFailed) return;
    let payload = buildPayload();
    if (isModelNameEdited()) {
      if (
        window.confirm("Click OK only if you've downloaded the product images!")
      ) {
        payload = { ...payload, images: null };
      } else return;
    }
    decline({ ...payload, active: false });
  }

  function submitProductForApproval() {
    const dataValidationFailed = updateProductDataValidation();
    if (dataValidationFailed) return;
    let payload = buildPayload();
    if (isModelNameEdited()) {
      if (
        window.confirm("Click OK only if you've downloaded the product images!")
      ) {
        payload = { ...payload, images: null };
      } else return;
    }
    submitForApproval({ ...payload, active: false });
  }

  const addImage = (i) => {
    setNoOfImages((prev) => ({ ...prev, [i]: [""] }));
  };

  const removeImage = (i) => {
    setImages((prev) => {
      const imgs = { ...prev };
      if (imgs[i] && Object.keys(imgs[i])?.length) delete imgs[i];
      return { ...imgs };
    });
    setNoOfImages((prev) => {
      const noOfImgs = { ...prev };
      delete noOfImgs[i];
      return { ...noOfImgs };
    });
  };

  const addBrochure = (i) => {
    setNoOfBrochures((prev) => ({ ...prev, [i]: [""] }));
  };

  const removeBrochure = (i) => {
    setBrochureImages((prev) => {
      const imgs = { ...prev };
      if (imgs[i] && Object.keys(imgs[i])?.length) delete imgs[i];
      return { ...imgs };
    });
    setNoOfBrochures((prev) => {
      const noOfImgs = { ...prev };
      delete noOfImgs[i];
      return { ...noOfImgs };
    });
  };

  const handleImageSequence = (idx, order) => {
    const t = { ...images };
    t[idx] = { ...t[idx], order };
    setImages(t);
  };

  const isSequenceUnique = () => {
    const sequenceMap = {};
    let imgs: any = Object.values(images);
    const imagesAfterDeleting = productData?.images?.imagesData?.filter(
      (_, idx) => !imagesToDelete.includes(idx)
    );
    imgs = [...imgs, ...(imagesAfterDeleting ?? [])];
    for (const { order } of imgs) {
      if (sequenceMap[order]) {
        return true;
      } else {
        sequenceMap[order] = true;
      }
    }
    return false;
  };

  function removeImageVariant(index, variant) {
    setImages((curr) => {
      const t = { ...curr };
      t[index][variant] = null;
      return t;
    });
  }

  function removeBrochureVariant(index, variant) {
    setBrochureImages((curr) => {
      const t = { ...curr };
      t[index][variant] = null;
      return t;
    });
  }

  function updateASN() {
    const payload = { ...productData, asn: newASN?.trim() };
    updateProduct(payload);
  }

  const handleVideoRemove = () => {
    setVideo(null);
    setProductData({ ...productData, video: null });
  };

  return {
    selectedTab,
    setSelectedTab,
    searchedAsn,
    setSearchedAsn,
    product,
    getProduct,
    updateProduct,
    searchedProducts,
    getSearchedProducts,
    selectedProduct,
    setSelectedProduct,
    setSelectedProductId,
    productData,
    setProductData,
    attributesMetaData,
    updateProductDetails,
    productSpecs,
    setProductSpecs,
    updateLoader,
    images,
    setImages,
    setImagesToDelete,
    imagesToDelete,
    handleImages,
    addImage,
    removeImage,
    noOfImages,
    handleImageSequence,
    categoryBrandsList,
    getProductLoader,
    generateDisplay,
    displayName,
    displayNameLoader,
    setDisplayName,
    categoryTenantsList,
    approve,
    decline,
    approving,
    declining,
    submitting,
    noOfBrochures,
    handleBrochures,
    brochureImages,
    addBrochure,
    removeBrochure,
    brochuresToDelete,
    setBrochuresToDelete,
    removeBrochureVariant,
    removeImageVariant,
    imageUploadFailed,
    updateProductSuccess,
    submitProductForApproval,
    approveProduct,
    declineProduct,
    downloadImages,
    showUpdateASN,
    setShowUpdateASN,
    newASN,
    setNewASN,
    updateASN,
    downloadImagesLoader,
    setVideo,
    video,
    handleVideoRemove,
    uploadVideo,
    uploadProgress,
  };
}
