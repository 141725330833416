import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactElement } from "react";
import Button from "./Button";

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  onSave: () => void;
  saveBtnTxt: string;
  children: ReactElement;
  titleClass?: string;
  disableSave?: boolean;
};

export default function Modal({
  isOpen,
  closeModal,
  title,
  onSave,
  saveBtnTxt,
  children,
  titleClass,
  disableSave,
}: ModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-mainBg text-left align-middle shadow-xl transition-all">
                {title && (
                  <Dialog.Title
                    as="h3"
                    className={classNames(
                      "text-lg font-medium leading-6 text-white bg-btn p-8",
                      titleClass
                    )}
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="p-20 max-h-300 overflow-y-scroll">
                  {children}
                </div>
                <div className="flex justify-end p-20 gap-x-20">
                  <Button
                    text="Close"
                    onButtonClick={closeModal}
                    btnClass="bg-[grey] hover:bg-opacity-80 px-[15px] py-[5px]"
                  />
                  <Button
                    text={saveBtnTxt}
                    onButtonClick={onSave}
                    btnClass="bg-btn hover:bg-opacity-80 px-[15px] py-[5px]"
                    disabled={disableSave}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
