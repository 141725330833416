import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Input from "components/ui/Input";
import Modal from "components/ui/Modal";

export default function AddValueOptions({
  showOptions,
  close,
  data,
  currAttr,
  updateAttributes,
  isEdit = false,
}) {
  const addVOption = (attrIdx) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (attrIdx === i) return { ...obj, options: [...obj.options, ""] };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (attrIdx === i) return { ...obj, options: [...obj.options, ""] };
          return obj;
        })
      );
  };

  const remVOption = (attrIdx, remIdx) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              options: obj.options.filter((_, i) => i !== remIdx),
            };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (attrIdx === i)
            return {
              ...obj,
              options: obj.options.filter((_, i) => i !== remIdx),
            };
          return obj;
        })
      );
  };

  const handleOptions = (ca, oi, v) => {
    if (isEdit)
      updateAttributes((current) => {
        const attributes = current.attributes.map((obj, i) => {
          if (ca === i)
            return {
              ...obj,
              options: obj.options.map((option, i) => (oi === i ? v : option)),
            };
          return obj;
        });
        return { ...current, attributes };
      });
    else
      updateAttributes((current) =>
        current.map((obj, i) => {
          if (ca === i)
            return {
              ...obj,
              options: obj.options.map((option, i) => (oi === i ? v : option)),
            };
          return obj;
        })
      );
  };
  return (
    <Modal
      isOpen={showOptions}
      closeModal={close}
      title={"Add options"}
      onSave={close}
      saveBtnTxt={"Save"}
    >
      {data?.map((option, i) => {
        return (
          <div key={i} className="flex justify-between items-center p-15">
            <Input
              inputType={"text"}
              value={option}
              name={`option${i}`}
              onChange={({ target: { value } }) => {
                handleOptions(currAttr, i, value);
              }}
              label={`Option${i + 1}`}
            />

            {i === data.length - 1 && (
              <PlusCircleIcon
                className="w-20 h-20 cursor-pointer"
                fill="green"
                onClick={() => addVOption(currAttr)}
              />
            )}
            {data.length > 1 && (
              <XCircleIcon
                className="w-20 h-20 cursor-pointer"
                fill="red"
                onClick={() => remVOption(currAttr, i)}
              />
            )}
          </div>
        );
      })}
    </Modal>
  );
}
