import { ProductApiConfig } from "api/configs/productApiConfig";
import { useState } from "react";
import { useApiQuery } from "./useApi";

export default function useGenerateASN() {
  const [showGenerateASNPopup, setShowGenerateASNPopup] = useState(false);
  const [showASNPopup, setShowASNPopup] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [searchedCatgory, setSearchedCategory] = useState("");
  const [generatedASN, setGeneratedASN] = useState("");
  const { refetch: generateAsn } = useApiQuery(ProductApiConfig.GENERATE_ASN, {
    params: { categoryId },
    successCb: (data) => {
      setGeneratedASN(data);
      setShowGenerateASNPopup(false);
      setShowASNPopup(true);
    },
  });

  return {
    showGenerateASNPopup,
    setShowGenerateASNPopup,
    setCategoryId,
    categoryId,
    generatedASN,
    generateAsn,
    searchedCatgory,
    setSearchedCategory,
    showASNPopup,
    setShowASNPopup,
    setGeneratedASN,
  };
}
