import {
  BULK_UPLOAD,
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_APPROVER_PAGE,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import { useApp } from "context/appContext";

export default function usePermissions() {
  const { permissions, tenant, isProductApprovalEnabled } = useApp();
  const canCreate = (page) =>
    (page === BULK_UPLOAD ? true : tenant === MASTER_TENANT) &&
    !!permissions?.[page]?.CREATE;
  const canRead = (page) => !!permissions?.[page]?.READ;
  const canUpdate = (page) =>
    (page === PRODUCT ? true : tenant === MASTER_TENANT) &&
    !!permissions?.[page]?.UPDATE;
  const canDelete = (page) =>
    tenant === MASTER_TENANT && !!permissions?.[page]?.DELETE;
  const canAccess = (page) =>
    ([PRODUCT_APPROVER_PAGE, PRODUCT_CREATOR_PAGE].includes(page)
      ? tenant === MASTER_TENANT && isProductApprovalEnabled
      : true) &&
    (canCreate(page) || canRead(page) || canUpdate(page));

  return {
    canRead,
    canUpdate,
    canCreate,
    canDelete,
    canAccess,
  };
}
