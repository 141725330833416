import Input from "components/ui/Input";
import SelectList from "components/ui/SelectList";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import usePermissions from "hooks/usePermissions";
import { useSearchParams } from "react-router-dom";
import { createSelectList } from "utils/helper";

export default function ProductSpecifications({
  metaData,
  data,
  setData,
  isEdit = false,
  setDisplayName,
}) {
  const { canRead, canUpdate, canAccess } = usePermissions();
  const { tenant, isProductApprovalEnabled } = useApp();
  const cannotEdit = canRead(PRODUCT) && !canUpdate(PRODUCT);
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from");

  const disableFields = () => {
    const productStatus = data.find((item) => item.status)?.status;
    return (
      isEdit &&
      (isProductApprovalEnabled &&
      productStatus &&
      productStatus !== APPROVED_STATUS
        ? (productStatus === PENDING_STATUS ||
            (productStatus === DRAFT_STATUS &&
              !canAccess(PRODUCT_CREATOR_PAGE))) &&
          fromPage !== "approver"
        : cannotEdit || tenant !== MASTER_TENANT)
    );
  };

  const elements = {
    integer: ({ isMandatory, _id, name, options, filterOptions }) => (
      <div className="flex justify-items-start">
        <Input
          inputType={"number"}
          value={data?.find((item) => item?.k === _id)?.v ?? ""}
          name={name}
          onChange={({ target: { value: v } }) => {
            setDisplayName("");
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], v, k: _id };
              } else {
                prev.push({ v, k: _id });
              }
              return [...prev];
            });
          }}
          label={name}
          mandatory={isMandatory}
          hasDropdown={!!options?.length}
          list={options?.length ? createSelectList(options) : []}
          onDropdrownChange={(u) => {
            setDisplayName("");
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], u, k: _id };
              } else {
                prev.push({ u, k: _id });
              }
              return [...prev];
            });
          }}
          selected={data.find((item) => item?.k === _id)?.u}
          disabled={disableFields()}
          hasFilterDropdown={!!filterOptions?.length}
          filterOptionList={
            filterOptions?.length ? createSelectList(filterOptions) : []
          }
          onFilterChange={(filterOption) => {
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], filterOption, k: _id };
              } else {
                prev.push({ filterOption, k: _id });
              }
              return [...prev];
            });
          }}
          selectedFilter={
            data?.find((item) => item?.k === _id)?.filterOption ?? ""
          }
          filterDisabled={disableFields()}
        />
      </div>
    ),
    string: ({ isMandatory, _id, name, options, filterOptions }) => (
      <div className="flex">
        <Input
          inputType={"text"}
          value={data?.find((item) => item?.k === _id)?.v ?? ""}
          name={name}
          onChange={({ target: { value: v } }) => {
            setDisplayName("");
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], v, k: _id };
              } else {
                prev.push({ v, k: _id });
              }
              return [...prev];
            });
          }}
          label={name}
          mandatory={isMandatory}
          hasDropdown={!!options?.length}
          list={options?.length ? createSelectList(options) : []}
          onDropdrownChange={(u) => {
            setDisplayName("");
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], u, k: _id };
              } else {
                prev.push({ u, k: _id });
              }
              return [...prev];
            });
          }}
          selected={data.find((item) => item?.k === _id)?.u}
          disabled={disableFields()}
          hasFilterDropdown={!!filterOptions?.length}
          filterOptionList={
            filterOptions?.length ? createSelectList(filterOptions) : []
          }
          onFilterChange={(filterOption) => {
            setData((prev) => {
              let objIdx = prev.findIndex((item) => item?.k === _id);
              if (objIdx > -1) {
                prev[objIdx] = { ...prev[objIdx], filterOption, k: _id };
              } else {
                prev.push({ filterOption, k: _id });
              }
              return [...prev];
            });
          }}
          selectedFilter={
            data?.find((item) => item?.k === _id)?.filterOption ?? ""
          }
          filterDisabled={disableFields()}
        />
      </div>
    ),
    dropdown: ({ isMandatory, options, _id, name, filterOptions }) => (
      <div className="flex w-[90%] justify-start items-center text-textColor">
        <label className="w-[20%] text-right pr-20 font-bold">
          {isMandatory ? <span className="text-red pr-5">*</span> : null}
          {name}
        </label>
        <>
          <SelectList
            list={options?.length ? createSelectList(options) : []}
            onChange={(v) => {
              setDisplayName("");
              setData((prev) => {
                let objIdx = prev.findIndex((item) => item?.k === _id);
                if (objIdx > -1) {
                  prev[objIdx] = { ...prev[objIdx], v, k: _id };
                } else {
                  prev.push({ v, k: _id });
                }
                return [...prev];
              });
            }}
            selected={data.find((item) => item?.k === _id)?.v}
            placeholder="Select"
            mandatory={isMandatory}
            disabled={disableFields()}
          />
          {!!filterOptions?.length && (
            <SelectList
              list={
                filterOptions?.length ? createSelectList(filterOptions) : []
              }
              onChange={(filterOption) => {
                setData((prev) => {
                  let objIdx = prev.findIndex((item) => item?.k === _id);
                  if (objIdx > -1) {
                    prev[objIdx] = { ...prev[objIdx], filterOption, k: _id };
                  } else {
                    prev.push({ filterOption, k: _id });
                  }
                  return [...prev];
                });
              }}
              selected={
                data?.find((item) => item?.k === _id)?.filterOption ?? ""
              }
              placeholder="Filter"
              labelType="dropdown"
              disabled={disableFields()}
              labelClassName="flex"
            />
          )}
        </>
      </div>
    ),
  };

  return (
    <div className="bg-white p-30">
      <div className="grid grid-cols-1 gap-y-20">
        {metaData?.map(
          (
            { valueType, isMandatory, options, _id, name, filterOptions },
            i
          ) => {
            return (
              <div key={i}>
                {elements[valueType]({
                  valueType,
                  isMandatory,
                  options,
                  _id,
                  i,
                  name,
                  filterOptions,
                })}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}
