export const productTabs = [
  { label: "Add", value: "add" },
  { label: "Edit", value: "edit" },
];
export const editProductTabs = [
  { label: "Product Info", value: "specifications" },
  { label: "Media", value: "media" },
];
export const productCats = [
  { name: "parentCat", placeholder: "Category" },
  { name: "childCatL1", placeholder: "Category-Level 1" },
  { name: "childCatL2", placeholder: "Category-Level 2" },
];
export const pictureVariants = [
  {
    label: "Small variant",
    name: "sv",
    accept: "image/jpg,image/jpeg,image/png",
  },
  {
    label: "Medium variant",
    name: "mv",
    isMandatory: true,
    accept: "image/jpg,image/jpeg",
  },
  {
    label: "Large variant",
    name: "lv",
    accept: "image/jpg,image/jpeg,image/png",
  },
];
export const brochureVariants = [
  {
    label: "Web",
    name: "webImageUrl",
    variant: "WEB",
    accept: "image/jpg,image/jpeg,image/png",
  },
  {
    label: "Mobile",
    name: "mobileImageUrl",
    variant: "MOBILE",
    accept: "image/jpg,image/jpeg,image/png",
  },
];

export const brochureVariantUrls = ["webImageUrl", "mobileImageUrl"];

export const infoFields = [
  { label: "Model", name: "modelName", type: "input", isMandatory: true },
  {
    label: "Brand",
    name: "brand",
    type: "autocomplete",
    isMandatory: true,
  },
  {
    label: "Description",
    name: "description",
    type: "textArea",
    isMandatory: true,
  },

  { label: "Priority", name: "priority", type: "input", valueType: "number" },
  {
    label: "Min Payment",
    name: "minPayment",
    type: "input",
    valueType: "number",
    isMandatory: true,
  },
  {
    label: "Shipping Cost",
    name: "shippingCost",
    type: "input",
    valueType: "number",
  },
  {
    label: "Popularity Ranking",
    name: "popularityRanking",
    type: "input",
    valueType: "number",
  },
  {
    label: "Highlights",
    name: "highlights",
    type: "input",
    valueType: "text",
    isMandatory: true,
  },
  { label: "Free Shipping", name: "freeShip", type: "checkbox" },
  {
    label: "App Active",
    name: "active",
    type: "checkbox",
  },
  {
    label: "Dashboard Active",
    name: "dashboardActive",
    type: "checkbox",
  },
  { label: "Has Capacity", name: "hasCapacity", type: "checkbox" },
  {
    label: "Minimum order qty",
    name: "moq",
    type: "input",
    valueType: "number",
    isMandatory: true,
  },
  {
    label: "Max Quantity",
    name: "maxQuantity",
    type: "input",
    valueType: "number",
  },
  {
    label: "Pack Quantity",
    name: "packQuantity",
    type: "input",
    valueType: "number",
    isMandatory: true,
  },
  { label: "Pay On Delivery", name: "payOnDelivery", type: "checkbox" },
  {
    label: "Tags",
    name: "tenants",
    type: "selectList",
    isMandatory: true,
  },
  {
    label: "MRP",
    name: "mrp",
    type: "input",
    valueType: "number",
    isMandatory: true,
  },
  {
    label: "Flipkart Model Name",
    name: "flipkartModelName",
    type: "input",
  },
  {
    label: "Flipkart Url",
    name: "flipkartUrl",
    type: "input",
  },
  {
    label: "Generic Name",
    name: "genericName",
    type: "input",
  },
  {
    label: "Country of Origin",
    name: "countryOfOrigin",
    type: "input",
  },
  {
    label: "Manufacturer's Details",
    name: "manufacturerDetails",
    type: "textArea",
  },
  {
    label: "Importer's Details",
    name: "importerDetails",
    type: "textArea",
  },
  {
    label: "Packer's Details",
    name: "packerDetails",
    type: "textArea",
  },
  {
    label: "Product Tag",
    name: "productTag",
    type: "selectList",
  },
  { name: "360_video_url", label: "3D Video Url", type: "input" },
];

export const DRAFT_STATUS = "DRAFT";
export const PENDING_STATUS = "PENDING";
export const APPROVED_STATUS = "APPROVED";

export const creatorTabs = [
  { label: "Draft", value: "DRAFT" },
  { label: "Pending", value: "PENDING" },
];

export const approverTabs = [
  { label: "Draft", value: "DRAFT" },
  { label: "Pending", value: "PENDING" },
  { label: "Approved", value: "APPROVED" },
];

export const productTags = [
  "Bestseller",
  "Most Popular",
  "Customer's Choice",
  "New Launch",
  "Trending",
  "Featured",
  "Price Drop",
];
