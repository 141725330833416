/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTable, useRowSelect, usePagination, useSortBy } from "react-table";

export type Column = {
  Header: string | (() => void) | React.Component | React.ReactNode;
  accessor?: string | ((originalRow: any, rowIndex: number) => any);
  id?: string;
};

export type TableColumns = Column & {
  id?: string;
  columns?: Array<Column>;
  Cell?: ((data: any) => void) | React.Component;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  filter?: any;
  Filter?: any;
};

type TableProps = {
  columns: Array<TableColumns>;
  data: any;
  loading: boolean;
  hasRowSelection?: boolean;
  setSelectedRows?: (rows: Array<Object>) => void;
  manualPagination?: boolean;
  pageIndex?: number;
  pageSize?: number;
  pageCount?: number;
  onPageChange?: any;
  hasPagination: boolean;
  onCellClick?: (value: any) => void;
};

const Table = ({
  columns,
  data,
  pageIndex = 0,
  pageSize = 10,
  loading = false,
  manualPagination = true, // If true, server-side pagination, else client-side
  onCellClick,
}: TableProps) => {
  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    toggleAllRowsSelected(false);
  }, [loading]);

  return (
    <div>
      <div className="overflow-x-auto rounded min-h-[25vh]">
        <table
          className="min-w-max table-auto bg-white border"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="bg-secondary bg-opacity-5 text-14 font-medium h-40"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="px-18 text-left items-center"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="flex items-center">
                      {column.render("Header")}
                      <div className="flex items-center ml-5">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="text-gray-600 text-sm font-light"
            {...getTableBodyProps()}
          >
            {!loading &&
              (manualPagination ? rows : page).map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    className="border-none text-black text-12 font-normal not-italic leading-17 h-40 even:bg-secondary even:bg-opacity-5 odd:bg-white"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="py-10 px-18 text-left"
                          {...cell.getCellProps()}
                          onClick={() => {
                            onCellClick &&
                              cell?.column?.id !== "actions" &&
                              onCellClick(row);
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
