/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { CommonApiConfig } from "api/configs/commonApiConfig";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { UserApiConfig } from "api/configs/userApiConfig";
import { MASTER_TENANT_ID } from "constants/commonConstants";
import { useApiQuery } from "hooks/useApi";
import React, { createContext, useContext, ReactNode, useState } from "react";
import { UseQueryResult } from "react-query";
import {
  buildPermissions,
  getDefaultTenant,
  getStorageData,
} from "utils/helper";

interface AppContextData {
  tenantsQuery: any;
  permissions: any;
  tenant: any;
  setTenant: any;
  tenantLoader: boolean;
  setTenantLoader: any;
  permissionsLoader: any;
  isProductApprovalEnabled: boolean;
  isCategorySequencingEnabled: boolean;
}

//Create the App Context with the data type specified
//and a empty object
const AppContext = createContext<AppContextData>({} as AppContextData);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenant, setTenant] = useState(getDefaultTenant() || "");
  const [tenantLoader, setTenantLoader] = useState(false);
  const { data: permissions, isLoading: permissionsLoader } = useApiQuery(
    UserApiConfig.GET_PERMISSIONS,
    {
      pathParams: {
        userId: getStorageData("userDetails")?.id,
        tenantId: MASTER_TENANT_ID,
      },
      dataParser: buildPermissions,
    }
  ) as UseQueryResult<any[], Error>;
  const tenantsQuery = useApiQuery(
    CommonApiConfig.GET_TENANTS_BY_OFFERING,
    {}
  ) as UseQueryResult<any[], Error>;
  const { data: isProductApprovalEnabled = false } = useApiQuery(
    ProductApiConfig.IS_PRODUCT_APPROVAL_ENABLED
  );
  const { data: isCategorySequencingEnabled = false } = useApiQuery(
    CategoryApiConfig.IS_SEQUENCING_ENABLED,
    { pathParams: { tenant } }
  );
  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AppContext.Provider
      value={{
        tenantsQuery,
        permissions,
        tenant,
        setTenant,
        tenantLoader,
        setTenantLoader,
        permissionsLoader,
        isProductApprovalEnabled,
        isCategorySequencingEnabled,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

//A simple hooks to facilitate the access to the AppContext
// and permit components to subscribe to AppContext updates
function useApp(): AppContextData {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
}

export { AppContext, AppProvider, useApp };
