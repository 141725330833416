import { AttributeApiConfig } from "api/configs/attributeApiConfig";
import Input from "components/ui/Input";
import Modal from "components/ui/Modal";
import SelectList from "components/ui/SelectList";
import { useApiMutate } from "hooks/useApi";
import useLists from "hooks/useLists";
import { useState } from "react";
import { handleError } from "utils/helper";

interface NewAttributeProps {
  isOpen: boolean;
  close: () => void;
}

export default function NewAttribute({ isOpen, close }: NewAttributeProps) {
  const [newAttribute, setNewAttribute] = useState("");
  const [tags, setTags] = useState([]);
  const resetData = () => {
    close();
    setNewAttribute("");
    setTags([]);
  };
  const { mutate: createAttribute } = useApiMutate(
    AttributeApiConfig.CREATE_ATTRIBUTE,
    {
      successCb: resetData,
      errorCb: handleError,
    }
  );
  const { tenants } = useLists();

  return (
    <Modal
      title="New Attribute"
      isOpen={isOpen}
      closeModal={resetData}
      onSave={() => {
        createAttribute({ name: newAttribute?.trim(), tenants: tags });
      }}
      saveBtnTxt="Save"
    >
      <div className="flex flex-col gap-y-30 py-20">
        <Input
          inputType="text"
          value={newAttribute}
          name="attribute"
          onChange={({ target: { value } }) => setNewAttribute(value)}
          label="Name:"
        />
        <div className="flex w-[90%] items-center">
          <label className="w-[20%] font-bold">Tags:</label>
          <div className="w-[70%]">
            <SelectList
              placeholder="Select tags"
              list={tenants}
              onChange={setTags}
              selected={tags}
              multiselect
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
