import { XCircleIcon } from "@heroicons/react/20/solid";

export default function RemoveIcon({ onClick }) {
  return (
    <XCircleIcon
      className="w-20 h-20 cursor-pointer"
      fill="red"
      onClick={onClick}
    />
  );
}
