export const BrandApiConfig: any = {
  GET_ALL_BRANDS: {
    url: "/brand",
    enabled: false,
  },
  GET_BRAND_BY_ID: {
    url: "/brand",
    enabled: false,
  },
  GET_BRAND_BY_NAME: {
    url: "/brand/search",
    enabled: false,
  },
  CREATE_BRAND: {
    url: "/brand",
    method: "post",
    successToast: "Created brand successfully!",
  },
};
