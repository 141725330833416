/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryApiConfig } from "api/configs/categoryConfig";
import Autocomplete from "components/ui/Autocomplete";
import { productCats } from "constants/productConstants";
import { useApiQuery } from "hooks/useApi";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { makeList } from "utils/helper";
import Button from "../../ui/Button";

export default function ProductCategory({
  categories,
  updateCategories,
  fetchCat,
  fetchingCategory,
}) {
  const [parentIdL0, setParentIdL0] = useState("");
  const [parentIdL1, setParentIdL1] = useState("");
  const [searchedCategory, setSearchedCategory] = useState("");
  const [searchParams] = useSearchParams();
  const { isFetching: l0CatsLoader } = useApiQuery(
    CategoryApiConfig.GET_ACTIVE_PARENT_CATEGORIES,
    {
      dataParser: makeList,
      successCb: (parentCat) =>
        setCategoryLists({ ...categoryLists, parentCat }),
    }
  );
  const { refetch: getChildCatsL1, isFetching: l1CatsLoader } = useApiQuery(
    CategoryApiConfig.GET_ACTIVE_CHILD_CATEGORIES,
    {
      pathParams: { parentId: parentIdL0 },
      dataParser: makeList,
      successCb: (childCatL1) =>
        setCategoryLists({ ...categoryLists, childCatL1 }),
    }
  );
  const { refetch: getChildCatsL2, isFetching: l2CatsLoader } = useApiQuery(
    CategoryApiConfig.GET_ACTIVE_CHILD_CATEGORIES,
    {
      pathParams: { parentId: parentIdL1 },
      dataParser: makeList,
      successCb: (childCatL2) =>
        setCategoryLists({ ...categoryLists, childCatL2 }),
    }
  );

  const [categoryLists, setCategoryLists] = useState({
    parentCat: [],
    childCatL1: [],
    childCatL2: [],
  });

  useEffect(() => {
    if (parentIdL0) getChildCatsL1();
  }, [parentIdL0]);

  useEffect(() => {
    if (parentIdL1) getChildCatsL2();
  }, [parentIdL1]);

  useEffect(() => {
    if (categories.parentCat?.value) setParentIdL0(categories.parentCat.value);
    if (categories.childCatL1?.value)
      setParentIdL1(categories.childCatL1.value);
  }, [categories.parentCat, categories.childCatL1]);

  const handleCategory = (name, value) => {
    switch (name) {
      case "parentCat":
        updateCategories({
          ...categories,
          [name]: value,
          childCatL1: "",
          childCatL2: "",
        });
        setCategoryLists({ ...categoryLists, childCatL1: [], childCatL2: [] });
        setParentIdL0(value?.value);
        break;
      case "childCatL1":
        updateCategories({
          ...categories,
          [name]: value,
          childCatL2: "",
        });
        setCategoryLists({ ...categoryLists, childCatL2: [] });
        setParentIdL1(value?.value);
        break;
      case "childCatL2":
        updateCategories({
          ...categories,
          [name]: value,
        });
        break;
      default:
        break;
    }
  };
  const showSubmit = () => {
    return (
      !l0CatsLoader &&
      !l1CatsLoader &&
      !l2CatsLoader &&
      ((categories?.parentCat?.value && !categoryLists?.childCatL1?.length) ||
        (categories?.parentCat?.value &&
          categoryLists?.childCatL1?.length &&
          categories?.childCatL1?.value &&
          !categoryLists?.childCatL2?.length) ||
        (categories?.parentCat?.value &&
          categoryLists?.childCatL1?.length &&
          categories?.childCatL1?.value &&
          categoryLists?.childCatL2?.length &&
          categories?.childCatL2?.value))
    );
  };

  return (
    <div className="flex gap-x-40">
      {productCats.map(({ name, placeholder }, i) => {
        return (name === "parentCat" && categoryLists[name]?.length) ||
          categoryLists[name]?.length ? (
          <div key={i} className="w-[15%]">
            <Autocomplete
              key={i}
              selected={categories[name]?.value}
              onChange={(value) => {
                handleCategory(name, value);
                setSearchedCategory("");
              }}
              list={categoryLists[name]}
              placeholder={placeholder}
              searchedValue={searchedCategory}
              onSearch={setSearchedCategory}
              disabled={!!searchParams.get("catId")}
            />
          </div>
        ) : null;
      })}
      {showSubmit() ? (
        <Button
          text={"Submit"}
          onButtonClick={() => {
            fetchCat();
          }}
          btnClass={"bg-btn"}
          loadingText="Fetching..."
          loading={fetchingCategory}
          disabled={!!searchParams.get("catId")}
        />
      ) : null}
    </div>
  );
}
