import type { FC } from "react";
import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DeleteOutlined } from "@ant-design/icons";

export interface CardProps {
  id: string;
  name: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  removeAttribute: any;
}

interface Item {
  id: string;
  originalIndex: number;
}

export const ItemTypes = {
  CARD: "card",
};

export const AttributeCard: FC<CardProps> = memo(function AttributeCard({
  id,
  name,
  moveCard,
  findCard,
  removeAttribute,
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity }}>
      <div
        className="font-bold bg-slate-300 rounded-md flex justify-between items-center p-3 mb-15 cursor-pointer"
        key={id}
      >
        <span className="p-5">{name}</span>
        <DeleteOutlined
          className="p-3 cursor-pointer"
          onClick={() => removeAttribute(id)}
        />
      </div>
    </div>
  );
});
