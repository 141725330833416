import "./App.css";
import { useAuth } from "context/authContext";
import { AppProvider } from "context/appContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthRoutes } from "Routes/authRoutes";
import AppRoutes from "Routes/appRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const queryClient = new QueryClient();

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        {isAuthenticated ? (
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        ) : (
          <AuthRoutes />
        )}
      </DndProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
