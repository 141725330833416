import { MASTER_TENANT } from "constants/commonConstants";
import { useApp } from "context/appContext";
import { useEffect, useState } from "react";

export default function useLists() {
  const { tenantsQuery } = useApp();
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    setTenants(
      tenantsQuery.data
        ?.filter(({ name }) => name !== MASTER_TENANT)
        ?.map(({ name }) => ({ label: name, value: name }))
    );
  }, [tenantsQuery.data]);

  return { tenants };
}
