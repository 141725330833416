import Title from "components/ui/Title";
import Spinner from "components/ui/Spinner";
import Tabs from "components/ui/Tabs";
import useProducts from "hooks/useProducts";
import AddVariants from "components/features/productVariants/AddVariants";
import EditVariants from "components/features/productVariants/EditVariants";
import { useApp } from "context/appContext";

export default function ProductVariants() {
  const { selectedTab, setSelectedTab } = useProducts();
  const { tenantLoader } = useApp();

  return (
    <div className="p-20">
      <Title title="Product Variants" />
      <div className="flex flex-col gap-y-20 p-20">
        <Tabs
          list={[
            { label: "Add", value: "add" },
            { label: "Edit", value: "edit" },
          ]}
          selectedTab={selectedTab}
          onClick={(tab) => {
            setSelectedTab(tab);
          }}
        />
        {tenantLoader ? (
          <Spinner />
        ) : (
          <>
            {selectedTab === "add" && <AddVariants />}
            {selectedTab === "edit" && <EditVariants />}
          </>
        )}
      </div>
    </div>
  );
}
