import { useAuth } from "context/authContext";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "api/apiClient";
import { toast } from "react-toastify";

const sendRequest = async (config, signOut: any) => {
  try {
    const res = await axiosInstance(config);
    return res?.data ?? res;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      signOut();
    } else {
      const {
        response: {
          data: { message = "Error!!" },
        },
      } = err;
      throw message;
    }
  }
};

const buildUrl = (url, pathParams?) => {
  // Replacing path params
  if (pathParams) {
    Object.entries(pathParams).forEach((entry) => {
      url = url.replace(`:${entry[0]}`, entry[1]?.toString());
    });
  }
  return url;
};

const onSuccess = (msg: any) => {
  if (msg) {
    toast.success(msg, {
      autoClose: 10000,
      theme: "light",
    });
  }
};

const onError = (msg: any) => {
  if (msg) {
    toast.error(msg, {
      autoClose: 10000,
      theme: "light",
    });
  }
};

type Options = {
  config: any;
  dataParser?: any;
  successCb?: any;
  errorCb?: any;
  refetchInterval?: number;
};

const buildOptions = ({
  config,
  dataParser,
  successCb,
  errorCb,
  refetchInterval,
}: Options) => {
  return {
    onSuccess: (data) => {
      onSuccess(config.successToast ?? data?.message);
      successCb && successCb(data);
    },
    onError: (error) => {
      onError(config.errorToast);
      errorCb && errorCb(error);
    },
    keepPreviousData: config.keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    select: dataParser,
    enabled: config.enabled,
    refetchInterval,
  };
};

export function useApiQuery(
  config: any,
  { pathParams, params, dataParser, successCb, errorCb }: any = {}
) {
  const url = buildUrl(config.url, pathParams);
  const options = buildOptions({ config, dataParser, successCb, errorCb });
  const { method, headers, service, responseType } = config;
  const { signOut } = useAuth();

  const queryFnc = () =>
    sendRequest(
      { url, method, params, headers, service, responseType },
      signOut
    );
  return useQuery([url, params], queryFnc, options);
}

export function useApiMutate(
  config: any,
  { pathParams, successCb, errorCb, params }: any
) {
  const url = buildUrl(config.url, pathParams);
  const options = buildOptions({ config, successCb, errorCb });
  const { method, headers, service, responseType } = config;
  const { signOut } = useAuth();

  const queryFnc = (data?: any) =>
    sendRequest(
      { url, method, params, data, headers, service, responseType },
      signOut
    );
  return useMutation(queryFnc, options);
}
