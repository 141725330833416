import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useAuth } from "context/authContext";
import { getStorageData } from "utils/helper";
import { Dropdown } from "./Dropdown";

function Header({ setShowSideMenu, showSideMenu }) {
  const { signOut } = useAuth();

  return (
    <div className="flex items-center justify-between bg-[#3E4449] h-[60px] w-full px-20">
      <Bars3Icon
        className="w-[30px] h-[35px] cursor-pointer text-white transition ease-in-out hover:-translate-y-1"
        onClick={() => setShowSideMenu(!showSideMenu)}
      />
      <div className="flex items-center">
        <UserCircleIcon className="w-[30px] h-[30px] text-white" />
        <Dropdown
          list={[{ label: "Logout", value: "logout" }]}
          onClick={signOut}
          labelClassName="text-white text-[18px] ml-5"
          label={getStorageData("userDetails")?.name}
        />
      </div>
    </div>
  );
}

export default Header;
