import { AttributeApiConfig } from "api/configs/attributeApiConfig";
import NewAttribute from "components/features/attributes/NewAttribute";
import Button from "components/ui/Button";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";
import Title from "components/ui/Title";
import { ATTRIBUTE } from "constants/commonConstants";
import { AttributeColumns } from "constants/TableColumns/attributeColumns";
import { useApiQuery } from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";

export function Attributes() {
  const [showNewAttribute, setShowNewAttribute] = useState(false);
  const [searchedAttribute, setSearchedAttribute] = useState("");
  const { canCreate } = usePermissions();
  const {
    data: attributes = [],
    refetch,
    isFetching,
  } = useApiQuery(AttributeApiConfig.GET_ATTRIBUTE_BY_NAME, {
    params: { name: searchedAttribute },
  });
  useEffect(() => {
    if (searchedAttribute) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedAttribute]);

  return (
    <div className="p-20">
      <Title title="Attribute" />
      <div className="flex justify-start gap-x-20 p-20">
        <DebounceInput
          minLength={3}
          debounceTimeout={200}
          className="border p-4 rounded outline-none"
          placeholder="Search attributes..."
          onChange={(e) => setSearchedAttribute(e.target.value)}
          value={searchedAttribute}
        />
        <Button
          text={"+ New Attribute"}
          onButtonClick={() => setShowNewAttribute(true)}
          btnClass="bg-btn"
          disabled={!canCreate(ATTRIBUTE)}
        />
      </div>
      {isFetching ? (
        <Spinner />
      ) : attributes.length ? (
        <div className="p-20">
          <div className="flex pb-20 text-20 text-[grey] opacity-50">
            Attributes matching "{searchedAttribute}"
          </div>
          <Table
            columns={AttributeColumns}
            data={attributes}
            loading={isFetching}
            hasPagination={false}
          />
        </div>
      ) : (
        <div className="flex p-20 text-20 text-[grey] opacity-50">
          Search some atrributes to see the data
        </div>
      )}
      <NewAttribute
        isOpen={showNewAttribute}
        close={() => setShowNewAttribute(false)}
      />
    </div>
  );
}
