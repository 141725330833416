import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { Fragment } from "react";

type DropdownType = {
  list: any;
  onClick: any;
  labelClassName?: string;
  label?: string;
  position?: "left" | "right";
  menuClassName?: string;
  disabled?: boolean;
};

export const Dropdown = (props: DropdownType) => {
  return (
    <Menu as="div" className={"relative inline-block text-left"}>
      <Menu.Button
        disabled={props.disabled}
        className={cn(
          "inline-flex items-center justify-between",
          props.labelClassName,
          props.disabled && "opacity-50 cursor-not-allowed"
        )}
      >
        {props.label && <span className="mr-6">{props.label}</span>}
        <ChevronDownIcon className="w-20 h-20" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "absolute w-auto overflow-scroll mt-2 z-[1] origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            props.menuClassName,
            props.position === "right" ? "left-0" : "right-0"
          )}
        >
          {props.list.map((item) => (
            <div
              className="px-1 py-1"
              key={`_d_${item.value}`}
              onClick={() => {
                props.onClick(item.value);
              }}
            >
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={cn(
                      "group flex rounded-md items-center w-full px-8 py-8 text-sm text-grey",
                      active && "bg-gray-100"
                    )}
                  >
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
