import axios from "axios";
import { getStorageData, setStorageData } from "utils/helper";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_CATALOGUE}`,
  headers: {},
});

axiosInstance.interceptors.request.use(
  (config): any => {
    const token = getStorageData("token")?.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer  ${token}`;
      config.headers["x-user-id"] = getStorageData("userDetails")?.uId;
    }
    const apiEndpoint = `REACT_APP_API_${config["service"] ?? "CATALOGUE"}`;
    config.baseURL = `${process.env[apiEndpoint]}`;
    return config;
  },
  (error): any => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (res): Promise<any> => {
    return res?.data;
  },
  async (err) => {
    if (err.response?.status === 401 && !err.config.retry) {
      const refreshToken = getStorageData("token")?.refreshToken;
      const refreshConfig: any = {
        method: "post",
        url: "/token/refresh",
        data: { refreshToken },
        service: "AUTH_CENTRAL",
        retry: true,
      };
      const tokenData = await axiosInstance(refreshConfig);
      if (tokenData) {
        setStorageData("token", tokenData?.data);
        const config = { ...err.config, retry: true };
        return axiosInstance(config);
      } else {
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
