import Checkbox from "components/ui/Checkbox";
import Input from "components/ui/Input";
import Modal from "components/ui/Modal";

export default function Replacements({
  type,
  attribute,
  show,
  close,
  update,
  replacements,
}) {
  const currentAttr = replacements?.find(
    ({ attributeId = "" }) => attributeId === attribute?._id
  );
  const isBlank =
    !!currentAttr?.isBlank || !!currentAttr?.DefaultIfBlank || false;

  const isNotblank =
    !!currentAttr?.isNotBlank ||
    !!currentAttr?.prefix ||
    !!currentAttr?.suffix ||
    false;

  const handleAttributes = (value, isLabel = false) => {
    update((curr) => {
      const t = curr.findIndex(
        ({ attributeId }) => attributeId === attribute?._id
      );
      if (t > -1) {
        if (isLabel)
          curr[t] = {
            ...curr[t],
            label: { ...curr[t]?.label, ...value?.label },
          };
        else curr[t] = { ...curr[t], ...value };
      } else {
        if (isLabel)
          curr = [
            ...curr,
            { label: { ...value?.label }, attributeId: attribute?._id },
          ];
        else curr = [...curr, { ...value, attributeId: attribute?._id }];
      }
      return [...curr];
    });
  };

  return (
    <div>
      <Modal
        isOpen={show}
        closeModal={close}
        title={"Replacements"}
        onSave={close}
        saveBtnTxt={"Save"}
      >
        <div>
          {type === "dropdown" && (
            <div className="flex flex-col gap-y-20">
              {attribute?.options?.map((option) => {
                return (
                  <div className="flex justify-between">
                    <Input
                      inputType={"text"}
                      value={
                        replacements.find(
                          ({ attributeId }) => attributeId === attribute?._id
                        )?.label?.[option]
                      }
                      name={option}
                      onChange={({ target: { value } }) =>
                        handleAttributes(
                          {
                            label: {
                              [option]: value,
                            },
                          },
                          true
                        )
                      }
                      label={option}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {type !== "dropdown" && (
            <div className="flex flex-col gap-y-20">
              <div className="flex pl-12">
                <label className="w-[30%] font-bold text-textColor">
                  Is blank
                </label>
                <Checkbox
                  checked={isBlank}
                  onChange={({ target: { checked } }) => {
                    handleAttributes({ isBlank: checked });
                  }}
                />
              </div>
              <div className="pl-12">
                <Input
                  inputType={"text"}
                  value={currentAttr?.DefaultIfBlank}
                  name={"DefaultIfBlank"}
                  onChange={({ target: { value } }) =>
                    handleAttributes({
                      DefaultIfBlank: value,
                    })
                  }
                  disabled={!isBlank}
                />
              </div>
              <div className="flex pl-12">
                <label className="w-[30%] font-bold text-textColor">
                  Is not blank
                </label>
                <Checkbox
                  checked={isNotblank}
                  onChange={({ target: { checked } }) =>
                    handleAttributes({ isNotBlank: checked })
                  }
                />
              </div>
              <Input
                inputType={"text"}
                value={currentAttr?.prefix}
                name={"prefix"}
                onChange={({ target: { value } }) =>
                  handleAttributes({
                    prefix: value,
                  })
                }
                label="Prefix"
                disabled={!isNotblank}
              />
              <Input
                inputType={"text"}
                value={currentAttr?.suffix}
                name={"suffix"}
                onChange={({ target: { value } }) =>
                  handleAttributes({
                    suffix: value,
                  })
                }
                label="Suffix"
                disabled={!isNotblank}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
