export const CATALOGUE_MAINTAINER = "Catalogue Maintainer";
export const MASTER_TENANT_ID = 3;
export const CATEGORY = "Category";
export const BRAND = "Brand";
export const ATTRIBUTE = "Attribute";
export const PRODUCT = "Product";
export const BULK_UPLOAD = "Bulk Upload";
export const CATALOGUE_OFFERING_ID = 1;
export const MASTER_TENANT = "MASTER";
export const PRODUCT_CREATOR = "Product-Creator";
export const PRODUCT_APPROVER = "Product-Approver";
export const DATE_FORMAT = "YYYY-MM-DD";
export const PRODUCT_APPROVER_PAGE = "Product Approver Page";
export const PRODUCT_CREATOR_PAGE = "Product Creator Page";
export const DATE_MONTH_YEAR_TIME_FORMAT = "DD MMM YYYY, hh:mm a";
export const PRODUCT_BULK_UPLOAD = "Product Bulk Upload";
export const SUPER_ADMIN = "Super Admin";
