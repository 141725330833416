import classNames from "classnames";
import { useState } from "react";

export default function ProgressBar({ w }) {
  const [style, setStyle] = useState({});
  setTimeout(() => {
    setStyle({ width: `${w}%` });
  });

  return (
    <div className="mb-4 w-full h-15 bg-gray-200 rounded-full dark:bg-gray-700">
      <div
        className={classNames(
          `h-15 text-xs text-center text-white bg-blue-600 rounded-full dark:bg-blue-500`
        )}
        style={style}
      >
        {w}%
      </div>
    </div>
  );
}
