import { Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

type AutocompleteProps = {
  selected: any;
  onChange: any;
  list: any;
  multiselect?: any;
  placeholder: string;
  apiSearch?: boolean;
  onSearch?: any;
  mandatory?: boolean;
  label?: string;
  searchedValue?: string;
  disabled?: boolean;
};

const Autocomplete = ({
  selected,
  onChange,
  list,
  multiselect = false,
  placeholder,
  apiSearch = false,
  onSearch,
  mandatory = false,
  label,
  searchedValue = "",
  disabled = false,
}: AutocompleteProps) => {
  const filteredList = !searchedValue
    ? list
    : list.filter(({ label }) =>
        label
          ?.toLowerCase()
          .replace(/\s+/g, "")
          .includes(searchedValue.toLowerCase().replace(/\s+/g, ""))
      );
  const value = multiselect
    ? selected
    : filteredList?.find(({ value = "" }) => value === selected);
  const selectedLabel = list?.find(
    ({ value = "" }) => value === selected
  )?.label;

  return (
    <Combobox
      value={value}
      onChange={onChange}
      multiple={multiselect}
      disabled={disabled}
    >
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-md text-left">
          <div className="flex h-40">
            {label && (
              <label className="pr-20 font-bold w-[20%]">
                {mandatory ? <span className="text-red pr-5">*</span> : ""}
                {label}
              </label>
            )}
            <Combobox.Input
              placeholder={placeholder}
              className={classNames(
                "w-full border rounded-md border-grey border-opacity-30 py-2 pl-3 pr-10 text-sm leading-[25px] text-gray-900 focus:ring-0 outline-none",
                disabled && "opacity-50 cursor-not-allowed bg-gray-100"
              )}
              displayValue={() =>
                multiselect ? "" : selectedLabel ?? searchedValue
              }
              onChange={(event) => onSearch(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-15 w-15 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
        </div>
        {(apiSearch ? list : filteredList).length ? (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => onSearch("")}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-200 w-full overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {(apiSearch ? list : filteredList).length === 0 &&
              (apiSearch ? !searchedValue.length : searchedValue !== "") ? (
                <div className="relative cursor-default select-none py-2 px-10 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                (apiSearch ? list : filteredList).map((item) => (
                  <Combobox.Option
                    key={item.value}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-[5px] pr-4 text-left w-full ${
                        active ? "bg-primary text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {item.label}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        ) : null}
      </div>
    </Combobox>
  );
};

export default Autocomplete;
