import { message } from "antd";
import { useState } from "react";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import Button from "./Button";

export default function UploadVideo({
  setVideo,
  video,
  uploadProgress,
  handleVideoRemove,
  uploadVideo,
  url,
  disabled = false,
}) {
  const [previewUrl, setPreviewUrl]: any = useState(url || "");

  const validateVideo = (file) => {
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type;
    const validFileSize = fileSize < 100;
    const validFileType = fileType === "video/mp4";

    if (!validFileSize) {
      message.error("Video file size should be less than 100MB!");
    }

    if (!validFileType) {
      message.error("Only MP4 video format is supported!");
    }

    return validFileSize && validFileType;
  };

  const handleVideoChange = async (event) => {
    const file = event.target.files[0];
    if (!validateVideo(file)) return;
    setVideo(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  return (
    <>
      <div className="m-20">
        {video || url ? (
          <div className="m-auto">
            <video width="470" height="255" controls>
              <source src={previewUrl} type="video/mp4" />
            </video>
            {!!video && <span>{video.name}</span>}
            {!disabled && (
              <div
                className="flex justify-center items-center mt-15 cursor-pointer"
                onClick={handleVideoRemove}
              >
                <DeleteOutlined />
                Remove
              </div>
            )}
          </div>
        ) : (
          <label className="p-15 border bg-[#ECF0F4] rounded cursor-pointer">
            <input
              id="video-upload"
              type="file"
              accept="video/mp4"
              onChange={handleVideoChange}
              className="hidden"
              disabled={disabled}
            />
            <>
              <UploadOutlined />
              Select Video
            </>
          </label>
        )}

        {!!(uploadProgress && uploadProgress < 100) && (
          <div className="flex items-center justify-center mt-15">
            <label htmlFor="videoUpload">Uploading progress:</label>
            <progress
              id="videoUpload"
              value={uploadProgress}
              max="100"
              className="ml-10 rounded-lg"
            />
          </div>
        )}
      </div>
      {!disabled && !!video && (
        <div>
          <Button
            text={"Upload"}
            onButtonClick={uploadVideo}
            btnClass={"bg-btn"}
            disabled={!video || disabled}
            loading={uploadProgress > 0}
            loadingText={"Uploading..."}
          />
        </div>
      )}
    </>
  );
}
