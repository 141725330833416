import SelectList from "../../ui/SelectList";
import Table, { TableColumns } from "../../ui/Table";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { displayNameOptions } from "constants/categoryConstants";
import Button from "components/ui/Button";
import Replacements from "./Replacements";

export default function DisplayNameLogic({
  mandatoryAttributes,
  displayNameValues,
  setDisplayNameValues,
  attributes = [],
  replacements = [],
  setReplacements = null,
  canUpdate = true,
}) {
  const [list, setList] = useState([
    ...displayNameOptions,
    ...mandatoryAttributes,
  ]);
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [showReplacements, setShowReplacements] = useState(false);

  const add = () => {
    // setList((prev) =>
    //   prev.filter(({ value }) => !data.find(({ d }) => d === value))
    // );
    setDisplayNameValues((prev) => [...prev, { d: "" }]);
  };
  const remove = (i: number) => {
    setDisplayNameValues((prev) => {
      prev.splice(i, 1);
      return [...prev];
    });
  };

  const handleDisplayName = (i: number, v: string) => {
    if (
      replacements?.find(({ attributeId = "" }) => attributeId === v) &&
      !displayNameValues.find(({ d }) => d === v)
    ) {
      setReplacements((prev) =>
        prev.filter(({ attributeId }) => attributeId !== v)
      );
    }
    setDisplayNameValues((prev) => {
      prev[i].d = v;
      return [...prev];
    });
  };
  const cols: TableColumns[] = [
    {
      Header: "Display Name",
      accessor: "d",
      Cell: ({ value, row: { index } }) => (
        <div className="flex gap-x-15">
          <SelectList
            placeholder="Select"
            list={list}
            onChange={(v: string) => {
              handleDisplayName(index, v);
            }}
            selected={value}
            disabled={!canUpdate}
          />
          {value &&
            !displayNameOptions.find(({ value: type }) => type === value) && (
              <Button
                text={"Replacements"}
                onButtonClick={() => {
                  setSelectedAttribute(value);
                  setShowReplacements(true);
                }}
                btnClass={"bg-btn text-11 px-5 py-5"}
                disabled={!canUpdate}
              />
            )}
        </div>
      ),
    },
    {
      Header: "Add/Remove",
      Cell: ({ row: { index } }) => (
        <div className="flex justify-center gap-x-10">
          {index === displayNameValues.length - 1 && (
            <PlusCircleIcon
              className="w-20 h-20 cursor-pointer"
              fill="green"
              onClick={() => canUpdate && add()}
            />
          )}
          {displayNameValues.length > 1 && (
            <XCircleIcon
              className="w-20 h-20 cursor-pointer"
              fill="red"
              onClick={() => canUpdate && remove(index)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="p-20">
      <p className="flex font-medium pb-30">
        Result:{" "}
        <span className="text-primary pl-15">
          {displayNameValues
            .map(({ d }) => {
              const t = mandatoryAttributes?.find(({ value }) => d === value);
              if (t) return t.label;
              return d;
            })
            ?.join(" ")}
        </span>
      </p>

      <Table
        columns={cols}
        data={displayNameValues}
        loading={false}
        hasPagination={false}
      />
      <Replacements
        type={
          attributes.find(({ _id }) => _id === selectedAttribute)?.valueType
        }
        attribute={attributes.find(({ _id }) => _id === selectedAttribute)}
        show={showReplacements}
        close={() => setShowReplacements(false)}
        update={setReplacements}
        replacements={replacements}
      />
    </div>
  );
}
