import Checkbox from "components/ui/Checkbox";
import ImagePreview from "components/ui/ImagePreview";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import usePermissions from "hooks/usePermissions";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { createArrayWithValues } from "utils/helper";

export default function BrochuresPreview({
  brochures,
  setBrochuresToDelete,
  productStatus = "",
  brochuresToDelete,
}) {
  const { canRead, canUpdate, canAccess } = usePermissions();
  const { tenant, isProductApprovalEnabled } = useApp();
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from");

  const cannotEdit =
    isProductApprovalEnabled &&
    productStatus &&
    productStatus !== APPROVED_STATUS
      ? fromPage !== "approver" &&
        (productStatus === PENDING_STATUS ||
          (productStatus === DRAFT_STATUS && !canAccess(PRODUCT_CREATOR_PAGE)))
      : (canRead(PRODUCT) && !canUpdate(PRODUCT)) || tenant === MASTER_TENANT;

  return (
    <div className="p-20">
      <div className="grid grid-cols-3 py-20">
        <div className="flex items-center justify-center">
          <Checkbox
            onChange={({ target: { checked } }) => {
              if (checked)
                setBrochuresToDelete(createArrayWithValues(brochures?.length));
              else setBrochuresToDelete([]);
            }}
            disabled={cannotEdit}
            checked={brochuresToDelete.length === brochures?.length}
            label={"Delete Brochures"}
            labelRight
          />
        </div>
        <div className="font-bold text-textColor">Web</div>
        <div className="font-bold text-textColor">Mobile</div>
      </div>
      <div className="grid grid-cols-3 gap-y-20 py-20">
        {brochures?.map(({ webImageUrl, mobileImageUrl }, i) => {
          return (
            <Fragment key={i}>
              <div
                key={`checkbox_${i}`}
                className="flex items-center justify-center"
              >
                <Checkbox
                  disabled={cannotEdit}
                  onChange={({ target: { checked } }) => {
                    if (checked) {
                      setBrochuresToDelete((curr) => [...curr, i]);
                    } else
                      setBrochuresToDelete((curr) =>
                        curr.filter((imgIndex) => imgIndex !== i)
                      );
                  }}
                  checked={brochuresToDelete.includes(i)}
                />
              </div>
              {webImageUrl ? (
                <div key={`webImageUrl_${i}`} className="flex justify-center">
                  <ImagePreview url={webImageUrl} />
                </div>
              ) : (
                <div></div>
              )}
              {mobileImageUrl ? (
                <div
                  key={`mobileImageUrl_${i}`}
                  className="flex justify-center"
                >
                  <ImagePreview url={mobileImageUrl} />
                </div>
              ) : (
                <div></div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
