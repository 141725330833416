import AddIcon from "components/ui/AddIcon";
import Input from "components/ui/Input";
import RemoveIcon from "components/ui/RemoveIcon";
import UploadImage from "components/ui/UploadImage";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
  pictureVariants,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import usePermissions from "hooks/usePermissions";
import { useSearchParams } from "react-router-dom";

export default function ProductPicture({
  noOfImages,
  images,
  handleImages,
  addImage,
  removeImage,
  handleImageSequence,
  info,
  imageUploadFailed = false,
  isEdit = false,
  removeImageVariant = null,
  uploadSuccess = false,
}) {
  const { canRead, canUpdate, canAccess } = usePermissions();
  const { tenant, isProductApprovalEnabled } = useApp();
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from");
  const cannotEdit =
    isEdit &&
    (isProductApprovalEnabled && info.status && info.status !== APPROVED_STATUS
      ? fromPage !== "approver" &&
        (info.status === PENDING_STATUS ||
          (info.status === DRAFT_STATUS && !canAccess(PRODUCT_CREATOR_PAGE)))
      : (canRead(PRODUCT) && !canUpdate(PRODUCT)) || tenant === MASTER_TENANT);

  return (
    <div className="flex flex-col p-30 bg-white">
      <div className="grid grid-cols-5 pt-10 pb-20">
        <label className="font-bold text-14">Small variant</label>
        <label className="font-bold text-14">
          <span className="text-red pr-5">*</span>Medium variant
        </label>
        <label className="font-bold text-14">Large variant</label>
        <label className="font-bold text-14">
          <span className="text-red pr-5">*</span>Sequence
        </label>
      </div>
      <div>
        {Object.keys(noOfImages)?.map((k, idx) => {
          return (
            <div key={k} className="grid grid-cols-5 gap-y-60 items-center">
              {pictureVariants.map(({ name, accept }, i) => (
                <div key={i} className="flex flex-col gap-y-30 items-center">
                  <UploadImage
                    uploadImage={(file) => handleImages(k, name, file, idx)}
                    info={info}
                    imageUploadFailed={imageUploadFailed}
                    disabled={cannotEdit}
                    variant={name}
                    images={images}
                    row={k}
                    removeImage={() => removeImageVariant(k, name)}
                    uploadSuccess={uploadSuccess}
                    accept={accept}
                  />
                </div>
              ))}
              <div className="flex gap-y-30 items-center mt-20 justify-items-center ml-[90px]">
                <Input
                  inputType={"number"}
                  value={images[k]?.order ?? ""}
                  name={"order"}
                  onChange={({ target: { value } }) =>
                    handleImageSequence(k, value)
                  }
                  placeholder="Sequence"
                  disabled={cannotEdit}
                />
              </div>
              <div className="flex justify-start gap-x-20 gap-y-30 pt-5">
                {Number(idx) === Object.keys(noOfImages).length - 1 && (
                  <AddIcon
                    onClick={() => {
                      !cannotEdit &&
                        addImage(
                          Number(
                            Object.keys(noOfImages)[
                              Object.keys(noOfImages).length - 1
                            ]
                          ) + 1
                        );
                    }}
                  />
                )}
                {Object.keys(noOfImages).length > 1 && (
                  <RemoveIcon onClick={() => !cannotEdit && removeImage(k)} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
