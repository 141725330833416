import { CategoryApiConfig } from "api/configs/categoryConfig";
import { weightTypes } from "constants/categoryConstants";
import { CATEGORY } from "constants/commonConstants";
import { useApiQuery } from "hooks/useApi";
import useLists from "hooks/useLists";
import usePermissions from "hooks/usePermissions";
import { makeList } from "utils/helper";
import Input from "../../ui/Input";
import SelectList from "../../ui/SelectList";

interface CategoryDetailsProps {
  details: any;
  updateDetails: any;
  isEdit?: boolean;
}

export default function CategoryDetails({
  details,
  updateDetails,
  isEdit = false,
}: CategoryDetailsProps) {
  const { tenants } = useLists();
  const { data: cats = [] } = useApiQuery(
    CategoryApiConfig.GET_ALL_CATEGORIES,
    {
      dataParser: makeList,
    }
  );
  const { canUpdate } = usePermissions();

  return (
    <div className="grid grid-cols-1 gap-20 py-20 justify-start">
      <Input
        inputType="text"
        value={details.name}
        name="name"
        onChange={({ target: { value: name } }) => updateDetails({ name })}
        disabled={isEdit}
        label={"Name:"}
        mandatory
      />
      <div className="flex w-[90%] justify-start items-center text-textColor">
        <label className="w-[20%] text-right pr-20 font-bold">Parent:</label>
        <SelectList
          placeholder="Select parent"
          list={cats}
          onChange={(parent) => {
            updateDetails({ parent });
          }}
          selected={details.parent}
          name="parent"
          disabled={isEdit}
        />
      </div>
      <Input
        inputType="text"
        value={details.hsnCode}
        name="hsnCode"
        onChange={({ target: { value: hsnCode } }) =>
          updateDetails({ hsnCode })
        }
        label={"HSN Code:"}
        disabled={isEdit && !canUpdate(CATEGORY)}
        mandatory
      />
      <Input
        inputType="number"
        value={details.gstPercentage}
        name="gstPercentage"
        onChange={({ target: { value: gstPercentage } }) =>
          updateDetails({ gstPercentage })
        }
        label={"GST %:"}
        disabled={isEdit && !canUpdate(CATEGORY)}
        mandatory
      />
      <div className="flex w-[90%] justify-start items-center text-textColor">
        <label className="w-[20%] text-right pr-20 font-bold">
          Weight Type:
        </label>
        <SelectList
          placeholder="Select"
          list={weightTypes}
          onChange={(weightType) => {
            updateDetails({ weightType });
          }}
          selected={details.weightType}
          name="weightType"
          disabled={isEdit && !canUpdate(CATEGORY)}
        />
      </div>
      <div className="flex w-[90%] justify-start items-center text-textColor">
        <label className="w-[20%] text-right pr-20 font-bold">
          <span className="text-red pr-5">*</span>
          Tags:
        </label>
        <SelectList
          placeholder="Select tags"
          list={tenants}
          onChange={(tenants) => updateDetails({ tenants })}
          selected={details.tenants}
          multiselect
          name="tenants"
          disabled={isEdit && !canUpdate(CATEGORY)}
        />
      </div>
    </div>
  );
}
