import BrochuresPreview from "components/features/products/BrochuresPreview";
import GenerateASN from "components/features/products/GenerateASN";
import Images from "components/features/products/Images";
import ProductBrochures from "components/features/products/ProductBrochures";
import ProductInfo from "components/features/products/ProductInfo";
import ProductPicture from "components/features/products/ProductPicture";
import ProductSpecifications from "components/features/products/ProductSpecifications";
import ProductVideos from "components/features/products/ProductVideos";
import UpdateASN from "components/features/products/UpdateASN";
import Accordion from "components/ui/Accordion";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import Tabs from "components/ui/Tabs";
import TextArea from "components/ui/TextArea";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
  SUPER_ADMIN,
} from "constants/commonConstants";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  editProductTabs,
  PENDING_STATUS,
} from "constants/productConstants";
import { useApp } from "context/appContext";
import useEditProducts from "hooks/useEditProducts";
import useGenerateASN from "hooks/useGenerateASN";
import usePermissions from "hooks/usePermissions";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import { getStorageData } from "utils/helper";

export default function EditProducts() {
  const {
    selectedTab,
    setSelectedTab,
    setSearchedAsn,
    getProduct,
    searchedProducts,
    selectedProduct,
    setSelectedProduct,
    setSelectedProductId,
    productData,
    setProductData,
    attributesMetaData = [],
    productSpecs,
    setProductSpecs,
    updateProductDetails,
    updateLoader,
    images,
    setImagesToDelete,
    handleImages,
    addImage,
    removeImage,
    noOfImages,
    handleImageSequence,
    categoryBrandsList,
    getProductLoader,
    displayName,
    generateDisplay,
    displayNameLoader,
    searchedAsn,
    setDisplayName,
    categoryTenantsList,
    approving,
    declining,
    submitting,
    noOfBrochures,
    handleBrochures,
    brochureImages,
    addBrochure,
    removeBrochure,
    setBrochuresToDelete,
    removeBrochureVariant,
    removeImageVariant,
    imageUploadFailed,
    updateProductSuccess,
    imagesToDelete,
    brochuresToDelete,
    approveProduct,
    declineProduct,
    submitProductForApproval,
    downloadImages,
    showUpdateASN,
    setShowUpdateASN,
    updateASN,
    newASN,
    setNewASN,
    downloadImagesLoader,
    video,
    setVideo,
    handleVideoRemove,
    uploadVideo,
    uploadProgress,
  } = useEditProducts();
  const { canRead, canUpdate, canAccess } = usePermissions();
  const {
    tenant,
    isProductApprovalEnabled,
    permissions: { roles },
  } = useApp();
  const [searchParams] = useSearchParams();

  const fromPage = searchParams.get("from");
  const cannotEdit =
    isProductApprovalEnabled &&
    productData.status &&
    productData.status !== APPROVED_STATUS
      ? fromPage !== "approver" &&
        (productData.status === PENDING_STATUS ||
          (productData.status === DRAFT_STATUS &&
            !canAccess(PRODUCT_CREATOR_PAGE)))
      : canRead(PRODUCT) && !canUpdate(PRODUCT);

  const getBtnText = () => {
    if (
      isProductApprovalEnabled &&
      productData.status &&
      productData.status === DRAFT_STATUS
    ) {
      return "Request for approval";
    }
    return "Save";
  };

  const isCreatorNdApprover = () =>
    !roles.includes(SUPER_ADMIN) &&
    productData.createdBy === getStorageData("userDetails")?.uId;

  const cloneProduct = () => {
    window.open(
      `/products?id=${productData._id}&catId=${productData?.categories?.[0]?.id}`,
      "_blank"
    );
  };
  const { setShowGenerateASNPopup, showGenerateASNPopup } = useGenerateASN();

  return (
    <>
      <div className="flex justify-start gap-x-20">
        <div className="w-[30%]">
          <Autocomplete
            selected={selectedProduct}
            onSearch={debounce((value) => {
              setSearchedAsn(value);
            }, 300)}
            onChange={({ value }) => {
              setSelectedProduct(value);
              setSelectedProductId(value);
            }}
            placeholder="Search ASN or Model name to edit..."
            list={searchedProducts}
            apiSearch
            searchedValue={searchedAsn}
          />
        </div>
        <Button
          text={"Submit"}
          onButtonClick={getProduct}
          btnClass="bg-btn"
          loadingText="Fetching..."
          disabled={getProductLoader}
          loading={getProductLoader}
        />
        {tenant === MASTER_TENANT &&
          [null, "APPROVED"].includes(productData.status) && (
            <Button
              text={"Clone"}
              onButtonClick={cloneProduct}
              btnClass="bg-btn"
              disabled={!productData._id}
            />
          )}
        {tenant === MASTER_TENANT && (
          <>
            <Button
              text={"Generate ASN"}
              onButtonClick={() => setShowGenerateASNPopup(true)}
              btnClass="bg-btn"
            />
            <Button
              text={"Update ASN"}
              onButtonClick={() => setShowUpdateASN(true)}
              btnClass="bg-btn"
              disabled={!productData._id}
            />
          </>
        )}
      </div>
      <Tabs
        list={editProductTabs}
        selectedTab={selectedTab}
        onClick={setSelectedTab}
      />
      {productData?.categories?.length ? (
        <>
          {selectedTab === "specifications" && (
            <>
              {isProductApprovalEnabled &&
                fromPage !== "approver" &&
                productData.status === DRAFT_STATUS &&
                canAccess(PRODUCT_CREATOR_PAGE) && (
                  <TextArea
                    name={"comment"}
                    value={productData?.comment ?? ""}
                    onChange={({ target: { value: comment } }) =>
                      setProductData({ ...productData, comment })
                    }
                    label="Comment"
                    disabled
                  />
                )}
              <Accordion
                title={productData?.displayName}
                labels={["Category: ", "ASN: "]}
                labelValues={[
                  productData.categories?.[0]?.name,
                  productData.asn,
                ]}
                titleClass="text-17"
                labelClass="text-primary text-17"
              >
                <ProductInfo
                  info={productData}
                  updateInfo={setProductData}
                  isEdit
                  categoryBrandsList={categoryBrandsList}
                  setDisplayName={setDisplayName}
                  categoryTenantsList={categoryTenantsList}
                />
              </Accordion>
              {attributesMetaData?.length ? (
                <Accordion title={"Specifications"} titleClass="text-17">
                  <ProductSpecifications
                    metaData={attributesMetaData}
                    data={productSpecs}
                    setData={setProductSpecs}
                    isEdit
                    setDisplayName={setDisplayName}
                  />
                </Accordion>
              ) : null}
            </>
          )}
          {selectedTab === "media" && (
            <div className="flex flex-col gap-y-40">
              <Accordion title={"Upload Product Images"}>
                <ProductPicture
                  images={images}
                  handleImages={handleImages}
                  noOfImages={noOfImages}
                  addImage={addImage}
                  removeImage={removeImage}
                  handleImageSequence={handleImageSequence}
                  info={productData}
                  isEdit
                  removeImageVariant={removeImageVariant}
                  imageUploadFailed={imageUploadFailed}
                  uploadSuccess={updateProductSuccess}
                />
              </Accordion>
              {productData?.images?.imagesData?.length ? (
                <Accordion
                  title={
                    <div>
                      Product Images
                      <Button
                        text={"Download"}
                        onButtonClick={downloadImages}
                        btnClass={"bg-btn mx-20"}
                        loading={downloadImagesLoader}
                        loadingText={"Downloading..."}
                        disabled={downloadImagesLoader}
                      />
                    </div>
                  }
                >
                  <Images
                    images={productData?.images}
                    setImagesToDelete={setImagesToDelete}
                    productStatus={productData?.status}
                    imagesToDelete={imagesToDelete}
                  />
                </Accordion>
              ) : null}
              <Accordion title={"Upload Brochure Images"}>
                <ProductBrochures
                  noOfImages={noOfBrochures}
                  images={brochureImages}
                  addImage={addBrochure}
                  removeImage={removeBrochure}
                  info={productData}
                  handleBrochures={handleBrochures}
                  isEdit
                  removeBrochureVariant={removeBrochureVariant}
                  imageUploadFailed={imageUploadFailed}
                  uploadSuccess={updateProductSuccess}
                />
              </Accordion>
              {productData?.brochureImages?.length ? (
                <Accordion title={"Brochure Images"}>
                  <BrochuresPreview
                    brochures={productData?.brochureImages}
                    setBrochuresToDelete={setBrochuresToDelete}
                    productStatus={productData?.status}
                    brochuresToDelete={brochuresToDelete}
                  />
                </Accordion>
              ) : null}
              <Accordion title={"Video"}>
                <ProductVideos
                  info={productData}
                  setVideo={setVideo}
                  video={video}
                  isEdit
                  handleVideoRemove={handleVideoRemove}
                  url={productData.video}
                  uploadProgress={uploadProgress}
                  uploadVideo={uploadVideo}
                />
              </Accordion>
            </div>
          )}
          {tenant === MASTER_TENANT && (
            <div className="flex items-center gap-x-20 py-10">
              <Button
                text={"Show Display Name"}
                onButtonClick={generateDisplay}
                btnClass={"bg-btn py-[10px]"}
                loading={displayNameLoader}
                loadingText="Generating..."
                disabled={displayNameLoader || cannotEdit}
              />
              <p className="mt-14 text-18 font-bold">
                Display Name:{" "}
                <span className="text-primary">{displayName}</span>
              </p>
            </div>
          )}
          {(!isProductApprovalEnabled ||
            !productData.status ||
            productData.status === APPROVED_STATUS ||
            (fromPage !== "approver" &&
              productData.status !== APPROVED_STATUS)) && (
            <div className="flex justify-center gap-x-20 py-40">
              <Button
                text={getBtnText()}
                onButtonClick={() => {
                  getBtnText() === "Save"
                    ? updateProductDetails()
                    : submitProductForApproval();
                }}
                btnClass={"bg-btn px-[30px] py-[10px]"}
                disabled={updateLoader || cannotEdit || submitting}
                loading={updateLoader}
                loadingText="Processing..."
              />
            </div>
          )}
          {isProductApprovalEnabled &&
            productData.status &&
            (fromPage === "creator"
              ? productData.status === DRAFT_STATUS
              : fromPage === "approver"
              ? productData.status !== APPROVED_STATUS
              : false) && (
              <div className="flex justify-between">
                <div className="grid grid-cols-1 gap-y-30 w-1/3">
                  {fromPage === "approver" &&
                    productData.status !== APPROVED_STATUS && (
                      <div className="flex items-center justify-between">
                        <label className="pr-20 text-right text-textColor font-bold">
                          Comment
                        </label>
                        <TextArea
                          name={"comment"}
                          value={productData?.comment ?? ""}
                          onChange={({ target: { value: comment } }) =>
                            setProductData({ ...productData, comment })
                          }
                        />
                      </div>
                    )}
                  {isProductApprovalEnabled &&
                    fromPage === "approver" &&
                    productData.status !== APPROVED_STATUS && (
                      <Button
                        text={"Decline"}
                        onButtonClick={declineProduct}
                        btnClass={"bg-btn px-[30px] py-[10px] w-[120px]"}
                        disabled={declining || isCreatorNdApprover()}
                        loadingText="Processing..."
                        loading={declining}
                      />
                    )}
                </div>
                {isProductApprovalEnabled &&
                  fromPage === "approver" &&
                  productData.status !== APPROVED_STATUS && (
                    <div className="flex justify-end w-1/3 items-center gap-x-30">
                      <div className="flex items-center justify-end">
                        <label className="pr-20 text-right text-textColor font-bold">
                          Active
                        </label>
                        <Checkbox
                          onChange={({ target: { checked: active } }) =>
                            setProductData({ ...productData, active })
                          }
                          name={"active"}
                          checked={productData?.active}
                        />
                      </div>
                      <div>
                        <Button
                          text={"Approve"}
                          onButtonClick={approveProduct}
                          btnClass={"bg-btn px-[30px] py-[10px] w-[120px]"}
                          disabled={approving || isCreatorNdApprover()}
                          loading={approving}
                          loadingText="Processing..."
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
        </>
      ) : null}
      <GenerateASN
        show={showGenerateASNPopup}
        close={() => setShowGenerateASNPopup(false)}
      />
      <UpdateASN
        show={showUpdateASN}
        oldASN={productData?.asn}
        close={() => setShowUpdateASN(false)}
        update={updateASN}
        newASN={newASN}
        setNewASN={setNewASN}
        updating={updateLoader}
      />
    </>
  );
}
