import Button from "components/ui/Button";
import { TableColumns } from "components/ui/Table";
import {
  DATE_MONTH_YEAR_TIME_FORMAT,
  SUPER_ADMIN,
} from "constants/commonConstants";
import { useApp } from "context/appContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getStorageData } from "utils/helper";

const CommonProductColumns: TableColumns[] = [
  {
    Header: "ASN",
    accessor: "asn",
  },
  {
    Header: "Model Name",
    accessor: "modelName",
  },
  {
    Header: "Display Name",
    accessor: "productDisplayName",
  },
  {
    Header: "Parent Category",
    accessor: "categoryName",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }) =>
      value && moment(value).format(DATE_MONTH_YEAR_TIME_FORMAT),
  },
  {
    Header: "Updated By",
    accessor: "updatedBy",
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
    Cell: ({ value }) =>
      value && moment(value).format(DATE_MONTH_YEAR_TIME_FORMAT),
  },
];

export const CreatorDraftProductColumns: TableColumns[] = [
  ...CommonProductColumns,
  { Header: "Comment", accessor: "comment" },
  {
    Header: "Preview",
    Cell: ({
      row: {
        original: { id },
      },
    }) => {
      const navigate = useNavigate();
      return (
        <Button
          text={"Preview"}
          onButtonClick={() => navigate(`/products?id=${id}&from=creator`)}
          btnClass={"bg-btn px-15"}
        />
      );
    },
  },
];

export const ApproverDraftProductColumns: TableColumns[] = [
  ...CommonProductColumns,
  { Header: "Comment", accessor: "comment" },
  {
    Header: "Preview",
    Cell: ({
      row: {
        original: { id, createdBy },
      },
    }) => {
      const navigate = useNavigate();
      const {
        permissions: { roles },
      } = useApp();
      return (
        <Button
          text={"Preview"}
          onButtonClick={() => navigate(`/products?id=${id}&from=approver`)}
          btnClass={"bg-btn px-15"}
          disabled={
            !roles.includes(SUPER_ADMIN) &&
            createdBy === getStorageData("userDetails")?.uId
          }
        />
      );
    },
  },
];

export const CreatorPendingProductColumns: TableColumns[] =
  CreatorDraftProductColumns;
export const ApproverPendingProductColumns: TableColumns[] =
  ApproverDraftProductColumns;

export const ApprovedProductColumns: TableColumns[] = [
  ...ApproverDraftProductColumns.filter(
    ({ accessor }) => accessor !== "comment"
  ),
  { Header: "Approved By", accessor: "approvedBy" },
  {
    Header: "Approved At",
    accessor: "approvedAt",
    Cell: ({ value }) =>
      value && moment(value).format(DATE_MONTH_YEAR_TIME_FORMAT),
  },
];
