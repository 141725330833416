import update from "immutability-helper";
import type { FC } from "react";
import { memo, useCallback } from "react";
import { useDrop } from "react-dnd";
import { AttributeCard } from "./AttributeCard";

const ItemTypes = {
  CARD: "card",
};

export interface ContainerState {
  cards: any[];
}

export const VariantAttributes: FC<{ cards; setCards; removeAttribute }> = memo(
  function CategoriesContainer({ cards, setCards, removeAttribute }) {
    const findCard = useCallback(
      (id: string) => {
        const card = cards.filter((c) => `${c.id}` === id)[0] as {
          id: number;
          name: string;
        };
        return {
          card,
          index: cards.indexOf(card),
        };
      },
      [cards]
    );

    const moveCard = useCallback(
      (id: string, atIndex: number) => {
        const { card, index } = findCard(id);
        setCards(
          update(cards, {
            $splice: [
              [index, 1],
              [atIndex, 0, card],
            ],
          })
        );
      },
      [findCard, cards, setCards]
    );

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    return (
      <div ref={drop}>
        {cards.map((card) => (
          <AttributeCard
            key={card.id}
            id={`${card.id}`}
            name={card.name}
            moveCard={moveCard}
            findCard={findCard}
            removeAttribute={removeAttribute}
          />
        ))}
      </div>
    );
  }
);
