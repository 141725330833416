import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

export default function Accordion(props) {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className="bg-white text-black rounded-md shadow-md p-10 cursor-pointer">
      <div
        className={classNames(
          "flex w-[98.5%] justify-between",
          isActive && "border-b"
        )}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="flex w-[90%] justify-between text-20 p-10">
          {props.title && (
            <div
              className={classNames("text-left font-bold", props.titleClass)}
            >
              {props.title}
            </div>
          )}
          {props.labels &&
            props.labels.map((label, i) => (
              <div key={i} className={classNames(props.labelClass)}>
                <span className="font-bold">{label}</span>
                <span className="font-medium">{props.labelValues[i]}</span>
              </div>
            ))}
        </div>
        <div>
          {isActive ? (
            <ChevronUpIcon className="w-20 h-20 mt-10" />
          ) : (
            <ChevronDownIcon className="w-20 h-20 mt-10" />
          )}
        </div>
      </div>
      {isActive && <div className="accordion-content">{props.children}</div>}
    </div>
  );
}
