import classNames from "classnames";
import SelectList from "./SelectList";

type InputProps = {
  inputType: string;
  value: string;
  name: string;
  onChange: any;
  label?: string;
  mandatory?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hasDropdown?: boolean;
  list?: any;
  onDropdrownChange?: any;
  selected?: any;
  maxLength?: number;
  minLength?: number;
  width?: string;
  hasFilterDropdown?: boolean;
  filterOptionList?: any;
  onFilterChange?: any;
  selectedFilter?: string;
  filterDisabled?: boolean;
};

export default function Input({
  inputType,
  value,
  name,
  onChange,
  label,
  mandatory = false,
  disabled = false,
  placeholder = "",
  hasDropdown = false,
  list,
  onDropdrownChange,
  selected,
  maxLength,
  minLength,
  hasFilterDropdown,
  filterOptionList,
  onFilterChange,
  selectedFilter,
  filterDisabled,
}: InputProps) {
  return (
    <div className={classNames("w-[90%] flex justify-start items-center")}>
      {label && (
        <label className="pr-20 text-right w-[20%] text-textColor font-bold">
          {mandatory ? <span className="text-red pr-5">*</span> : ""}
          {label}
        </label>
      )}
      <input
        className={classNames(
          "rounded border-[1.5px] outline-none p-2 h-40",
          disabled && "cursor-not-allowed",
          label && !hasFilterDropdown
            ? "w-[70%]"
            : hasFilterDropdown
            ? "w-[55%]"
            : "w-[90%]"
        )}
        type="text"
        name={name}
        value={value}
        onChange={(e) => {
          if (inputType === "number") {
            const re = /^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              onChange(e);
            }
          } else if (inputType === "alphanumeric") {
            const re = /^[a-zA-Z0-9]*$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              onChange(e);
            }
          } else onChange(e);
        }}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
      />
      {hasDropdown ? (
        <SelectList
          list={list}
          onChange={onDropdrownChange}
          selected={selected}
          placeholder="Unit"
          labelType="dropdown"
        />
      ) : null}
      {hasFilterDropdown ? (
        <SelectList
          list={filterOptionList}
          onChange={onFilterChange}
          selected={selectedFilter}
          placeholder="Filter"
          labelType="dropdown"
          disabled={filterDisabled}
        />
      ) : null}
    </div>
  );
}
