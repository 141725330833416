export default function Checkbox({ labelRight = false, ...checkboxProps }) {
  return (
    <div className="flex w-[90%] justify-start">
      {checkboxProps.label && !labelRight && (
        <label className="pr-20 w-[20%] text-right font-bold text-textColor">
          {checkboxProps.label}
        </label>
      )}
      <div className="flex justify-start w-20">
        <input
          type="checkbox"
          {...checkboxProps}
          className="form-checkbox w-[70%] text-left cursor-pointer"
        />
      </div>
      {checkboxProps.label && labelRight && (
        <label className="w-[20%] font-bold text-textColor">
          {checkboxProps.label}
        </label>
      )}
    </div>
  );
}
