/* eslint-disable react-hooks/exhaustive-deps */
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { ProductApiConfig } from "api/configs/productApiConfig";
import ProductCategory from "components/features/products/ProductCategory";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import Title from "components/ui/Title";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { handleError, makeProductsSelectionList } from "utils/helper";
import classNames from "classnames";
import { useApp } from "context/appContext";

export default function EditSubCategory() {
  const [asn, setAsn] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [brandCheck, setBrandCheck] = useState("");
  const [shouldDeleteAttributes, setShouldDeleteAttributes] = useState(false);
  const [categories, setCategories]: any = useState({
    childCatL2: {},
    childCatL1: {},
    parentCat: {},
  });
  const checkBrand = (data) => {
    if (data?.brands?.find(({ _id }) => _id === product?.brand?.id))
      setBrandCheck("Pass");
    else setBrandCheck("Fail");
  };
  const { tenant } = useApp();
  let {
    data: category,
    refetch: getCat,
    isLoading: fetchingCategory,
  } = useApiQuery(CategoryApiConfig.GET_CATEGORY_BY_ID, {
    pathParams: {
      id: categories.childCatL2?.value
        ? categories.childCatL2?.value
        : categories.childCatL1?.value
        ? categories.childCatL1?.value
        : categories.parentCat?.value,
      tenant,
    },
    successCb: checkBrand,
  });
  const { data: searchedProducts = [], refetch: getSearchedProduct } =
    useApiQuery(ProductApiConfig.SEARCH_PRODUCTS, {
      params: { text: asn },
      pathParams: { tenant: "MASTER" },
      dataParser: (data) => makeProductsSelectionList(data),
    });
  let {
    data: product,
    refetch: getProduct,
    isLoading: getProductLoader,
  } = useApiQuery(ProductApiConfig.GET_PRODUCT_BY_ID, {
    pathParams: { productId: selectedProduct, tenant: "MASTER" },
    errorCb: handleError,
  });
  const reset = () => {
    getProduct();
    category = null;
    setCategories({
      childCatL2: {},
      childCatL1: {},
      parentCat: {},
    });
    setBrandCheck("");
    setShouldDeleteAttributes(false);
  };
  const { mutate: editSubcategory, isLoading } = useApiMutate(
    CategoryApiConfig.EDIT_SUB_CATEGORY,
    {
      pathParams: {
        productId: product?._id,
        shouldDeleteAttributes,
      },
      successCb: reset,
      errorCb: handleError,
    }
  );
  useEffect(() => {
    if (asn.length) getSearchedProduct();
  }, [asn]);

  return (
    <div className="p-20">
      <Title title="Change Product Category" />
      <div className="flex justify-start gap-x-20 px-20 py-30">
        <div className="w-[30%]">
          <Autocomplete
            selected={selectedProduct}
            onSearch={debounce((value) => {
              setAsn(value);
            }, 300)}
            onChange={({ value }) => {
              setSelectedProduct(value);
            }}
            placeholder="Search ASN or Model name to edit..."
            list={searchedProducts}
            apiSearch
            searchedValue={asn}
          />
        </div>
        <Button
          text={"Submit"}
          onButtonClick={getProduct}
          btnClass="bg-btn"
          loadingText="Fetching..."
          disabled={getProductLoader}
          loading={getProductLoader}
        />
      </div>
      {product ? (
        <div className="p-20 flex flex-col justify-start gap-y-40 text-textColor">
          <div className="columns-xs text-18">
            <div className="font-bold text-left">Category:</div>
            <div>{product?.categories?.[0]?.name}</div>
          </div>
          <div className="columns-xs text-18">
            <div className="font-bold text-left">Brand:</div>
            <div>{product?.brand?.name}</div>
          </div>
          <div className="flex items-center gap-x-300">
            <div className="font-bold text-18 text-left w-[10%]">
              New Category:
            </div>
            <div className="w-[90%]">
              <ProductCategory
                categories={categories}
                updateCategories={setCategories}
                fetchCat={getCat}
                fetchingCategory={fetchingCategory}
              />
            </div>
          </div>
          <div className="columns-xs text-18 font-bold">
            <div className="text-left">Brand Check:</div>
            <div
              className={classNames(
                brandCheck === "Pass"
                  ? "text-green-500"
                  : brandCheck === "Fail"
                  ? "text-red"
                  : ""
              )}
            >
              {brandCheck}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <label className="font-bold text-18 text-left">
              Delete Attributes
            </label>
            <Checkbox
              onChange={({ target: { checked } }) =>
                setShouldDeleteAttributes(checked)
              }
              checked={shouldDeleteAttributes}
            />
          </div>
          <div>
            <Button
              text={"Submit"}
              onButtonClick={() =>
                editSubcategory({
                  category: category?._id,
                })
              }
              btnClass={"bg-btn flex justify-start"}
              loadingText={"Updating..."}
              disabled={isLoading || brandCheck === "Fail" || !category}
              loading={isLoading}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
