export const CommonApiConfig: any = {
  GET_TENANTS_BY_OFFERING: {
    url: "/offering/1/tenant",
    service: "AUTH_CATALOGUE",
  },
  GET_ROLES_BY_OFFERING: {
    url: "/offering/1/role",
    service: "AUTH_CATALOGUE",
  },
  ADD_TENANT: {
    url: "/tenants",
    method: "post",
    service: "AUTH_CATALOGUE",
  },
};
