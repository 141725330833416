import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { CATALOGUE_OFFERING_ID } from "constants/commonConstants";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { useApp } from "context/appContext";

export const getStorageData = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const getTokenData = () => {
  const { accessToken } = getStorageData("token");
  const userData = jwt_decode(accessToken);
  return userData;
};

export const setStorageData = (key: string, value: any) => {
  value && localStorage.setItem(key, JSON.stringify(value));
};

export const handleError = (msg: any) => {
  toast.error(msg ?? "Oops! That didn't work", {
    autoClose: 10000,
    theme: "light",
  });
};

export const makeList = (data: any) => {
  return data.map(({ id, name }) => ({ label: name, value: id }));
};
export const makeProductsSelectionList = (data: any) => {
  return data.map(({ _id, modelName, asn }) => ({
    label: `${asn} (${modelName})`,
    value: _id,
  }));
};

export const createSelectList = (
  data: any,
  labelKey?: string,
  valueKey?: string
) => {
  return data.map((item) => {
    return {
      label: labelKey ? item[labelKey] : item,
      value: valueKey ? item[valueKey] : item,
    };
  });
};

export const validateNumber = (text) => {
  const rgx = /(^[0-9]+$|^$)/;
  return rgx.test(text);
};

export const buildPermissions = ({ permissions = [] }) => {
  const [roles, resourceAccessDetails] = permissions.reduce(
    (
      [allRoles, allResourceAccessDetails],
      { roleName, resourceAccessDetails }
    ) => {
      allRoles = [...allRoles, roleName];
      allResourceAccessDetails = [
        ...allResourceAccessDetails,
        ...resourceAccessDetails,
      ];
      return [allRoles, allResourceAccessDetails];
    },
    [[], []]
  );

  return resourceAccessDetails.reduce(
    (permissions, { resourceName, accessType }) => {
      permissions[resourceName] = {
        ...(permissions[resourceName] ?? {}),
        [accessType]: true,
      };
      return permissions;
    },
    { roles }
  );
};

export const getPageLabel = (page) => {
  const pageLabels = {
    attributes: "Attribute",
    brands: "Brand",
    categories: "Category",
    products: "Product",
    "bulk-update": "Bulk Upload",
    administration: "Administration",
    settings: "Settings",
    "product-creator": "Product Creator Page",
    "product-approver": "Product Approver Page",
    "product-bulk-upload": "Product",
    variants: "Product",
  };
  return pageLabels[page];
};

export const getDefaultTenant = () => {
  const { tenantDetails = [] } = getStorageData("userDetails") ?? {};
  const { defaultTenantId = "", tenants = [] } =
    tenantDetails?.find(
      ({ offeringId }) => offeringId === CATALOGUE_OFFERING_ID
    ) ?? {};
  return tenants.find(({ id }) => id === defaultTenantId)?.name;
};

export const getTenants = () => {
  const { tenantDetails = [] } = getStorageData("userDetails") ?? {};
  const tenants = tenantDetails?.find(
    ({ offeringId }) => offeringId === CATALOGUE_OFFERING_ID
  )?.tenants;
  if (tenants?.length) {
    const tenantsList = createSelectList(tenants, "name", "name");
    return tenantsList;
  }
  return [];
};

export const replaceImageBaseUrl = (imgUrl) => {
  return imgUrl
    ? imgUrl.includes("static.gostor.com")
      ? imgUrl
      : imgUrl?.replace(
          imgUrl.substring(0, imgUrl.indexOf(".com") + 4),
          process.env.REACT_APP_IMAGE_BASE_URL
        )
    : null;
};

export const disabledDate = (current) => {
  // Cannot select days after today and before 30 days
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return (
    current && (current.valueOf() > Date.now() || current.valueOf() < date)
  );
};

export const downloadFile = (data: any, fileNameWithExtension: string) => {
  saveAs(new Blob([data]), fileNameWithExtension);
};

export function buildValidArray(arr = []) {
  const validArr = [];
  for (const e of arr) {
    if (e !== null && e !== "") validArr.push(e);
  }
  return validArr;
}

export function createArrayWithValues(length) {
  return Array.from({ length }, (_, i) => i);
}

export function generateZIP(links, zipFilename) {
  let zip = new JSZip();
  let count = 0;
  links.forEach(function ({ url, fileName }) {
    const extension = url?.split(".").pop();
    url += `?t=${performance.now()}`;
    fileName = `${fileName}.${extension}`;
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        throw err; // or handle the error
      }
      zip.file(fileName, data, { binary: true });
      count++;
      if (count === links.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${zipFilename}.zip`);
        });
      }
    });
  });
}

export function hasCategorySequencingAccess() {
  return process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_USER_FOR_CATEGORY_SEQUENCING.split(",").includes(
        getStorageData("userDetails")?.email
      )
    : true;
}

export function IsCategorySequencingAccessable() {
  const { isCategorySequencingEnabled } = useApp();
  return process.env.REACT_APP_ENV === "production"
    ? hasCategorySequencingAccess() && isCategorySequencingEnabled
    : true;
}

export function hasUpdateFiltersAccess() {
  return process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_USERS_FOR_UPDATING_FILTERS.split(",").includes(
        getStorageData("userDetails")?.uId
      )
    : true;
}
