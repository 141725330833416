/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, message } from "antd";
import { CategoryApiConfig } from "api/configs/categoryConfig";
import { ProductApiConfig } from "api/configs/productApiConfig";
import { VariantApiConfig } from "api/configs/variantApiConfig";
import ProductCategory from "components/features/products/ProductCategory";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import { useApp } from "context/appContext";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
  createSelectList,
  handleError,
  makeProductsSelectionList,
} from "utils/helper";
import { DeleteOutlined } from "@ant-design/icons";
import { VariantAttributes } from "./VariantAttributes";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function AddVariants() {
  const [categories, setCategories]: any = useState({
    childCatL2: {},
    childCatL1: {},
    parentCat: {},
  });
  const { tenant } = useApp();
  const [attributesMetaData, setAttributesMetaData] = useState([]);
  const [categoryBrandsList, setCategoryBrands] = useState([]);
  const [searchedBrand, setSearchedBrand] = useState("");
  const [searchedAttribute, setSearchedAttribute] = useState("");
  const [searchedAsn, setSearchedAsn] = useState("");
  const [variantData, setVariantData]: any = useState({});
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [isAttributesModalOpen, setIsAttributesModalOpen] = useState(false);
  const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);
  const { refetch: getSearchedProducts } = useApiQuery(
    ProductApiConfig.SEARCH_PRODUCTS,
    {
      pathParams: { tenant },
      params: { text: searchedAsn },
      dataParser: makeProductsSelectionList,
      successCb: setSearchedProducts,
      errorCb: () => setSearchedProducts([]),
    }
  );
  const {
    data: category,
    refetch: getCat,
    isFetching: fetchingCategory,
  } = useApiQuery(CategoryApiConfig.GET_CATEGORY_BY_ID, {
    pathParams: {
      id: categories.childCatL2?.value
        ? categories.childCatL2?.value
        : categories.childCatL1?.value
        ? categories.childCatL1?.value
        : categories.parentCat?.value,
      tenant,
    },
    successCb: (data) => {
      const { _id: id, name, brands, attributes } = data;
      setVariantData((prev) => ({
        ...prev,
        category: { id, name },
      }));
      setAttributesMetaData(
        createSelectList(
          attributes?.filter(({ isMandatory }) => isMandatory),
          "name",
          "_id"
        )
      );
      setCategoryBrands(createSelectList(brands, "name", "_id"));
    },
  });
  const { refetch: getProduct } = useApiQuery(
    ProductApiConfig.GET_PRODUCT_BY_ID,
    {
      pathParams: { productId: selectedProductId, tenant },
      successCb: handleProduct,
    }
  );

  useEffect(() => {
    if (selectedProductId) getProduct();
  }, [selectedProductId]);

  useEffect(() => {
    if (searchedAsn.length > 2) getSearchedProducts();
  }, [searchedAsn]);

  function isProductEligible(productAttributes) {
    return variantData.attributes?.every(({ id }) =>
      productAttributes?.find(
        ({ k, v }) => k === id && ![null, undefined, ""].includes(v)
      )
    );
  }

  function handleProduct(product) {
    const { _id: id, displayName, attributes, variant, asn } = product;
    if (variant) {
      message.error("Product already has variants");
      return;
    }
    if (!isProductEligible(attributes)) {
      message.error("Product doesn't have the selected attribute values");
      return;
    }
    if (!variantData.variants?.find((product) => product.id === id))
      setVariantData((prev) => ({
        ...prev,
        variants: [...(prev.variants ?? []), { id, displayName, asn }],
      }));
    message.success(`Added product: ${displayName} successfully`);
  }

  const { mutate: createVariants, isLoading: isCreating } = useApiMutate(
    VariantApiConfig.CREATE_VARIANTS,
    {
      successCb: () => {
        setVariantData({});
      },
      errorCb: handleError,
    }
  );

  function removeAttribute(attributeId) {
    setVariantData((prev) => {
      const attributes = prev.attributes.filter(({ id }) => id !== attributeId);
      return { ...prev, attributes };
    });
  }

  function removeProduct(productId) {
    setVariantData((prev) => {
      const variants = prev.variants.filter(({ id }) => id !== productId);
      return { ...prev, variants };
    });
  }

  function create() {
    if (!variantData?.brand) {
      message.error("Please select brand");
      return;
    }
    if (!variantData?.name?.trim()) {
      message.error("Please provide a name");
      return;
    }
    if (!variantData?.attributes?.length) {
      message.error("Please select attributes");
      return;
    }
    if (!variantData?.variants?.length) {
      message.error("Please select products");
      return;
    }
    createVariants(variantData);
  }

  return (
    <div>
      <ProductCategory
        categories={categories}
        updateCategories={setCategories}
        fetchCat={getCat}
        fetchingCategory={fetchingCategory}
      />
      {!!category && (
        <div className="flex flex-col gap-y-40 pt-40">
          <div className="flex items-center gap-x-60">
            <label className="w-[6%] font-bold text-textColor">
              <span className="text-red pr-5">*</span>
              Brand
            </label>
            <div className="w-[30%]">
              <Autocomplete
                selected={variantData.brand?.id}
                onChange={({ label: name, value: id }) => {
                  setVariantData((prev) => ({
                    ...prev,
                    brand: { id, name },
                    name: `${name} ${variantData?.category?.name}`,
                  }));
                }}
                placeholder="Search brand"
                list={categoryBrandsList}
                searchedValue={searchedBrand}
                onSearch={(v) => setSearchedBrand(v)}
              />
            </div>
          </div>
          <div className="flex items-center gap-x-60">
            <label className="w-[6%] font-bold text-textColor">
              <span className="text-red pr-5">*</span>
              Name
            </label>
            <div className="w-[37%]">
              <Input
                inputType={"text"}
                value={variantData.name ?? ""}
                name={"name"}
                onChange={({ target: { value } }) => {
                  if (value.indexOf(variantData?.brand?.name) !== 0)
                    value = `${variantData?.brand?.name}`;
                  setVariantData((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                mandatory
                disabled={!variantData?.brand?.name}
              />
            </div>
          </div>
          <div className="flex items-center gap-x-60">
            <label className="w-[6%] font-bold text-textColor">
              <span className="text-red pr-5">*</span>
              Attributes
            </label>
            <div className="w-[30%]">
              <Autocomplete
                selected={
                  variantData.attributes?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })) ?? []
                }
                onChange={(x) => {
                  if (variantData.attributes?.length === 3) {
                    message.info("Only 3 attributes can be added");
                    return;
                  }
                  const { value: id, label: name } = x[x?.length - 1];
                  if (
                    !variantData?.attributes?.find(
                      ({ id: attrId }) => attrId === id
                    )
                  ) {
                    const attributes = [
                      ...(variantData?.attributes ?? []),
                      { id, name },
                    ];
                    setVariantData((prev) => ({
                      ...prev,
                      attributes,
                      variants: [],
                    }));
                    message.success(`Added attribute: ${name} successfully`);
                  }
                }}
                placeholder="Search attribute"
                list={attributesMetaData}
                searchedValue={searchedAttribute}
                onSearch={(v) => setSearchedAttribute(v)}
                multiselect={true}
              />
            </div>
            <div>
              {variantData?.attributes?.length > 0 && (
                <Button
                  text={"View attributes"}
                  onButtonClick={() => setIsAttributesModalOpen(true)}
                  btnClass={"bg-btn"}
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-60">
            <label className="w-[6%] font-bold text-textColor">
              Pack variants
            </label>
            <Checkbox
              checked={variantData?.pack}
              onChange={({ target: { checked } }) => {
                setVariantData({ ...variantData, pack: checked });
              }}
              autoFocus
            >
              {variantData?.pack ? "Enabled" : "Disabled"}
            </Checkbox>
          </div>
          <div className="flex items-center gap-x-60">
            <label className="w-[6%] font-bold text-textColor">
              <span className="text-red pr-5">*</span>
              Products
            </label>
            <div className="w-[30%]">
              <Autocomplete
                selected={
                  variantData.variants?.map(({ id, asn }) => ({
                    label: asn,
                    value: id,
                  })) ?? []
                }
                onSearch={debounce((value) => {
                  setSearchedAsn(value);
                }, 300)}
                onChange={(x) => {
                  if (!variantData.attributes.length) {
                    message.info("Please select attributes first");
                    return;
                  }
                  const { value: productId } = x[x.length - 1];
                  setSelectedProductId(productId);
                }}
                placeholder="Search ASN or Model name"
                list={searchedProducts}
                apiSearch
                searchedValue={searchedAsn}
                multiselect={true}
              />
            </div>
            <div>
              {variantData?.variants?.length > 0 && (
                <Button
                  text={"View products"}
                  onButtonClick={() => setIsProductsModalOpen(true)}
                  btnClass={"bg-btn"}
                />
              )}
            </div>
          </div>
          <div className="flex">
            <Button
              text={"Create"}
              onButtonClick={create}
              btnClass={"bg-btn"}
              disabled={isCreating}
              loading={isCreating}
              loadingText="Creating..."
            />
          </div>
          <Modal
            title="Selected attributes"
            open={isAttributesModalOpen}
            onOk={() => setIsAttributesModalOpen(false)}
            onCancel={() => setIsAttributesModalOpen(false)}
          >
            <VariantAttributes
              cards={variantData?.attributes}
              setCards={(attributes) =>
                setVariantData({ ...variantData, attributes })
              }
              removeAttribute={removeAttribute}
            />
          </Modal>
          <Modal
            title="Selected products"
            open={isProductsModalOpen}
            onOk={() => setIsProductsModalOpen(false)}
            onCancel={() => setIsProductsModalOpen(false)}
          >
            {variantData?.variants?.map((x) => (
              <div
                className="font-bold bg-slate-300 rounded-md flex justify-between items-center p-3 mb-15"
                key={x.id}
              >
                <span className="p-5">{x.displayName}</span>
                <DeleteOutlined
                  className="p-3 cursor-pointer"
                  onClick={() => removeProduct(x.id)}
                />
              </div>
            ))}
          </Modal>
        </div>
      )}
    </div>
  );
}
