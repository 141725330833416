/* eslint-disable react-hooks/exhaustive-deps */
import Input from "../../ui/Input";
import TextArea from "../../ui/TextArea";
import Autocomplete from "components/ui/Autocomplete";
import SelectList from "components/ui/SelectList";
import Checkbox from "components/ui/Checkbox";
import {
  APPROVED_STATUS,
  DRAFT_STATUS,
  infoFields,
  PENDING_STATUS,
  productTags,
} from "constants/productConstants";
import { useState } from "react";
import usePermissions from "hooks/usePermissions";
import {
  MASTER_TENANT,
  PRODUCT,
  PRODUCT_CREATOR_PAGE,
} from "constants/commonConstants";
import { useApp } from "context/appContext";
import { useSearchParams } from "react-router-dom";
import { createSelectList } from "utils/helper";

export default function ProductInfo({
  info,
  updateInfo,
  isEdit = false,
  categoryBrandsList,
  setDisplayName,
  categoryTenantsList = [],
}) {
  const [searchedBrand, setSearchedBrand] = useState("");
  const { canRead, canUpdate, canAccess } = usePermissions();
  const cannotEdit = canRead(PRODUCT) && !canUpdate(PRODUCT);
  const nonEditableInfo = ["moq", "maxQuantity", "packQuantity"];
  const { tenant, isProductApprovalEnabled } = useApp();
  const isMaster = tenant === MASTER_TENANT;
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from");
  const isPendingProduct = () => {
    return (
      fromPage !== "approver" &&
      (info.status === PENDING_STATUS ||
        (info.status === DRAFT_STATUS && !canAccess(PRODUCT_CREATOR_PAGE)))
    );
  };
  const masterEditableFields = [
    "modelName",
    "flipkartModelName",
    "flipkartUrl",
    "genericName",
    "countryOfOrigin",
    "manufacturerDetails",
    "importerDetails",
    "packerDetails"
  ];

  function updateTenantsListByPackQty() {
    return Number(info.packQuantity) !== 1
      ? categoryTenantsList.filter(({ value }) => value !== "GOSTOR")
      : categoryTenantsList;
  }

  const elements = {
    input: ({ name, valueType, label, isMandatory }) => (
      <div className="flex">
        <Input
          inputType={valueType}
          value={info[name] ?? ""}
          name={name}
          onChange={({ target: { value } }) => {
            if (name === "modelName") setDisplayName("");
            updateInfo({
              ...info,
              [name]: value,
            });
          }}
          label={label}
          mandatory={isMandatory}
          disabled={
            isEdit &&
            (isProductApprovalEnabled &&
            info.status &&
            info.status !== APPROVED_STATUS
              ? isPendingProduct()
              : cannotEdit ||
                nonEditableInfo.includes(name) ||
                (isMaster
                  ? !masterEditableFields.includes(name)
                  : masterEditableFields.includes(name)))
          }
        />
      </div>
    ),
    selectList: ({ name, label, isMandatory }) => (
      <div className="flex w-[90%] justify-start items-center text-textColor">
        {label && (
          <label className="w-[20%] text-right pr-20 font-bold">
            {isMandatory ? <span className="text-red pr-5">*</span> : null}
            {label}
          </label>
        )}
        <>
          <SelectList
            list={
              name === "tenants"
                ? updateTenantsListByPackQty()
                : createSelectList(productTags)
            }
            onChange={(value) =>
              value && updateInfo({ ...info, [name]: value })
            }
            selected={info[name] ?? []}
            placeholder="Select"
            multiselect={name === "tenants"}
            mandatory={isMandatory}
            disabled={
              (name === "tenants" && !info.packQuantity) ||
              (isEdit &&
                (isProductApprovalEnabled &&
                info.status &&
                info.status !== APPROVED_STATUS
                  ? isPendingProduct()
                  : cannotEdit))
            }
          />
        </>
      </div>
    ),
    checkbox: ({ name, label }) => (
      <div>
        <Checkbox
          onChange={({ target: { checked } }) =>
            updateInfo({ ...info, [name]: checked })
          }
          name={name}
          label={label}
          checked={info[name]}
          disabled={
            isEdit &&
            (isProductApprovalEnabled &&
            info.status &&
            info.status !== APPROVED_STATUS
              ? isPendingProduct()
              : cannotEdit || isMaster)
          }
        />
      </div>
    ),
    textArea: ({ name, label, isMandatory }) => (
      <TextArea
        name={name}
        value={info[name] ?? ""}
        onChange={({ target: { value } }) =>
          updateInfo({ ...info, [name]: value })
        }
        label={label}
        mandatory={isMandatory}
        disabled={
          isEdit &&
          (isProductApprovalEnabled &&
          info.status &&
          info.status !== APPROVED_STATUS
            ? isPendingProduct()
            : cannotEdit || !isMaster)
        }
      />
    ),
    autocomplete: ({ name, label, isMandatory }) => (
      <div className="flex justify-start w-[90%] items-center">
        <label className="w-[20%] text-right pr-20 font-bold text-textColor">
          {isMandatory ? <span className="text-red pr-5">*</span> : null}
          {label}
        </label>
        <div className="w-[70%]">
          <Autocomplete
            selected={isEdit ? info[name]?.id : info[name]}
            onChange={({ label, value }) => {
              if (name === "brand") setDisplayName("");
              updateInfo({
                ...info,
                [name]: isEdit ? { name: label, id: value } : value,
              });
              setSearchedBrand("");
            }}
            placeholder="Search brands"
            list={categoryBrandsList}
            mandatory={isMandatory}
            searchedValue={searchedBrand}
            onSearch={(v) => setSearchedBrand(v)}
            disabled={
              isEdit &&
              (isProductApprovalEnabled &&
              info.status &&
              info.status !== APPROVED_STATUS
                ? isPendingProduct()
                : cannotEdit || !isMaster)
            }
          />
        </div>
      </div>
    ),
  };

  const fieldConditions = {
    tenants: isMaster,
    active:
      isEdit && isProductApprovalEnabled
        ? !info.status || info.status === APPROVED_STATUS
        : !isProductApprovalEnabled,
    flipkartModelName: isMaster,
    flipkartUrl: isMaster,
  };

  const checkFieldConditions = (name) => {
    return fieldConditions.hasOwnProperty(name) ? fieldConditions[name] : true;
  };

  return (
    <div className="bg-white p-30">
      <div className="grid grid-cols-1 gap-y-20">
        {infoFields.map(
          ({ type, ...rest }, i) =>
            checkFieldConditions(rest.name) && (
              <div key={i}>{elements[type]({ ...rest })}</div>
            )
        )}
      </div>
    </div>
  );
}
