import { ProductApiConfig } from "api/configs/productApiConfig";
import ProductCategory from "components/features/products/ProductCategory";
import Button from "components/ui/Button";
import FileUpload from "components/ui/FileUpload";
import Modal from "components/ui/Modal";
import ProgressBar from "components/ui/ProgressBar";
import Title from "components/ui/Title";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import { useState } from "react";
import { toast } from "react-toastify";
import { downloadFile, handleError } from "utils/helper";
import Table from "components/ui/Table";
import Tabs from "components/ui/Tabs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Input from "components/ui/Input";
import { useApp } from "context/appContext";
import { PRODUCT } from "constants/commonConstants";
import usePermissions from "hooks/usePermissions";

export default function ProductBulkUpload() {
  const [categories, setCategories]: any = useState({
    childCatL2: {},
    childCatL1: {},
    parentCat: {},
  });
  const [file, setFile] = useState<any>();
  const [imagesFile, setImagesFile] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [imagesLoader, setImagesLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showUrlPopup, setShowUrlPopup] = useState(false);
  const [w, setW] = useState(0);
  const [selectedTab, setSelectedTab] = useState("images");
  const [copiedImagesUrl, setCopiedImagesUrl] = useState(false);
  const [imagesUrl, setImagesUrl] = useState("");
  let i = 0;
  const getCatName = () => {
    return categories.childCatL2?.value
      ? categories.childCatL2?.label
      : categories.childCatL1?.value
      ? categories.childCatL1?.label
      : categories.parentCat?.label;
  };
  const { refetch: getFileTemplate, isLoading } = useApiQuery(
    ProductApiConfig.PRODUCT_BULK_UPLOAD_FILE,
    {
      pathParams: {
        categoryId: categories.childCatL2?.value
          ? categories.childCatL2?.value
          : categories.childCatL1?.value
          ? categories.childCatL1?.value
          : categories.parentCat?.value,
      },
      successCb: (data) => {
        downloadFile(data, `${getCatName()}.xlsx`);
      },
      errorCb: handleError,
    }
  );

  const { mutate: uploadFile, data: uploadRes } = useApiMutate(
    ProductApiConfig.PRODUCT_BULK_UPLOAD,
    {
      successCb: (data) => {
        setLoader(false);
        setW(0);
        i = 0;
        toast.success("Uploaded successfully!", {
          autoClose: 10000,
          theme: "light",
        });
        setFile("");
        downloadFile(data, "Result.xlsx");
      },
      errorCb: (err) => {
        setLoader(false);
        setW(0);
        i = 0;
        handleError(err);
      },
    }
  );

  const { mutate: imageFileUpload, data: imagesRes } = useApiMutate(
    ProductApiConfig.IMAGES_FILE_UPLOAD,
    {
      successCb: () => {
        setTimeout(() => {
          setImagesLoader(false);
          setW(0);
          i = 0;
          toast.success("Uploaded successfully!", {
            autoClose: 10000,
            theme: "light",
          });
          setImagesFile("");
          setShowUrlPopup(true);
        }, 3000);
      },
      errorCb: (err) => {
        setTimeout(() => {
          setImagesLoader(false);
          setW(0);
          i = 0;
          handleError(err);
        }, 3000);
      },
    }
  );

  const { tenant } = useApp();

  const { mutate: updateImages, isLoading: updateImagesLoader } = useApiMutate(
    ProductApiConfig.IMAGES_BULK_UPDATE,
    {
      pathParams: { tenant },
      params: { url: imagesUrl?.trim() },
      successCb: (data) => {
        downloadFile(data, "Result.xlsx");
        setImagesUrl("");
      },
      errorCb: handleError,
    }
  );

  function makeProgress() {
    if (i < 100) {
      i++;
      setW(i);
    }
    setTimeout(() => {
      makeProgress();
    }, 50);
  }

  const handleBulkFileSubmit = async () => {
    setLoader(true);
    makeProgress();
    const data = new FormData();
    data.append("file", file);
    uploadFile(data);
  };

  const handleImagesFileSubmit = async () => {
    setImagesLoader(true);
    makeProgress();
    const data = new FormData();
    data.append("file", imagesFile);
    imageFileUpload(data);
  };

  const { canCreate } = usePermissions();

  return (
    <div className="p-20">
      <Title title="Product Bulk Upload" />
      <div className="p-20 flex flex-col gap-y-30">
        <Tabs
          list={[
            { label: "Image File Upload", value: "images" },
            ...(canCreate(PRODUCT)
              ? [
                  { label: "Get Template File", value: "template" },
                  { label: "Bulk Upload", value: "upload" },
                ]
              : []),
            { label: "Images Bulk Update", value: "imagesUpdate" },
          ]}
          selectedTab={selectedTab}
          onClick={setSelectedTab}
        />
        {selectedTab === "template" && (
          <div className="py-20">
            <ProductCategory
              categories={categories}
              updateCategories={setCategories}
              fetchCat={getFileTemplate}
              fetchingCategory={isLoading}
            />
          </div>
        )}
        {selectedTab === "upload" && (
          <>
            <div className="w-1/2 m-auto">
              <FileUpload
                onChange={({ target: { files } }) => setFile(files[0])}
                fileName={file?.name}
                accept={".xlsx"}
              />
              {loader ? (
                <div className="py-20">
                  <ProgressBar w={w} />
                </div>
              ) : (
                <div>
                  <Button
                    text={"Submit"}
                    onButtonClick={handleBulkFileSubmit}
                    btnClass={"bg-btn mt-30"}
                    disabled={!file || loader}
                  />
                </div>
              )}
            </div>
            <Modal
              isOpen={showPopup}
              closeModal={() => setShowPopup(false)}
              title={"Failed Products"}
              onSave={() => setShowPopup(false)}
              saveBtnTxt={"OK"}
            >
              {uploadRes?.errorList?.length && (
                <Table
                  columns={[
                    { Header: "Model Name", accessor: "modelName" },
                    { Header: "Reason", accessor: "reason" },
                  ]}
                  data={uploadRes?.errorList}
                  loading={false}
                  hasPagination={false}
                />
              )}
            </Modal>
          </>
        )}
        {selectedTab === "images" && (
          <>
            <Modal
              isOpen={showUrlPopup}
              closeModal={() => setShowUrlPopup(false)}
              title={""}
              onSave={() => setShowUrlPopup(false)}
              saveBtnTxt={"OK"}
            >
              <div className="flex justify-between items-center">
                <a
                  href={imagesRes?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-16"
                >
                  Images Url
                </a>
                <CopyToClipboard
                  text={imagesRes?.url}
                  onCopy={() => {
                    setCopiedImagesUrl(true);
                    setTimeout(() => {
                      setCopiedImagesUrl(false);
                    }, 1000);
                  }}
                  className="text-blue-400"
                >
                  <button>
                    {copiedImagesUrl ? (
                      <span className="text-green-500 no-underline text-16">
                        Copied!
                      </span>
                    ) : (
                      <span className="text-16">Copy</span>
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </Modal>
            <div className="w-1/2 m-auto">
              <FileUpload
                onChange={({ target: { files } }) => setImagesFile(files[0])}
                fileName={imagesFile?.name}
                accept={".zip"}
              />
              {imagesLoader ? (
                <div className="py-20">
                  <ProgressBar w={w} />
                </div>
              ) : (
                <div>
                  <Button
                    text={"Submit"}
                    onButtonClick={handleImagesFileSubmit}
                    btnClass={"bg-btn mt-30"}
                    disabled={!imagesFile || imagesLoader}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {selectedTab === "imagesUpdate" && (
          <div className="flex justify-between w-[50%]">
            <Input
              inputType={"text"}
              value={imagesUrl}
              name={"imagesUrl"}
              onChange={({ target: { value } }) => setImagesUrl(value)}
              placeholder={"Enter or paste URL"}
            />
            <Button
              text={"Update"}
              onButtonClick={() => updateImages({})}
              btnClass={"bg-btn"}
              disabled={!imagesUrl || updateImagesLoader}
              loading={updateImagesLoader}
              loadingText={"Updating"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
