import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import Input from "components/ui/Input";
import SelectList from "components/ui/SelectList";
import {
  attributeTypes,
  tenantsList,
  valueTypes,
} from "constants/categoryConstants";
import { CATEGORY } from "constants/commonConstants";
import useLists from "hooks/useLists";
import usePermissions from "hooks/usePermissions";
import { debounce } from "lodash";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

export default function AttributesTable({
  attributes,
  handleAttributeData,
  fetchAttrs,
  attributesList,
  searchedAttribute,
  setCurrAttr,
  setShowOptions,
  setShowFilterOptions,
  addAttr,
  isEditCategory = false,
  removeAttr = null,
}) {
  const columnHeaders = [
    { header: "Attribute", isMandatory: true },
    { header: "Type", isMandatory: true },
    { header: "Priority", isMandatory: true },
    { header: "Value Type", isMandatory: true },
    { header: "Filter Options", isMandatory: false },
    { header: "Mandatory", isMandatory: false },
    { header: "Filter Priority", isMandatory: false },
    { header: "Tags", isMandatory: true },
    { header: "Add", isMandatory: false },
  ];
  const { canUpdate, canCreate } = usePermissions();
  const { tenants } = useLists();
  function checkPermissions() {
    return isEditCategory ? !canUpdate(CATEGORY) : !canCreate(CATEGORY);
  }
  return (
    <div className="overflow-x-auto rounded min-h-[25vh]">
      <table className="min-w-max table-auto bg-white border">
        <thead>
          <tr className="bg-secondary bg-opacity-5 text-14 font-medium h-40">
            {columnHeaders.map(({ header, isMandatory }) => (
              <th className="px-18 text-left items-center">
                {isMandatory && <span className="text-red pr-5">*</span>}
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {attributes.map((attribute, index) => {
            return (
              <tr
                key={index}
                className="border-none text-black text-12 font-normal not-italic leading-17 h-40 even:bg-secondary even:bg-opacity-5 odd:bg-white"
              >
                <td className="p-10">
                  <Autocomplete
                    selected={attribute._id}
                    onSearch={debounce((value) => {
                      fetchAttrs(value, index);
                    }, 300)}
                    onChange={(value) => {
                      handleAttributeData(index, { name: "_id", value });
                    }}
                    placeholder="Search attributes..."
                    list={
                      attributesList.length && searchedAttribute.index === index
                        ? attributesList
                        : [{ label: attribute.name, value: attribute._id }]
                    }
                    apiSearch
                    searchedValue={
                      searchedAttribute.index === index
                        ? searchedAttribute.text
                        : ""
                    }
                    disabled={checkPermissions()}
                  />
                </td>
                <td className="p-10">
                  <SelectList
                    placeholder="Select type"
                    list={attributeTypes}
                    onChange={(value) => {
                      handleAttributeData(index, { name: "type", value });
                    }}
                    selected={attribute.type}
                    name="type"
                    disabled={checkPermissions()}
                  />
                </td>
                <td className="p-10">
                  <Input
                    inputType={"number"}
                    value={attribute.attributePriority}
                    name={"attributePriority"}
                    onChange={({ target: { value } }) => {
                      handleAttributeData(index, {
                        name: "attributePriority",
                        value: value ? Number(value) : null,
                      });
                    }}
                    disabled={checkPermissions()}
                    placeholder="Priority"
                  />
                </td>
                <td className="p-10">
                  <div className="flex justify-between relative">
                    <SelectList
                      placeholder="Select type"
                      list={valueTypes}
                      onChange={(value) => {
                        handleAttributeData(index, {
                          name: "valueType",
                          value,
                        });
                      }}
                      selected={
                        attribute.valueType === "integer"
                          ? "number"
                          : attribute.valueType
                      }
                      disabled={checkPermissions()}
                    />
                    <Button
                      text={
                        attribute.options?.[0]?.length
                          ? "View options"
                          : "Add options"
                      }
                      onButtonClick={() => {
                        setCurrAttr(index);
                        setShowOptions(true);
                      }}
                      btnClass={"bg-btn ml-20"}
                      disabled={checkPermissions()}
                    />
                  </div>
                </td>
                <td className="p-10">
                  <Button
                    text={
                      attribute?.filterOptions?.[0]?.option?.length
                        ? "View"
                        : "Add"
                    }
                    onButtonClick={() => {
                      setCurrAttr(index);
                      setShowFilterOptions(true);
                    }}
                    btnClass={"bg-btn"}
                    disabled={checkPermissions()}
                  />
                </td>
                <td className="p-10">
                  <Checkbox
                    checked={!!attribute.isMandatory}
                    onChange={({ target: { checked } }) => {
                      handleAttributeData(index, {
                        name: "isMandatory",
                        value: checked,
                      });
                    }}
                    name="isMandatory"
                    disabled={checkPermissions()}
                  />
                </td>
                <td className="p-10">
                  <Input
                    inputType={"number"}
                    value={attribute.filterPriority}
                    name={"filterPriority"}
                    onChange={({ target: { value } }) => {
                      handleAttributeData(index, {
                        name: "filterPriority",
                        value: value ? Number(value) : null,
                      });
                    }}
                    disabled={checkPermissions()}
                    placeholder="Filter Priority"
                  />
                </td>
                <td className="p-10">
                  <div className="relative">
                    <SelectList
                      placeholder="Select tags"
                      list={tenants ?? tenantsList}
                      onChange={(value) => {
                        handleAttributeData(index, { name: "tenants", value });
                      }}
                      selected={attribute.tenants ?? []}
                      multiselect
                      disabled={checkPermissions()}
                    />
                  </div>
                </td>
                <td className="p-10">
                  <div className="flex justify-center gap-x-10">
                    {index === attributes.length - 1 && (
                      <PlusCircleIcon
                        className="w-20 h-20 cursor-pointer"
                        fill="green"
                        onClick={() => !checkPermissions() && addAttr()}
                      />
                    )}
                    {!isEditCategory && attributes.length > 1 && (
                      <XCircleIcon
                        className="w-20 h-20 cursor-pointer"
                        fill="red"
                        onClick={() => removeAttr(index)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
