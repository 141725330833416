import update from "immutability-helper";
import type { FC } from "react";
import { memo, useCallback } from "react";
import { useDrop } from "react-dnd";

import { CategoryCard } from "./CategoryCard";

const style = {
  width: 400,
};

const ItemTypes = {
  CARD: "card",
};

export interface ContainerState {
  cards: any[];
}

export const CategoriesContainer: FC<{ cards; setCards; handleEditChilds }> =
  memo(function CategoriesContainer({ cards, setCards, handleEditChilds }) {
    const findCard = useCallback(
      (id: string) => {
        const card = cards.filter((c) => `${c.id}` === id)[0] as {
          id: number;
          text: string;
          categoryId: string;
          childCategories: any;
        };
        return {
          card,
          index: cards.indexOf(card),
        };
      },
      [cards]
    );

    const moveCard = useCallback(
      (id: string, atIndex: number) => {
        const { card, index } = findCard(id);
        setCards(
          update(cards, {
            $splice: [
              [index, 1],
              [atIndex, 0, card],
            ],
          })
        );
      },
      [findCard, cards, setCards]
    );

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    return (
      <div ref={drop} style={style}>
        {cards.map((card) => (
          <CategoryCard
            key={card.id}
            id={`${card.id}`}
            text={card.text}
            moveCard={moveCard}
            findCard={findCard}
            childCategories={card.childCategories}
            handleEditChilds={handleEditChilds}
          />
        ))}
      </div>
    );
  });
