import classNames from "classnames";
import { useState } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";

export default function Layout({ children }) {
  const [showSideMenu, setShowSideMenu] = useState(false);

  return (
    <div className="App">
      <SideMenu isOpen={showSideMenu} close={() => setShowSideMenu(false)} />
      <div
        className={classNames(
          "transition-[margin-left] duration-100 ease-in",
          showSideMenu && "ml-[18%]"
        )}
      >
        <Header setShowSideMenu={setShowSideMenu} showSideMenu={showSideMenu} />
        {children}
      </div>
    </div>
  );
}
