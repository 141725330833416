/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { AttributeApiConfig } from "api/configs/attributeApiConfig";
import { CategoryApiConfig } from "api/configs/categoryConfig";
import CategoryDetails from "components/features/categories/CategoryDetails";
import DisplayNameLogic from "components/features/categories/DisplayNameLogic";
import Autocomplete from "components/ui/Autocomplete";
import Button from "components/ui/Button";
import SelectList from "components/ui/SelectList";
import Table, { TableColumns } from "components/ui/Table";
import Tabs from "components/ui/Tabs";
import Title from "components/ui/Title";
import { useApiMutate, useApiQuery } from "hooks/useApi";
import useLists from "hooks/useLists";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import AddValueOptions from "components/features/categories/AddValueOptions";
import { buildValidArray, handleError, makeList } from "utils/helper";
import {
  attributesPayload,
  createCategoryBrandsPayload,
  categoryTabs,
  detailsPayload,
  displayNameLogic,
  levels,
} from "constants/categoryConstants";
import { BrandApiConfig } from "api/configs/brandApiConfig";
import FilterOptions from "components/features/categories/FilterOptions";
import AttributesTable from "components/features/categories/AttributesTable";
import { useApp } from "context/appContext";

export default function NewCategory() {
  const [selectedTab, setSelectedTab] = useState("details");
  const [categoryDetails, setCategoryDetails] = useState(detailsPayload);
  const [attributes, setCategoryAttributes] = useState(attributesPayload);
  const [brands, setCategoryBrands] = useState(createCategoryBrandsPayload);
  const [displayNameValues, setDisplayNameValues] = useState([{ d: "" }]);
  const [currAttr, setCurrAttr] = useState(0);
  const { tenants } = useLists();
  const [searchedAttribute, setSearchedAttribute] = useState({
    index: -1,
    text: "",
  });
  const [showOptions, setShowOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [replacements, setReplacements] = useState([]);
  const { mutate: createCategory, isLoading: createCategoryLoader } =
    useApiMutate(CategoryApiConfig.CREATE_CATEGORY, {
      successCb: () => {
        setCategoryDetails(detailsPayload);
        setCategoryAttributes(attributesPayload);
        setCategoryBrands(createCategoryBrandsPayload);
        setDisplayNameValues([{ d: "" }]);
        setReplacements([]);
        setParent("");
      },
      errorCb: handleError,
    });
  const [attributesList, setAttributesList] = useState([]);
  const { refetch } = useApiQuery(AttributeApiConfig.GET_ATTRIBUTE_BY_NAME, {
    params: { name: searchedAttribute?.text },
    dataParser: makeList,
    successCb: (data) => setAttributesList(data),
  });
  const [parent, setParent] = useState("");
  const { tenant } = useApp();
  const { data: category = {}, refetch: getCategory } = useApiQuery(
    CategoryApiConfig.GET_CATEGORY_BY_ID,
    {
      pathParams: { id: parent, tenant },
    }
  );

  useEffect(() => {
    if (searchedAttribute.text.length > 2) refetch();
  }, [searchedAttribute]);

  const addAttr = () => {
    setCategoryAttributes((prev) => [...prev, ...attributesPayload]);
  };
  const removeAttr = (i: number) => {
    setCategoryAttributes((prev) => {
      prev.splice(i, 1);
      return [...prev];
    });
  };
  const handleAttributeData = (i, { name, value }) => {
    setAttributesList([]);
    setSearchedAttribute({ index: -1, text: "" });
    setCategoryAttributes((current) =>
      current.map((obj, idx) => {
        if (i === idx) {
          if (name === "_id") {
            return { ...obj, _id: value?.value, name: value?.label };
          }
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const fetchAttrs = (v, i) => {
    setSearchedAttribute({ text: v, index: i });
  };

  const getDisplayNameLogic = () => {
    const logic = [];
    displayNameValues.forEach(({ d }) => {
      if (d) {
        if (displayNameLogic.hasOwnProperty(d)) logic.push(displayNameLogic[d]);
        else {
          const attributeLogic = replacements.find(
            ({ attributeId }) => attributeId === d
          );
          if (attributeLogic) {
            delete attributeLogic.isBlank;
            delete attributeLogic.isNotBlank;
            logic.push(attributeLogic);
          } else logic.push({ attributeId: d });
        }
      }
    });
    return logic.length ? logic : null;
  };
  function getBrandsPayload() {
    const categoryBrands = brands
      .filter(({ _id }) => _id)
      .map(({ _id, tenants }) => ({
        _id,
        tenants: buildValidArray(tenants),
      }));
    return categoryBrands?.length ? categoryBrands : null;
  }
  function getAttributesPayload() {
    const categoryAttributes = attributes
      .filter(({ _id }) => _id)
      .map(
        ({
          valueType = null,
          options = null,
          filterOptions = null,
          filterPriority = null,
          tenants = null,
          ...rest
        }) => ({
          ...rest,
          valueType: valueType === "number" ? "integer" : valueType,
          options: options?.filter((option) => option),
          filterOptions: filterOptions
            ?.filter(({ option }) => option)
            ?.map(({ option }) => option),
          filterPriority: filterPriority === "" ? null : filterPriority,
          tenants: buildValidArray(tenants),
        })
      );
    return categoryAttributes?.length ? categoryAttributes : null;
  }
  const buildPayload = async () => {
    let { ancestors = [] } = category;
    if (ancestors.length > 1) [, ...ancestors] = ancestors;
    else ancestors = [];
    return {
      ...categoryDetails,
      ancestors,
      level: levels[ancestors?.length],
      brands: getBrandsPayload(),
      attributes: getAttributesPayload(),
      displayNameLogic: getDisplayNameLogic(),
    };
  };

  const create = async () => {
    if (!categoryDetails.name) return alert("Provide category name");
    if (!categoryDetails.hsnCode) return alert("Provide HSN code");
    if (!categoryDetails.gstPercentage) return alert("Provide GST Percentage");
    if (!categoryDetails.tenants) return alert("Provide category tags");

    for (const att of attributes) {
      if (!att._id) return alert("Select attribute");
      else if (!att.type) return alert("Attribute type is mandatory");
      else if ([null, ""].includes(att.attributePriority))
        return alert("Attribute priority is mandatory");
      else if (!att.valueType)
        return alert("Attribute value type is mandatory");
      else if (
        att?.filterPriority !== null &&
        att?.filterPriority !== "" &&
        att?.filterOptions?.filter(({ option }) => option?.length)?.length === 0
      )
        return alert(
          "Filter options is mandatory if filter priority is assigned"
        );
      else if (!att.tenants.length)
        return alert("Attribute tags are mandatory");
    }
    const pl = await buildPayload();
    createCategory(pl);
  };

  const [searchedBrand, setSearchedBrand] = useState({ index: -1, text: "" });
  const [brandsList, setBrandsList] = useState([]);
  const { refetch: getBrands } = useApiQuery(BrandApiConfig.GET_BRAND_BY_NAME, {
    params: { name: searchedBrand.text },
    dataParser: makeList,
    successCb: (data) => setBrandsList(data),
  });
  const add = () => {
    setCategoryBrands((prev) => [...prev, ...createCategoryBrandsPayload]);
  };
  const remove = (i: number) => {
    setCategoryBrands((prev) => prev.filter((_, index) => index !== i));
  };

  useEffect(() => {
    if (searchedBrand.text.length > 1) getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedBrand]);

  const fetchBrands = (v, i) => {
    setSearchedBrand({ text: v, index: i });
  };

  const CategoryBrandsColumns: TableColumns[] = [
    {
      Header: "Brand",
      accessor: "_id",
      Cell: ({
        value,
        row: {
          index,
          original: { name },
        },
      }) => (
        <Autocomplete
          selected={value}
          onSearch={debounce((value) => {
            fetchBrands(value, index);
          }, 300)}
          onChange={({ value: _id, label: name }) => {
            setBrandsList([]);
            setSearchedBrand({ index: -1, text: "" });
            setCategoryBrands((curr) =>
              curr.map((obj, i) => {
                if (i === index) return { ...obj, _id, name };
                return obj;
              })
            );
          }}
          placeholder="Search brands..."
          list={
            brandsList.length && searchedBrand.index === index
              ? brandsList
              : [{ label: name, value }]
          }
          apiSearch
          searchedValue={
            searchedBrand.index === index ? searchedBrand.text : ""
          }
        />
      ),
    },
    {
      Header: "Tags",
      accessor: "tenants",
      Cell: ({ value, row: { index } }) => (
        <SelectList
          placeholder="Select tags"
          list={tenants}
          onChange={(tenants) => {
            setCategoryBrands((current) =>
              current.map((obj, i) => {
                if (i === index) {
                  return { ...obj, tenants };
                }
                return obj;
              })
            );
          }}
          selected={value}
          multiselect
        />
      ),
    },
    {
      Header: "Add/Remove",
      Cell: ({ row: { index } }) => (
        <div className="flex justify-center gap-x-10">
          {index === brands.length - 1 && (
            <PlusCircleIcon
              className="w-20 h-20 cursor-pointer"
              fill="green"
              onClick={() => add()}
            />
          )}
          {brands.length > 1 && (
            <XCircleIcon
              className="w-20 h-20 cursor-pointer"
              fill="red"
              onClick={() => remove(index)}
            />
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (parent) getCategory();
  }, [parent]);

  function handleCategoryDetails(value) {
    if (value?.parent) setParent(value?.parent);
    setCategoryDetails((prev) => ({ ...prev, ...value }));
  }

  return (
    <div className="p-20">
      <Title title="New Category" />
      <div className="flex p-20">
        <Button
          text={"Save"}
          onButtonClick={() => create()}
          btnClass={"bg-btn px-[30px] py-[10px]"}
          disabled={createCategoryLoader}
          loadingText="Processing..."
          loading={createCategoryLoader}
        />
      </div>
      <div className="p-20">
        <Tabs
          list={categoryTabs}
          selectedTab={selectedTab}
          onClick={setSelectedTab}
        />
      </div>
      {selectedTab === "details" && (
        <CategoryDetails
          details={categoryDetails}
          updateDetails={handleCategoryDetails}
        />
      )}
      {selectedTab === "attributes" && (
        <AttributesTable
          attributes={attributes}
          handleAttributeData={handleAttributeData}
          fetchAttrs={fetchAttrs}
          attributesList={attributesList}
          searchedAttribute={searchedAttribute}
          setCurrAttr={setCurrAttr}
          setShowOptions={setShowOptions}
          setShowFilterOptions={setShowFilterOptions}
          addAttr={addAttr}
          removeAttr={removeAttr}
        />
      )}
      {selectedTab === "qc" && (
        <div className="flex gap-x-30 p-20">
          <SelectList
            list={attributes?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={(qcCapacity) => {
              setCategoryDetails({ ...categoryDetails, qcCapacity });
            }}
            selected={categoryDetails.qcCapacity}
            placeholder="Select capacity"
          />
          <SelectList
            list={attributes?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={(qcColor) => {
              setCategoryDetails({ ...categoryDetails, qcColor });
            }}
            selected={categoryDetails.qcColor}
            placeholder="Select color"
          />
        </div>
      )}
      {selectedTab === "brands" && (
        <div className="flex p-20">
          <Table
            columns={CategoryBrandsColumns}
            data={brands}
            loading={false}
            hasPagination={false}
          />
        </div>
      )}
      {selectedTab === "displayName" && (
        <DisplayNameLogic
          mandatoryAttributes={
            attributes[0]?._id
              ? attributes.map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))
              : []
          }
          displayNameValues={displayNameValues}
          setDisplayNameValues={setDisplayNameValues}
          attributes={attributes}
          replacements={replacements}
          setReplacements={setReplacements}
        />
      )}
      <AddValueOptions
        showOptions={showOptions}
        close={() => setShowOptions(false)}
        data={attributes[currAttr]?.options}
        currAttr={currAttr}
        updateAttributes={setCategoryAttributes}
      />
      <FilterOptions
        showOptions={showFilterOptions}
        close={() => setShowFilterOptions(false)}
        data={attributes[currAttr]?.filterOptions}
        currAttr={currAttr}
        updateAttributes={setCategoryAttributes}
      />
    </div>
  );
}
